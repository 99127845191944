import { Button, Select, Form, Input, Empty, Spin, message } from "antd";
import { CustomTable } from "../common/table";
import {
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined,
  StopOutlined,
} from "@ant-design/icons";
import { ModalForm } from "../common/modalForm";
import { Option } from "antd/es/mentions";
import { useTableSearch } from "../common/tableSearch";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";

/**
 * Component: Device Form Body
 * @returns Form.items
 */
function DeviceFormBody({
  deviceTypeOptions,
  deviceTypeSelect,
  handleDeviceTypeChange,
  mode,
  siteID,
}) {
  const [loading, setLoading] = useState(false);
  const [deviceKeyOptions, setDeviceKeyOptions] = useState([]);

  function getDeviceKeySelection(siteID) {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteID,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setDeviceKeyOptions(data.device_key_selection);
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/deviceManagement/getDeviceKeySelection`,
      cb,
      setLoading,
      "Get Device Key Selection",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteID) {
      getDeviceKeySelection(siteID);
    }
  }, [siteID]);

  return (
    <>
      <Form.Item
        label="Device Name"
        name="DeviceNameLabel"
        rules={[{ required: true, message: "Device Name cannot be empty." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Device Type"
        name="DeviceTypeId"
        rules={[{ required: true, message: "Device Type cannot be empty." }]}
      >
        <Select onChange={handleDeviceTypeChange}>
          {deviceTypeOptions.map((data) => (
            <Option key={data.ID} value={data.ID}>
              {data.DeviceType}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Device Select" name="DeviceName">
        <Select
          disabled={mode === "edit" || loading}
          loading={loading}
          options={deviceKeyOptions}
        />
      </Form.Item>

      {deviceTypeSelect === "Inverter" ? (
        <Form.Item
          label="Solar Panels kWp Connected to Inverter"
          name="RatingInkWp"
        >
          <Input />
        </Form.Item>
      ) : (
        <></>
      )}
    </>
  );
}

/**
 * Component: Device Edit Form within a Model
 */
function DeviceEditModalForm({
  deviceTypeOptions,
  deviceTypeSelect,
  handleDeviceTypeChange,
  editLoading,
  modalOpen,
  closeModal,
  initialValues,
  onSubmit,
}) {
  const [deviceForm] = Form.useForm();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      modalTitle={"Edit Device"}
      formObject={deviceForm}
      formName={"deviceInfoForm"}
      formBody={
        editLoading ? (
          <Spin
            style={{ width: "100%" }}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 80,
                  color: "#000038",
                }}
                spin
              />
            }
          />
        ) : (
          <DeviceFormBody
            deviceTypeOptions={deviceTypeOptions}
            deviceTypeSelect={deviceTypeSelect}
            handleDeviceTypeChange={handleDeviceTypeChange}
            mode={"edit"}
          />
        )
      }
      formIntitialValues={initialValues.data}
      formSubmit={onSubmit}
    />
  );
}

/**
 * Component: Device Form within a Model
 */
function DeviceModalForm({
  deviceTypeOptions,
  deviceTypeSelect,
  handleDeviceTypeChange,
  modalOpen,
  closeModal,
  onSubmit,
  siteID,
}) {
  const [deviceForm] = Form.useForm();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      modalTitle={"Add Device"}
      formObject={deviceForm}
      formName={"deviceInfoForm"}
      formBody={
        <DeviceFormBody
          deviceTypeOptions={deviceTypeOptions}
          deviceTypeSelect={deviceTypeSelect}
          handleDeviceTypeChange={handleDeviceTypeChange}
          mode={"add"}
          siteID={siteID}
        />
      }
      formSubmit={onSubmit}
    />
  );
}

/**
 * Component: Device Management Table
 */
function DeviceManagementTable({
  tableData,
  deviceType,
  onEditClick,
  onDeleteClick,
}) {
  const { getColumnSearchProps } = useTableSearch();

  const deviceTypeMap = {};

  // Populate deviceTypeMap if deviceType is an object
  if (deviceType && typeof deviceType === "object") {
    Object.keys(deviceType).forEach((key) => {
      deviceTypeMap[deviceType[key].ID] = deviceType[key].DeviceType;
    });
  }

  const deviceTabColumns = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Device Name",
      dataIndex: "DeviceNameLabel",
      key: "DeviceNameLabel",
      ...getColumnSearchProps("DeviceNameLabel"),
    },
    {
      title: "Device Type",
      dataIndex: "DeviceTypeId",
      key: "DeviceTypeId",
      render: (DeviceTypeId) => deviceTypeMap[DeviceTypeId] || DeviceTypeId,
      filters: deviceType
        ? Object.keys(deviceType).map((key) => ({
            text: deviceType[key].DeviceType,
            value: deviceType[key].ID,
          }))
        : [],
      onFilter: (value, record) => record.DeviceTypeId === value,
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      filters: [
        { text: "Online", value: "Online" },
        { text: "Offline", value: "Offline" },
      ],
      onFilter: (value, record) => record.Status === value,
    },
    {
      title: "Last Online Time",
      dataIndex: "LastOnline",
      key: "LastOnline",
    },
    {
      title: "Added At",
      dataIndex: "CreateDateTime",
      key: "CreateDateTime",
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}
        >
          {record.IsRemoved == true ? (
            <Button
              type="ghost"
              icon={<StopOutlined style={{ color: "red" }} />}
            />
          ) : (
            ""
          )}
          <Button
            type="text"
            icon={<EditOutlined />}
            disabled={record.IsRemoved == true ? true : false}
            onClick={() => onEditClick(record.ID, "deviceManagementTab")}
          />
          {record.IsRemoved != true ? (
            <Button
              type="ghost"
              icon={<DeleteOutlined />}
              onClick={() => onDeleteClick(record.ID, "deviceManagementTab")}
            />
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  return (
    <CustomTable
      dataSource={tableData && tableData.length > 0 ? tableData : []}
      columns={deviceTabColumns}
      row_per_page={8}
      tableHeight={500}
      tableContainerStyleObject={{ height: "80%", minWidth: "700px" }}
    />
  );
}

function DeviceManagement({
  tableData,
  editLoading,
  onEditClick,
  onDeleteClick,
  deviceTypeOptions,
  modalOpen,
  editModalOpen,
  closeModal,
  initialValues,
  onAddSubmit,
  onEditSubmit,
}) {
  const [deviceTypeSelect, setDeviceTypeSelect] = useState({});
  const [siteID, setSiteID] = useState(null);

  const handleDeviceTypeChange = (value) => {
    const selectDevice = deviceTypeOptions.find(
      (device) => device.ID === value
    );
    setDeviceTypeSelect(selectDevice.DeviceType);
  };

  const handleModalClose = () => {
    setDeviceTypeSelect("");
    closeModal();
  };

  useEffect(() => {
    if (initialValues.data && initialValues.data.DeviceTypeId) {
      if (initialValues.data.DeviceTypeId == 3) {
        setDeviceTypeSelect("Inverter");
      } else {
        setDeviceTypeSelect("");
      }
    }
  }, [initialValues]);

  useEffect(() => {
    if (tableData.length != undefined) {
      setSiteID(tableData[0].SiteId);
    }
  }, [tableData]);

  return (
    <>
      <DeviceManagementTable
        tableData={tableData}
        deviceType={deviceTypeOptions}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
      <DeviceModalForm
        deviceTypeOptions={deviceTypeOptions}
        deviceTypeSelect={deviceTypeSelect}
        handleDeviceTypeChange={handleDeviceTypeChange}
        modalOpen={modalOpen}
        closeModal={handleModalClose}
        onSubmit={onAddSubmit}
        siteID={siteID}
      />
      <DeviceEditModalForm
        deviceTypeOptions={deviceTypeOptions}
        deviceTypeSelect={deviceTypeSelect}
        handleDeviceTypeChange={handleDeviceTypeChange}
        editLoading={editLoading}
        modalOpen={editModalOpen}
        closeModal={handleModalClose}
        initialValues={initialValues}
        onSubmit={onEditSubmit}
      />
    </>
  );
}

export default DeviceManagement;
