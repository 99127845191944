import "../css/pages/STReport.css";
import { useEffect, useState } from "react";
import { Card, DatePicker, Divider, Input, Space, Button, message } from "antd";
import dayjs from "dayjs";
import { formatMoneyNumber } from "../functions/numberFormatting";
import { useOutletContext } from "react-router-dom";
import { fetchHelper } from "../functions/fetch";
import { BACKEND_URL } from "../configuration";

const BULLET_POINT = "\xa0\xa0•\xa0\xa0";

function STReportRow({ title, value, additional, lastRow }) {
  const lastRowCSS = lastRow ? "st-report-last-row" : "";

  return (
    <div className={`st-report-row ${lastRowCSS}`}>
      <div className="st-report-col-1 align-center">
        <span className="st-report-row-title">{title}</span>
        {value === false ? (
          <></>
        ) : (
          <Divider className="st-report-divider" type="vertical" />
        )}
      </div>

      <div className="st-report-col-2">
        {value}
        {additional ?? <></>}
      </div>
    </div>
  );
}

function CompactInput({ value, setValue, unit }) {
  return (
    <Space.Compact>
      <Input
        size="small"
        suffix={unit}
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        min={0}
      />
    </Space.Compact>
  );
}

function STReport() {
  const [siteSelect, setSiteSelect, siteChange, setSiteChange] =
    useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [tariffRateInput, setTariffRateInput] = useState(false);
  const [displacedCostInput, setDisplaceCostInput] = useState(false);
  const [monthSelection, setMonthSelection] = useState(dayjs());
  const [siteReportData, setSiteReportData] = useState({
    energy_imported: "-",
    energy_consumed: "-",
    energy_exported: "-",
    total_yield: "-",
    tariff_type: "-",
    tariff_rate: "-",
    displace_cost: "-",
    savings_energy_sold: "-",
    savings_energy_consumed: "-",
    savings_total: "-",
    maximum_demand: "-",
  });

  const changeEnergyImported = (value) =>
    setSiteReportData((prev) => ({ ...prev, energy_imported: value }));
  const changeEnergyExported = (value) =>
    setSiteReportData((prev) => ({ ...prev, energy_exported: value }));
  const changeTariffRate = (value) =>
    setSiteReportData((prev) => ({ ...prev, tariff_rate: value }));
  const changeDisplaceCost = (value) =>
    setSiteReportData((prev) => ({ ...prev, displace_cost: value }));

  const disabledDate = (current) => {
    return current && current.isAfter(dayjs().endOf("month"));
  };

  const onTariffRateEdit = () => {
    setTariffRateInput(!tariffRateInput);
  };

  const onDisplaceCostInput = () => {
    setDisplaceCostInput(!displacedCostInput);
  };

  const onSubmitClick = () => {
    getSTReportData();
  };

  const onResetClick = () => {
    setSiteReportData({
      energy_imported: "-",
      energy_consumed: "-",
      energy_exported: "-",
      total_yield: "-",
      tariff_type: "-",
      tariff_rate: "-",
      displace_cost: "-",
      savings_energy_sold: "-",
      savings_energy_consumed: "-",
      savings_total: "-",
      maximum_demand: "-",
    });
  };

  function getSTReportData() {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        siteId: siteSelect,
        month: monthSelection.$d,
        siteReportData: siteReportData,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setSiteReportData(data.data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/report/getSTReportData`,
      cb,
      setIsLoading,
      "Get ST Reporrt Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    onResetClick();
  }, [monthSelection, siteSelect]);

  return (
    <Card
      className="context-card st-report-card"
      title={
        <div className="st-report-title">
          <span>Suruhanjaya Tenaga Monthly Report</span>
          <DatePicker
            format="MM/YYYY"
            value={monthSelection}
            allowClear={false}
            picker="month"
            onChange={(date) => setMonthSelection(date)}
            disabledDate={disabledDate}
          />
        </div>
      }
      style={{ overflowY: "hidden" }}
      bodyStyle={{
        height: "90%",
        // overflowY: "scroll"
      }}
      loading={isLoading}
    >
      <div className="st-report-card-content">
        <STReportRow
          title={"Billing Period"}
          value={
            <span>
              {monthSelection.startOf("month").format("YYYY-MM-DD")}
              {`\xa0to\xa0`}
              {monthSelection.endOf("month").format("YYYY-MM-DD")}
            </span>
          }
        />
        <STReportRow
          title={"Energy imported from TNB/SESB"}
          value={
            <CompactInput
              value={siteReportData.energy_imported}
              setValue={changeEnergyImported}
              unit={"kWh"}
            />
          }
        />
        <STReportRow
          title={"Energy consumed from solar PV (NEM)"}
          value={`${siteReportData.energy_consumed} kWh`}
        />
        <STReportRow
          title={"Energy exported/sold to from TNB/SESB"}
          value={
            <CompactInput
              value={siteReportData.energy_exported}
              setValue={changeEnergyExported}
              unit={"kWh"}
            />
          }
        />
        <STReportRow
          title={"Total Yield from Solar PV (NEM)"}
          value={`${siteReportData.total_yield} kWh`}
        />
        <STReportRow
          title={"Tariff Type for TNB"}
          value={siteReportData.tariff_type}
        />
        <STReportRow
          title={"Tariff Rate"}
          value={
            tariffRateInput ? (
              <CompactInput
                value={siteReportData.tariff_rate}
                setValue={changeTariffRate}
                unit={"RM/kWh"}
              />
            ) : (
              `${siteReportData.tariff_rate} RM/kWh`
            )
          }
          additional={
            <Button size="small" onClick={() => onTariffRateEdit()}>
              {tariffRateInput ? "Complete" : "Edit"}
            </Button>
          }
        />
        <STReportRow
          title={"Displaced Cost"}
          value={
            displacedCostInput ? (
              <CompactInput
                value={siteReportData.displace_cost}
                setValue={changeDisplaceCost}
                unit={"RM/kWh"}
              />
            ) : (
              `${siteReportData.displace_cost} RM/kWh`
            )
          }
          additional={
            <Button size="small" onClick={() => onDisplaceCostInput()}>
              {displacedCostInput ? "Complete" : "Edit"}
            </Button>
          }
        />
        <STReportRow title={"Savings Calculations"} value={false} />
        <STReportRow
          title={`${BULLET_POINT} Energy sold TNB/SESB`}
          value={`RM ${formatMoneyNumber(siteReportData.savings_energy_sold)}`}
        />
        <STReportRow
          title={`${BULLET_POINT} Energy consumed from solar PV`}
          value={`RM ${formatMoneyNumber(
            siteReportData.savings_energy_consumed
          )}`}
        />

        <STReportRow
          title={`${BULLET_POINT} Total Savings`}
          value={`RM ${formatMoneyNumber(siteReportData.savings_total)}`}
        />
        <STReportRow
          title={"Maximum Demand"}
          value={`${siteReportData.maximum_demand} kW`}
          lastRow={true}
        />
      </div>
      <div className="st-report-reset-button-container">
        <div>
          <Button
            className="st-report-submit-button"
            onClick={onSubmitClick}
            disabled={tariffRateInput || displacedCostInput}
          >
            Calculate
          </Button>
          <Button className="st-report-reset-button" onClick={onResetClick}>
            Reset
          </Button>
        </div>
      </div>
    </Card>
  );
}

export default STReport;
