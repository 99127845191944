import React, { useEffect, useState } from "react";
import {
  Card,
  DatePicker,
  Flex,
  Table,
  Tabs,
  Button,
  Divider,
  message,
} from "antd";
import dayjs from "dayjs";
import "../css/pages/SavingReport.css";
import MD from "../component/SavingReport/MD";
import EnergyRecharge from "../component/SavingReport/EnergyRecharge";
import { DownloadOutlined } from "@ant-design/icons";
import { fetchHelper } from "../functions/fetch";
import { BACKEND_URL } from "../configuration";
import { useOutletContext } from "react-router-dom";
import html2canvas from "html2canvas";

const { RangePicker } = DatePicker;

function SavingReport() {
  const BESS_peak_shaving = React.useRef();
  const Scenario_BESS = React.useRef();
  const Energy_Saving = React.useRef();

  const [siteSelect] = useOutletContext();

  const [loading, setLoading] = useState(false);
  const [downloadButtonLoading, setDownloadButtonLoading] = useState(false);

  const [dates, setDates] = useState([dayjs(), dayjs()]); // value of RangePicker when either one of the start date and end date has been selected
  const [value, setValue] = useState([dayjs(), dayjs()]); // value of RangePicker if both the start date and end date has been selected

  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [tableData, setTableData] = useState([]);
  const [tableColumn, setTableColumn] = useState([
    {
      title: "Saving",
      dataIndex: "Saving",
      key: "Saving",
      width: 200,
    },
  ]);

  const [startDate, setStartDate] = useState(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );

  const [mdSummaryData, setMdSummaryData] = useState([]);
  const [mdBreakdownData, setMdBreakdownData] = useState([]);
  const [mdSaving, setMdSaving] = useState(null);
  const [mdDateBeforeBess, setMdDateBeforeBess] = useState(null);
  const [mdDateAfterBess, setMdDateAfterBess] = useState(null);

  const items = [
    {
      key: "md_saving",
      label: "Maximum Demand Saving",
      children: (
        <MD
          BESS_peak_shaving={BESS_peak_shaving}
          Scenario_BESS={Scenario_BESS}
          startDateOri={startDate}
          endDateOri={endDate}
        />
      ),
    },
    {
      key: "energy_saving",
      label: "Energy Saving",
      children: (
        <EnergyRecharge
          Energy_Saving={Energy_Saving}
          startDateOri={startDate}
          endDateOri={endDate}
        />
      ),
    },
  ];

  // Disable dates that are not in the same year as the selected start date
  const disabledDate = (current) => {
    if (!dates || !dates[0]) {
      // No start date selected, so allow all dates
      return false;
    }

    const startYear = dates[0].year();

    // Disable dates that are not in the same year as the selected start date
    return current.year() !== startYear;
  };

  const onOpenChange = (open) => {
    if (open) {
      setDates([null, null]); // Reset dates to [null, null] when the picker is opened
    } else {
      setDates(null); // Clear the state completely when the picker is closed
    }
  };

  const onCalendarChange = (val) => {
    if (val[1] && !val[0]) {
      // If end date is selected first, set the start date to the Jan of the year
      const sameMonthStart = val[1].clone().startOf("month");
      setDates([sameMonthStart, val[1]]);
    } else {
      setDates(val); // Otherwise, just set the selected dates
    }
  };

  function getSavingReportSummary() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
        start_date: startDate,
        end_date: endDate,
      }),
    };

    const cb = (data) => {
      setTableColumn(data.table_column);
      setTableData(data.table_data);
    };

    fetchHelper(
      `${BACKEND_URL}/saving_report/getSavingReportSummary`,
      cb,
      setLoading,
      "Get Saving Report Summary",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadImage(file) {
    const xhr = new XMLHttpRequest();
    xhr.open("POST", BACKEND_URL + "/saving_report/getDownloadSavingReport");

    xhr.upload.onprogress = function (e) {};

    xhr.onload = function () {
      setDefaultPageSize(10);
      if (xhr.status === 200) {
        const blob = new Blob([xhr.response], {
          type: xhr.getResponseHeader("Content-Type"),
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");

        const filename = "downloaded_file.pdf";
        a.href = url;
        a.download = filename;

        document.body.appendChild(a);
        a.click();

        setTimeout(() => {
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        }, 100);

        message.success("File downloaded successfully");
      } else {
        message.error("Failed to download file");
      }
      setDownloadButtonLoading(false);
    };

    xhr.send(file);
  }

  useEffect(() => {
    if (startDate && endDate && siteSelect) {
      getSavingReportSummary();
    }
  }, [startDate, endDate, siteSelect]);

  const takeScreenshot = async () => {
    setDownloadButtonLoading(true);
    setDefaultPageSize(1000000);
    const BESS_peak_shaving_Elemenet = BESS_peak_shaving.current;
    const Scenario_BESS_Element = Scenario_BESS.current;
    const Energy_Saving_Element = Energy_Saving.current;

    // Capture the screenshot
    const canvas_1 = await html2canvas(BESS_peak_shaving_Elemenet);
    const imgData_1 = canvas_1.toDataURL("image/png");
    const canvas_2 = await html2canvas(Scenario_BESS_Element);
    const imgData_2 = canvas_2.toDataURL("image/png");
    const canvas_3 = await html2canvas(Energy_Saving_Element);
    const imgData_3 = canvas_3.toDataURL("image/png");

    // Prepare the data to send to the server
    const blob_1 = await fetch(imgData_1).then((res) => res.blob());
    const blob_2 = await fetch(imgData_2).then((res) => res.blob());
    const blob_3 = await fetch(imgData_3).then((res) => res.blob());

    const imaeg_path = new FormData();
    imaeg_path.append("BESS_peak_shaving", blob_1, "BESS_peak_shaving.png");
    imaeg_path.append("Scenario_BESS", blob_2, "Scenario_BESS.png");
    imaeg_path.append("Energy_Saving", blob_3, "Energy_Saving.png");

    imaeg_path.append("mdBreakdownData", JSON.stringify(mdBreakdownData));
    imaeg_path.append("mdSaving", JSON.stringify(tableData)); // mdSaving is already a string, but this keeps it consistent
    imaeg_path.append("mdSummaryData", JSON.stringify(mdSummaryData));
    imaeg_path.append("mdDateBeforeBess", mdDateBeforeBess);
    imaeg_path.append("mdDateAfterBess", mdDateAfterBess);
    uploadImage(imaeg_path);
  };

  // function uploadImage(image_path){
  //   fetchWrapper({
  //       endpoint_url: "saving_report/download_saving_report",
  //       set_content_type_header: false,
  //       body: image_path,
  //       onSuccess: (fetch_output) => {
  //           if (fetch_output.error) {
  //               console.log('failed to upload')
  //           } else {
  //               console.log('failed to upload')
  //           }
  //       },
  //       onFailure: (error) => {
  //           console.log(error)
  //       }
  //   });
  // }

  return (
    <div
      style={{
        margin: "10px",
        width: "100%",
        height: "90vh",
        overflowY: "auto",
      }}
      className="saving-report"
    >
      <Card
        title={
          <Flex justify="space-between" align="center" wrap={true} gap={8}>
            <div>Saving Report</div>

            <Flex gap={4}>
              <RangePicker
                picker="month"
                defaultValue={dates}
                value={dates || value}
                disabledDate={disabledDate} // Apply the disabledDate function
                onCalendarChange={onCalendarChange} // Update selected dates
                onChange={(val) => {
                  setValue(val);
                  setStartDate(val[0].startOf("month").format("YYYY-MM-DD"));
                  setEndDate(val[1].startOf("month").format("YYYY-MM-DD"));
                }} // Update final value, start month and end month
                onOpenChange={onOpenChange} // Reset when the picker opens
              />

              <Button
                loading={downloadButtonLoading}
                icon={<DownloadOutlined />}
                onClick={() => {
                  takeScreenshot();
                }}
              />
            </Flex>
          </Flex>
        }
      >
        <Table
          style={{ marginBottom: "12px" }}
          columns={tableColumn}
          dataSource={tableData}
          pagination={false}
          loading={loading}
          size="small"
        />
        {/* <Tabs type="card" defaultActiveKey="md_saving" items={items} /> */}
        <Divider style={{ borderColor: "lightgray" }} />
        <MD
          mdDateAfterBess={mdDateAfterBess}
          setMdDateAfterBess={setMdDateAfterBess}
          mdDateBeforeBess={mdDateBeforeBess}
          setMdDateBeforeBess={setMdDateBeforeBess}
          mdSaving={mdSaving}
          setMdSaving={setMdSaving}
          mdSummaryData={mdSummaryData}
          setMdSummaryData={setMdSummaryData}
          mdBreakdownData={mdBreakdownData}
          setMdBreakdownData={setMdBreakdownData}
          BESS_peak_shaving={BESS_peak_shaving}
          Scenario_BESS={Scenario_BESS}
          startDateOri={startDate}
          endDateOri={endDate}
        />

        <Divider style={{ borderColor: "lightgray" }} />
        <EnergyRecharge
          defaultPageSize={defaultPageSize}
          Energy_Saving={Energy_Saving}
          startDateOri={startDate}
          endDateOri={endDate}
        />
      </Card>
    </div>
  );
}

export default SavingReport;
