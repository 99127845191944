import { Form, Input, Flex, Button, Skeleton } from "antd";
import { FORM_REQUIRED } from "../../configuration";

function TelegramForm(props) {
  const {
    setIsTelegramFormModalOpen,
    mode,
    addTelegram,
    updateTelegram,
    form,
    submitButtonLoading,
    telegramDetailsRetrievalLoading,
  } = props;

  return !telegramDetailsRetrievalLoading ? (
    <Form
      layout="vertical"
      onFinish={(values) => {
        if (mode === "add") {
          addTelegram(values);
        } else {
          updateTelegram(values);
        }
      }}
      form={form}
      autoComplete="off"
    >
      <Form.Item
        label="Telegram"
        name="Name"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Please input telegram name.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Chat ID"
        name="ChatID"
        rules={[{ required: FORM_REQUIRED, message: "Please input Chat ID." }]}
      >
        <Input />
      </Form.Item>

      <Flex justify="center" gap="8px">
        <Button
          onClick={() => {
            setIsTelegramFormModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={submitButtonLoading}>
          Submit
        </Button>
      </Flex>
    </Form>
  ) : (
    <Skeleton
      active={true}
      style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
      paragraph={{ rows: 5 }}
    />
  );
}

export default TelegramForm;
