import { useState, useCallback } from "react";
import { fetchHelper } from "../../functions/fetch";
import { BACKEND_URL } from "../../configuration";
import { message } from "antd";

function useGetAlarmSummary() {

  const [ alarmSummary, setAlarmSummary ] = useState({})
  const [ alarmSummaryLoading, setAlarmSummaryLoading] = useState(true)

  const getAlarmSummary = useCallback((startDateSelection, endDateSelection, site_id) => {
    setAlarmSummaryLoading(true)
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        'start_date': startDateSelection,
        'end_date': endDateSelection,
        'site_id': site_id
      }),
    };
  
    const cb = (data) => {
      if (data.status) {
        setAlarmSummary(data.alarm_summary)
        setAlarmSummaryLoading(false)
      } else {
        message.error(data.message);
        setAlarmSummaryLoading(false)
      }
    };
  
    fetchHelper(
      BACKEND_URL + "/alarm/getAlarmSummary",
      cb,
      () => {},
      "get alarm summary",
      () => {},
      () => {},
      requestOptions,
      true
    );  }, [fetchHelper]);

  return { getAlarmSummary, alarmSummary, alarmSummaryLoading};
}

export default useGetAlarmSummary;
