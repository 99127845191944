import { Button, Form, Input, Select, Popconfirm, message } from "antd";
import { CustomTable } from "../common/table";
import {
  DeleteOutlined,
  EditOutlined,
  StopOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import { ModalForm } from "../common/modalForm";
import { Option } from "antd/es/mentions";
import { useTableSearch } from "../common/tableSearch";
import { FORM_REQUIRED } from "../../configuration";
import { useEffect, useState } from "react";

/**
 * Component: user Form Body
 * @returns Form.items
 */
function UserFormBody({ userData, currentUser, modalType, checkUserName }) {
  const [userType, setUserType] = useState("");
  const [validatorUsername, setValidatorUsername] = useState(false);
  const [submit, setSubmit] = useState(true);

  return (
    <>
      <Form.Item
        label="Organisation Name"
        name="Organisation"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Organisation name cannot be empty.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Username"
        name="Username"
        rules={
          modalType != "Edit"
            ? [
                {
                  required: FORM_REQUIRED,
                  message: "Username cannot be empty.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    checkUserName(value).then((data) => {
                      setValidatorUsername(data.status);
                    });
                    if (validatorUsername) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Username already exist."));
                  },
                }),
              ]
            : []
        }
      >
        <Input disabled={modalType == "Edit" ? true : false} />
      </Form.Item>
      {modalType != "Edit" ? (
        <Form.Item
          label="Password"
          name="Password"
          rules={[
            { required: FORM_REQUIRED, message: "Password cannot be empty." },
          ]}
        >
          <Input.Password />
        </Form.Item>
      ) : (
        ""
      )}
      {modalType != "Edit" ? (
        <Form.Item
          name="confirmPassword"
          label="Confirm Password"
          dependencies={["password"]}
          rules={[
            {
              required: FORM_REQUIRED,
              message: "Please confirm your password.",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("Password") === value) {
                  setSubmit(false);
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match."));
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
      ) : (
        ""
      )}
      {modalType != "Edit" ? (
        <Form.Item
          label="User Type"
          name="UserType"
          rules={[
            {
              required: FORM_REQUIRED,
              message: "Account role cannot be empty.",
            },
          ]}
        >
          <Select
            onChange={(value) => {
              setUserType(value);
            }}
          >
            {currentUser["user_type"] == 0 ? (
              <Option value={1}>Admin</Option>
            ) : (
              ""
            )}
            <Option value={2}>User</Option>
          </Select>
        </Form.Item>
      ) : (
        ""
      )}
      {modalType == "Edit" ? (
        <Form.Item label="Site Access" name="siteAccess">
          <Select
            mode="multiple"
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            options={userData.SiteLists}
          />
        </Form.Item>
      ) : (
        <Form.Item label="Site Access" name="siteAccess">
          <Select
            allowClear
            style={{ width: "100%" }}
            placeholder="Please select"
            disabled={userType.length == 0 ? true : false}
            options={
              currentUser["user_type"] == 1
                ? currentUser["admin_sites_lists"]
                : currentUser["sites_lists"]
            }
          />
        </Form.Item>
      )}
      <Form.Item
        label="Valid"
        name="AccessValidity"
        rules={[
          { required: FORM_REQUIRED, message: "Validity cannot be empty." },
        ]}
      >
        <Select>
          <Option value={true}>True</Option>
          <Option value={false}>False</Option>
        </Select>
      </Form.Item>
    </>
  );
}

/**
 * Component: User Account Edit Form within a Model
 */
function UserAccountEditModalForm({
  modalOpen,
  closeModal,
  initialValues,
  onSubmit,
}) {
  const [custForm] = Form.useForm();

  useEffect(() => {
    // console.log(initialValues);
  });

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      modalTitle={"Edit User Account"}
      formObject={custForm}
      formName={"userForm"}
      formBody={<UserFormBody userData={initialValues} modalType={"Edit"} />}
      formIntitialValues={initialValues}
      formSubmit={onSubmit}
    />
  );
}

/**
 * Component: User Account Form within a Model
 */
function UserAccountModalForm({
  modalOpen,
  closeModal,
  userDetails,
  onSubmit,
  checkUserName,
}) {
  const [custForm] = Form.useForm();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      modalTitle={"Add User Account"}
      formObject={custForm}
      formName={"userForm"}
      formBody={
        <UserFormBody
          currentUser={userDetails}
          modalType={"Add"}
          checkUserName={checkUserName}
        />
      }
      formSubmit={onSubmit}
    />
  );
}

/**
 * Component: user Management Table
 */
function UserManagementTable({ tableData, onEditClick, onDeleteClick }) {
  const { getColumnSearchProps } = useTableSearch();

  const userTableColumns = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Username",
      dataIndex: "Username",
      key: "Username",
      ...getColumnSearchProps("Username"),
    },
    {
      title: "User Type",
      dataIndex: "UserType",
      key: "UserType",
      filters: [
        {
          text: "Admin",
          value: 1,
        },
        {
          text: "User",
          value: 2,
        },
      ],
      onFilter: (value, record) => record.UserType === value, // Filter based on the id value
      render: (_, record, index) => (record.UserType == 1 ? "Admin" : "User"), // render text instead of user type id
    },
    {
      title: "Organisation Name",
      dataIndex: "CreateBy",
      key: "CreateBy",
      ...getColumnSearchProps("CreateBy"),
    },
    {
      title: "Company Name",
      dataIndex: "Organisation",
      key: "Organisation",
      ...getColumnSearchProps("Organisation"),
    },
    {
      title: "Created At",
      dataIndex: "CreateDateTime",
      key: "CreateDateTime",
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (_, record, index) => (
        <div
          style={{
            display: "flex",
            gap: "5px",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {record.AccessValidity != true ? (
            <h4
              style={{
                margin: "0px",
                marginTop: "-2px",
                padding: "0px 10px",
                color: "red",
              }}
            >
              <SolutionOutlined />
            </h4>
          ) : (
            ""
          )}
          {record.IsRemoved == true ? (
            <h4
              style={{
                margin: "0px",
                marginTop: "-2px",
                padding: "0px 10px",
                color: "red",
              }}
            >
              <StopOutlined />
            </h4>
          ) : (
            ""
          )}
          <Button
            type="text"
            icon={<EditOutlined />}
            disabled={record.IsRemoved != true ? false : true}
            onClick={() => onEditClick(record, "userManagementTab")}
          />
          {record.IsRemoved != true ? (
            <Popconfirm
              title="Delete User"
              description={"Are you sure to delete this user?"}
              onConfirm={() => {
                onDeleteClick(record, "userManagementTab");
              }}
              onCancel={() => {
                message.success("Action is cancelled");
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" icon={<DeleteOutlined />} />
            </Popconfirm>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  return (
    <CustomTable
      dataSource={tableData}
      columns={userTableColumns}
      row_per_page={8} // to fit the screen when at 100%
      tableHeight={500} // to fit the screen when at 100%
      // pageSizeOptions={}
      tableContainerStyleObject={{ height: "80%", minWidth: "700px" }}
    />
  );
}

function UserManagement({
  userDetails,
  tableData,
  onEditClick,
  onDeleteClick,
  modalOpen,
  editModalOpen,
  closeModal,
  initialValues,
  onSubmit,
  checkUserName,
  editSubmit,
}) {
  return (
    <>
      <UserManagementTable
        tableData={tableData}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
      />
      <UserAccountModalForm
        checkUserName={checkUserName}
        userDetails={userDetails}
        modalOpen={modalOpen}
        closeModal={closeModal}
        onSubmit={onSubmit}
      />
      <UserAccountEditModalForm
        modalOpen={editModalOpen}
        closeModal={closeModal}
        initialValues={initialValues}
        onSubmit={editSubmit}
      />
    </>
  );
}

export default UserManagement;
