import { Button, Card, Form, Input, message } from "antd";
import { React, useState } from "react";
import { BACKEND_URL } from "../configuration";
import { useNavigate } from "react-router-dom";
import { fetchHelper } from "../functions/fetch";

const ChangePassword = (props) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const changePassword = (form_data) => {
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(form_data),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
      } else {
        message.error(data.message);
      }
    };

    setLoading(true);
    fetchHelper(
      BACKEND_URL + "/change_password/changePassword",
      cb,
      setLoading,
      "Change Password",
      () => {},
      () => {},
      requestOptions,
      true
    );
  };

  return (
    <div style={{ padding: "16px", width: "100%", height: "100%" }}>
      <Card
        style={{ width: "100%", height: "100%" }}
        title={"Change Password"}
        loading={loading}
      >
        <Form name="changePassword" layout="vertical" onFinish={changePassword}>
          <Form.Item
            label="Old Password"
            name="old_password"
            rules={[
              {
                required: true,
                message: "Please input your old password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="new_password"
            rules={[
              {
                required: true,
                message: "Please input your new password!",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue("new_password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The new password that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item style={{ position: "absolute", right: "25px" }}>
            <Button htmlType={"submit"} className="config-btn">
              Save
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default ChangePassword;
