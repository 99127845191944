import { Button, Col, Flex, Row, Skeleton, Tabs, message } from "antd";
import { CustomTable } from "../common/table";
import { LabelValueUnitDisplayRow } from "../common/wordDisplay";
import DeviceCard from "./DeviceCard";
import DeviceInformation from "./DeviceInformation";
import { EyeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useTableSearch } from "../common/tableSearch";
import { fetchHelper } from "../../functions/fetch";
import { BACKEND_URL, MQTT_REFRESH_RATE } from "../../configuration";

function UtilityTable({ data, setOpenModal }) {
  const { getColumnSearchProps } = useTableSearch();

  const utilityTableColumns = [
    {
      title: "Phase Name",
      dataIndex: "phasename",
      key: "phasename",
      ...getColumnSearchProps("phasename"),
    },
    {
      title: "Voltage",
      dataIndex: "voltage",
      key: "voltage",
    },
    {
      title: "Current",
      dataIndex: "current",
      key: "current",
    },
    {
      title: "Power Factor",
      dataIndex: "powerfactor",
      key: "powerfactor",
    },
    {
      title: "Active Power",
      dataIndex: "activepower",
      key: "activepower",
    },
    {
      title: "Reactive Power",
      dataIndex: "reactivepower",
      key: "reactivepower",
    },
    // {
    //   title: (
    //     <div
    //       style={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}
    //     >
    //       {data.length > 0 && (
    //         <Button
    //           type="link"
    //           icon={<EyeOutlined />}
    //           onClick={() => setOpenModal(true)}
    //         >
    //           View
    //         </Button>
    //       )}
    //     </div>
    //   ),
    //   dataIndex: "view",
    //   key: "view",
    // },
  ];

  return (
    <CustomTable
      dataSource={data}
      columns={utilityTableColumns}
      row_per_page={8}
      tableHeight={500}
      tableContainerStyleObject={{
        height: "80%",
        minWidth: "700px",
      }}
    />
  );
}

function UtilityTNBContent({
  deviceTabData,
  systemData,
  tableData,
  utilityCard,
  handleChange,
  setOpenModal,
  handleTab,
  utilitySystemLoading,
  tabChange,
  utilityDeviceLoading,
  utilityCardData,
  systemLastUpdate,
  deviceLastUpdate,
}) {
  return (
    <div className="system-col">
      {utilitySystemLoading ? (
        <Skeleton active style={{ margin: "5px" }} />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              align: "center",
              marginBottom: "12px",
            }}
          >
            <h2 style={{ margin: "0px" }}>TNB System</h2>
            <span>Last Update: {systemLastUpdate}</span>
          </div>
          <div className="system-row">
            <div className="content-box">
              <LabelValueUnitDisplayRow
                label={"Real Power"}
                value={
                  systemData?.realPower !== undefined
                    ? systemData.realPower
                    : "-"
                }
                unit={"kW"}
              />
              <LabelValueUnitDisplayRow
                label={"Reactive Power"}
                value={
                  systemData?.reactivePower !== undefined
                    ? systemData.reactivePower
                    : "-"
                }
                unit={"kVAR"}
              />
              <LabelValueUnitDisplayRow
                label={"Apparent Power"}
                value={
                  systemData?.apparentPower !== undefined
                    ? systemData.apparentPower
                    : "-"
                }
                unit={"kVA"}
              />
              <LabelValueUnitDisplayRow
                label={"Frequency value"}
                value={
                  systemData?.frequency !== undefined
                    ? systemData.frequency
                    : "-"
                }
                unit={"Hz"}
              />
              <LabelValueUnitDisplayRow
                label={"Load Factor"}
                value={
                  systemData?.loadFactor !== undefined
                    ? systemData.loadFactor
                    : "-"
                }
                unit={""}
              />
              <LabelValueUnitDisplayRow
                label={"Power Factor"}
                value={
                  systemData?.powerFactor !== undefined
                    ? systemData.powerFactor
                    : "-"
                }
                unit={""}
              />
            </div>
            <div className="content-box">
              <LabelValueUnitDisplayRow
                label={"Energy Consumption (Peak) - Month"}
                value={
                  systemData?.onPeakImport !== undefined
                    ? systemData.onPeakImport
                    : "-"
                }
                unit={"kWh"}
              />
              <LabelValueUnitDisplayRow
                label={"Energy Consumption (Off-Peak) - Month"}
                value={
                  systemData?.offPeakImport !== undefined
                    ? systemData.offPeakImport
                    : "-"
                }
                unit={"kWh"}
              />
              <LabelValueUnitDisplayRow
                label={"Energy Consumption - Month"}
                value={
                  systemData?.import !== undefined ? systemData.import : "-"
                }
                unit={"kWh"}
              />
              <LabelValueUnitDisplayRow
                label={"Energy Export - Month"}
                value={
                  systemData?.export !== undefined ? systemData.export : "-"
                }
                unit={"kWh"}
              />
            </div>
            <div className="content-box">
              <LabelValueUnitDisplayRow
                label={"Reactive Energy Import - Month"}
                value={
                  systemData?.reactiveImport !== undefined
                    ? systemData.reactiveImport
                    : "-"
                }
                unit={"kVARh"}
              />
              <LabelValueUnitDisplayRow
                label={"Maximum Demand - Month"}
                value={
                  systemData?.maximumDemand !== undefined
                    ? systemData.maximumDemand
                    : "-"
                }
                unit={"kW"}
              />
            </div>
            <div className="content-box">{/* empty space */}</div>
          </div>
        </div>
      )}
      <div className="content-box">
        <Flex justify="space-between" align="center">
          {deviceTabData != undefined && tabChange != undefined ? (
            <>
              <Tabs items={deviceTabData} onChange={(key) => handleTab(key)} />
              <Flex gap={"8px"}>
                <Button
                  disabled={
                    utilityCardData.length === 0 || utilityDeviceLoading
                  }
                  onClick={() => setOpenModal(true)}
                >
                  View Device Details
                </Button>
                <Button onClick={handleChange}>Change View</Button>
              </Flex>
            </>
          ) : (
            <></>
          )}
        </Flex>
        {utilityDeviceLoading ? (
          <Skeleton active style={{ margin: "5px" }} />
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                align: "center",
                marginBottom: "12px",
              }}
            >
              <h2 style={{ margin: "0px" }}>TNB Device Main Parameters</h2>
              <span>Last Update: {deviceLastUpdate}</span>
            </div>
            <div className="system-row power-meter-phases">
              {utilityCard == true ? (
                <Col span={24}>
                  <Row gutter={[18, 18]}>
                    {tableData?.map((d, index) => {
                      const key = `col-${index}`;
                      return (
                        <Col
                          key={key}
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}
                          xl={{ span: 8 }}
                          xxl={{ span: 6 }}
                        >
                          <DeviceCard data={d} setOpenModal={setOpenModal} />
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              ) : (
                <UtilityTable data={tableData} setOpenModal={setOpenModal} />
              )}
              <div className="content-box">{/* empty space */}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function UtilityTNB({
  deviceTabData,
  utilityCard,
  handleChange,
  openModal,
  setOpenModal,
  token,
  siteSelect,
}) {
  const [utilitySystemLoading, setUtilitySystemLoading] = useState(true);
  const [utilityDeviceLoading, setUtilityDeviceLoading] = useState(true);
  const [tabChange, setTabChange] = useState(undefined);
  const [utilityTableData, setUtilityTableData] = useState([]);
  const [utilityCardData, setUtilityCardData] = useState([]);
  const [systemData, setSystemData] = useState({});

  const [systemLastUpdate, setSystemLastUpdate] = useState(null);
  const [deviceLastUpdate, setDeviceLastUpdate] = useState(null);

  const handleTab = (key) => {
    setTabChange(key);
    setUtilityDeviceLoading(true);
  };

  /** API to call device mqtt data */
  function getUtilityMQTTData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setUtilityTableData(data.data.table);
        setUtilityCardData(data.data.card);
        setDeviceLastUpdate(data.last_update);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/system/getDeviceMQTT/${tabChange}`,
      cb,
      setUtilityDeviceLoading,
      "Get Utility Device MQTT Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to call device mqtt data */
  function getSystemData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setSystemData(data.data);
        setSystemLastUpdate(data.last_update);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/system/getSystemUtility/${siteSelect}`,
      cb,
      setUtilitySystemLoading,
      "Get Utility System Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (deviceTabData && deviceTabData.length > 0) {
      setTabChange(deviceTabData[0].key);
    }
  }, [deviceTabData]);

  useEffect(() => {
    if (tabChange != undefined) {
      getUtilityMQTTData();
    }

    const timer = setInterval(() => {
      if (tabChange != undefined) {
        getUtilityMQTTData();
      }
    }, MQTT_REFRESH_RATE * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [tabChange]);

  useEffect(() => {
    setUtilityCardData([]);
    setUtilityTableData([]);
    setSystemData([]);

    if (siteSelect) {
      getSystemData();

      const timer = setInterval(() => {
        getSystemData();
      }, MQTT_REFRESH_RATE * 1000);
      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelect]);

  return (
    <>
      <UtilityTNBContent
        deviceTabData={deviceTabData}
        tableData={utilityTableData}
        systemData={systemData}
        utilityCard={utilityCard}
        handleChange={handleChange}
        setOpenModal={setOpenModal}
        handleTab={handleTab}
        utilitySystemLoading={utilitySystemLoading}
        tabChange={tabChange}
        utilityDeviceLoading={utilityDeviceLoading}
        utilityCardData={utilityCardData}
        systemLastUpdate={systemLastUpdate}
        deviceLastUpdate={deviceLastUpdate}
      />
      <DeviceInformation
        deviceCardData={utilityCardData}
        openModal={openModal}
        setOpenModal={setOpenModal}
        themeToken={token}
        title="Utility Card"
      />
    </>
  );
}

export default UtilityTNB;
