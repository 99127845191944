import { DatePicker, Button } from "antd";
import {
  SearchOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useEffect } from "react";

export function VerticalDateFilter({
  startDateSelection,
  setStartDateSelection,
  endDateSelection,
  setEndDateSelection,
  saveLoading,
  onFilterClick,
  onExportClick,
  pickerType,
}) {
  const disabledEndDate = (current) => {
    if (!startDateSelection) return false;
    const startLimit = startDateSelection.clone().add(7, "days");
    return current && (current < startDateSelection || current > startLimit);
  };

  const disabledButton = () => {
    const isStartDateAfterEndDate =
      startDateSelection.isAfter(endDateSelection);
    const daysDifference = endDateSelection.diff(startDateSelection, "days");
    return isStartDateAfterEndDate || daysDifference > 6;
  };

  // useEffect(() => {
  //   disabledButton();
  // }, [startDateSelection, endDateSelection]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <span>Start Date</span>
      <DatePicker
        // format="DD/MM/YYYY"
        value={startDateSelection}
        allowClear={false}
        picker={pickerType ? pickerType : "date"}
        onChange={(date) => {
          // const startDate = date ? date.startOf("day") : null;
          const startDate = date
            ? pickerType
              ? pickerType === "date"
                ? date.startOf("day")
                : pickerType === "month"
                ? date.startOf("month")
                : pickerType === "year"
                ? date.startOf("year")
                : date.startOf("day")
              : date.startOf("day")
            : null;
          setStartDateSelection(startDate);
        }}
      />
      <span>End Date</span>
      <DatePicker
        // format="DD/MM/YYYY"
        value={endDateSelection}
        allowClear={false}
        picker={pickerType ? pickerType : "date"}
        onChange={(date) => {
          // const endDate = date ? date.endOf("day") : null;
          const endDate = date
            ? pickerType
              ? pickerType === "date"
                ? date.endOf("day")
                : pickerType === "month"
                ? date.endOf("month")
                : pickerType === "year"
                ? date.endOf("year")
                : date.endOf("day")
              : date.endOf("day")
            : null;
          setEndDateSelection(endDate);
        }}
        // disabledDate={disabledEndDate}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Button
          icon={<SearchOutlined />}
          onClick={onFilterClick}
          // disabled={disabledButton()}
        />
        {onExportClick ? (
          <Button
            icon={saveLoading ? <LoadingOutlined /> : <DownloadOutlined />}
            onClick={onExportClick}
            // disabled={disabledButton()}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export function HorizontalDateFilter({
  startDateSelection,
  setStartDateSelection,
  endDateSelection,
  setEndDateSelection,
  onFilterClick,
  onExportClick,
  title,
}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        alignItems: "center",
      }}
    >
      <span>{title ? title : "Date : "}</span>
      <DatePicker.RangePicker
        format="DD/MM/YYYY"
        value={[startDateSelection, endDateSelection]}
        allowClear={false}
        picker="date"
        onChange={(dates, _) => {
          setStartDateSelection(dates[0]);
          setEndDateSelection(dates[1]);
        }}
      />
      <Button icon={<SearchOutlined />} onClick={onFilterClick} />
      {onExportClick ? (
        <Button icon={<DownloadOutlined />} onClick={onExportClick} />
      ) : (
        <></>
      )}
    </div>
  );
}

export function HorizontalDateMonthYearFilter({
  startDateSelection,
  setStartDateSelection,
  onFilter,
  onExportClick,
  pickerType,
  showTitle = true,
}) {
  let hasPicker = false;
  if (["date", "month", "year"].includes(pickerType)) {
    hasPicker = true;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        alignItems: "center",
      }}
    >
      {showTitle ? (
        <span>{pickerType.charAt(0).toUpperCase() + pickerType.slice(1)} </span>
      ) : (
        <></>
      )}

      <DatePicker
        value={startDateSelection}
        allowClear={false}
        picker={pickerType}
        onChange={(date, _) => {
          setStartDateSelection(date);
          onFilter(date);
        }}
        // disabled={!hasPicker}
      />
      {onExportClick ? (
        <Button icon={<DownloadOutlined />} onClick={onExportClick} />
      ) : (
        <></>
      )}
    </div>
  );
}
