import "../css/pages/System.css";
import {
  Card,
  Segmented,
  Flex,
  Select,
  Typography,
  theme,
  message,
} from "antd";
import { useEffect, useState } from "react";
import LoadUsage from "../component/System/LoadUsage";
import UtilityTNB from "../component/System/UtilityTNB";
import PowerMeter from "../component/System/PowerMeter";
import Inverter from "../component/System/Inverter";
import BESS from "../component/System/BESS";
import { fetchHelper } from "../functions/fetch";
import { BACKEND_URL } from "../configuration";
import { useOutletContext } from "react-router-dom";
const { Text } = Typography;
const { useToken } = theme;

function System() {
  const systemOptions = [
    { label: "PV System", value: "pvsystem" },
    { label: "Load Usage", value: "loadusage" },
    { label: "Utlity - TNB", value: "utility" },
    { label: "BESS", value: "bess" },
  ];
  const [systemSelection, setSystemSelection] = useState(
    systemOptions[0].value
  );

  const [
    siteSelect,
    setSiteSelect,
    siteChange,
    setSiteChange,
    isDarkMode,
    setIsDarkMode,
    subscribePpaBilling,
    subscribeCarbonManagement,
    subscribeAiPvDiagnosis,
    subscribeAiBess,
    hasInverter,
  ] = useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const [pvDeviceList, setPvDeviceList] = useState([]);
  const [utilityDeviceList, setUtilityDeviceList] = useState([]);
  const [inverterDeviceList, setInverterDeviceList] = useState([]);
  const [bessDeviceList, setBessDeviceList] = useState([]);

  const selectList = [
    {
      value: "powermeterTab",
      label: "Power Meter",
    },
    {
      value: "inverterlistTab",
      label: "Inverter List",
    },
  ];

  const [activeSelectValue, setactiveSelectValue] = useState(
    selectList[0].value
  );
  const onSelectChange = (value) => setactiveSelectValue(value);

  const [pvSystemCard, setPvSystemCard] = useState(false);
  const handlePvSystemCardChange = () => {
    setPvSystemCard(!pvSystemCard);
  };
  const [utilityCard, setUtilityCard] = useState(false);
  const handleUtilityCardChange = () => {
    setUtilityCard(!utilityCard);
  };

  const [bessCard, setBessCard] = useState(false);
  const handleBessCardChange = () => {
    setBessCard(!bessCard);
  };

  const [openModal, setOpenModal] = useState(false);
  const { token } = useToken();

  /** API to get difference type of device */
  function getDeviceName() {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setPvDeviceList(data.data.PV);
        setUtilityDeviceList(data.data.TNB);
        // setBessDeviceList(data?.data?.BESS ? data.data.BESS : [])
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/system/getDeviceName/${siteSelect}`,
      cb,
      setIsLoading,
      "Get Device Name Successfully",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get inverter list */
  function getInverterList() {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setInverterDeviceList(data.data);
        setIsLoading(false);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/system/getInverterList/${siteSelect}`,
      cb,
      setIsLoading,
      "Get Inverter List Successfully",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    setInverterDeviceList([]);
    setPvDeviceList([]);
    setUtilityDeviceList([]);

    if (siteSelect != undefined) {
      getDeviceName();
      getInverterList();

      const timer = setInterval(() => {
        getInverterList();
      }, 10000 * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelect]);

  const contentList = {
    powermeterTab: (
      <PowerMeter
        deviceTabData={pvDeviceList}
        pvSystemCard={pvSystemCard}
        handleChange={handlePvSystemCardChange}
        openModal={openModal}
        setOpenModal={setOpenModal}
        token={token}
        siteSelect={siteSelect}
      />
    ),
    inverterlistTab: (
      <Inverter
        tableData={inverterDeviceList}
        openModal={openModal}
        setOpenModal={setOpenModal}
        token={token}
      />
    ),
  };

  return (
    <>
      <Card
        className="context-card system-card"
        title={
          <div className="system-segmented-container">
            <Flex
              className="system-segmented-container-flex"
              justify="space-between"
              align="center"
              gap="small"
            >
              <Segmented
                className="system-segmented"
                size="large"
                options={systemOptions}
                onChange={(value) => {
                  setSystemSelection(value);
                }}
                value={systemSelection}
              />
              {systemSelection == "pvsystem" && hasInverter == true ? (
                <Flex
                  align="center"
                  gap="middle"
                  style={{ marginTop: "5px", marginBottom: "5px" }}
                  wrap="wrap"
                >
                  <Text>Data Select: </Text>
                  <Select
                    style={{ width: 125 }}
                    options={selectList}
                    value={activeSelectValue}
                    onChange={onSelectChange}
                  />
                </Flex>
              ) : (
                <></>
              )}
            </Flex>
          </div>
        }
        style={{ overflowY: "hidden" }}
        bodyStyle={{
          height: "85%",
          overflowX: "hidden",
          // overflowY: "scroll"
        }}
        loading={isLoading}
      >
        {systemSelection == "pvsystem" ? (
          contentList[activeSelectValue]
        ) : systemSelection == "loadusage" ? (
          <LoadUsage siteSelect={siteSelect} />
        ) : systemSelection == "utility" ? (
          <UtilityTNB
            deviceTabData={utilityDeviceList}
            utilityCard={utilityCard}
            handleChange={handleUtilityCardChange}
            openModal={openModal}
            setOpenModal={setOpenModal}
            token={token}
            siteSelect={siteSelect}
          />
        ) : systemSelection == "bess" ? (
          <BESS
            deviceTabData={bessDeviceList}
            bessCard={bessCard}
            handleChange={handleBessCardChange}
            openModal={openModal}
            setOpenModal={setOpenModal}
            token={token}
            siteSelect={siteSelect}
          />
        ) : (
          <></>
        )}
      </Card>
    </>
  );
}

export default System;
