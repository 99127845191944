import { Button, Card, Modal, Tooltip, message } from "antd";
import { useState, useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { Line } from "@ant-design/plots";
import { CustomTable } from "../common/table";
import { fetchHelper } from "../../functions/fetch";
import { BACKEND_URL } from "../../configuration";

function HistoryTable({ tableData, onViewClick }) {
  const truncateText = (text, limit) => {
    const words = text.split("");
    const check = words.length > limit;
    return check ? words.slice(0, limit).join("") + "..." : text;
  };
  const historyTableColumns = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Device",
      dataIndex: "Device",
      key: "Device",
    },
    {
      title: "String No.",
      dataIndex: "StringNo",
      key: "StringNo",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (text) => (
        <Tooltip title={text}>{truncateText(text, 20)}</Tooltip>
      ),
    },
    {
      title: "Start",
      dataIndex: "StartDateTime",
      key: "StartDateTime",
    },
    {
      title: "End",
      dataIndex: "EndDateTime",
      key: "EndDateTime",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record, index) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            onClick={() => {
              onViewClick(record);
            }}
          >
            View
          </Button>
        </div>
      ),
    },
  ];

  return (
    <CustomTable
      dataSource={tableData}
      columns={historyTableColumns}
      row_per_page={8}
      tableHeight={500}
      tableContainerStyleObject={{ height: "80%", minWidth: "700px" }}
    />
  );
}

function HistoryModal({ modelOpen, modalClose, isLoading, title, chart_data }) {
  const lineChartConfig = {
    autoFit: true,
    data: chart_data?.length ? chart_data : [],
    xField: "time",
    yField: "data",
    seriesField: "type",
    smooth: false,
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    point: false,
    xAxis: {
      title: {
        text: "Time",
        style: {
          fontSize: 16,
        },
      },
    },
    yAxis: {
      title: {
        text: "Parameter",
        style: {
          fontSize: 16,
        },
      },
    },
    color: ["grey", "#01063B", "#20C092"],
  };

  return (
    <>
      <Modal
        open={modelOpen}
        onCancel={() => modalClose()}
        footer={null}
        style={{ maxWidth: "90vw", minWidth: "70vw", height: "80vh" }}
      >
        <Card
          title={title?.length ? title : ""}
          style={{ marginTop: "30px" }}
          loading={isLoading}
        >
          <Line {...lineChartConfig} />
        </Card>
      </Modal>
    </>
  );
}

function History() {
  const [isLoading, setIsLoading] = useState(false);
  const [historyModel, setHistoryModal] = useState(false);
  const [historyTableData, setHistoryTableData] = useState([]);
  const [historyChartData, setHistoryChartData] = useState([]);
  const [viewRecordId, setViewRecordId] = useState(0);
  const [siteSelect, setSiteSelect, siteChange, setSiteChange] =
    useOutletContext();

  const onViewClick = (record) => {
    setHistoryModal(true);
    setViewRecordId(record.ID);
  };

  const onModalClose = () => {
    setHistoryModal(false);
  };

  const onDismissClick = () => {
    console.log("Dismiss Button");
  };

  function getHistoryRecords() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    console.log("site", siteSelect);

    const cb = (data) => {
      if (data?.status) {
        setHistoryTableData(data?.data ? data.data : []);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/pv_condition/getAllHistory`,
      cb,
      setIsLoading,
      "Get Alarm Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getChartData(idx) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        id: idx,
      }),
    };

    const cb = (data) => {
      if (data?.status) {
        setHistoryChartData(data?.data ? data.data : []);
      } else {
        message.error(data.message);
      }
    };

    if (idx) {
      setIsLoading(true);
      fetchHelper(
        `${BACKEND_URL}/pv_condition/getChartData`,
        cb,
        setIsLoading,
        "Get Chart Data",
        () => {},
        () => {},
        requestOptions,
        true
      );
    }
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getHistoryRecords();
    }
  }, [siteSelect]);

  useEffect(() => {
    getChartData(viewRecordId);
  }, [viewRecordId]);

  let modal_title = "";
  if (historyTableData?.length) {
    if (viewRecordId > 0) {
      const viewed_record = historyTableData.find(
        (element) => element.ID === viewRecordId
      );
      modal_title = viewed_record.Device + " - " + viewed_record.Parameter;
    }
  }

  return (
    <>
      <HistoryTable
        tableData={historyTableData}
        onViewClick={onViewClick}
        onDismissClick={onDismissClick}
      />
      <HistoryModal
        modelOpen={historyModel}
        modalClose={onModalClose}
        isLoading={isLoading}
        title={modal_title}
        chart_data={historyChartData}
      />
    </>
  );
}

export default History;
