import "../../css/pages/Management.css";
import { Button, Card, message } from "antd";
import { useEffect, useState } from "react";
import { BACKEND_URL } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { Modal } from "antd";
import DeviceManagement from "./DeviceManagement";
import { PlusOutlined } from "@ant-design/icons";
import DataReferenceSources from "./DataReferenceSources";
import PVSystDataEntry from "./PVSystDataEntry";

const tabList = [
  {
    key: "deviceManagementTab",
    tab: "Device Management",
  },
  {
    key: "dataReferenceSourcesTab",
    tab: "Data Reference Sources",
  },
  {
    key: "pvSystDataEntry",
    tab: "PVSyst Data Entry",
  },
];

function DeviceConfigurationModal({ modalOpen, modalClose, siteID }) {
  const [isLoading, setIsLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [activeTabKey, setactiveTabKey] = useState("deviceManagementTab");
  const onTabChange = (key) => {
    setactiveTabKey(key);
  };

  // Device Management
  const [deviceTable, setDeviceTable] = useState({});
  const [deviceType, setDeviceType] = useState({});
  const [editDeviceData, setEditDeviceData] = useState({});

  // Device Modal Form
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const modalOpenForTab = (tab) => {
    return addModalOpen && activeTabKey === tab;
  };
  const editModalOpenForTab = (tab) => {
    return editModalOpen && activeTabKey === tab;
  };
  const closeModal = () => {
    addModalOpen ? setAddModalOpen(false) : setEditModalOpen(false);
  };

  // Device Modal Form Edit
  const onEditClick = (id, tab) => {
    if (tab === "deviceManagementTab") {
      getDevice(id);
    }
    return setEditModalOpen(true);
  };
  const onDeleteClick = (id, tab) => {
    if (tab === "deviceManagementTab") {
      deleteDevice(id);
    }
  };

  // Device Modal Form Submit
  const addDeviceSubmit = (value) => {
    addDevice(value);
  };
  const editDeviceSubmit = (value) => {
    updateDevice(value);
  };

  /** API to get all the device data by site id*/
  function getAllDevices() {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setDeviceTable(data.data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/deviceManagement/getAllDevices/${siteID}`,
      cb,
      setIsLoading,
      "Get All Devices",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get all the device type */
  function getDeviceTypes() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setDeviceType(data.data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/deviceTypes/getAllDeviceType",
      cb,
      () => {},
      "Get All Device Types",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get the device by id */
  function getDevice(id) {
    setEditLoading(true);
    const requestOptions = {
      method: "GET",
      header: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setEditDeviceData(data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/deviceManagement/${id}`,
      cb,
      setEditLoading,
      "Get Specific Device",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to add device data*/
  function addDevice(value) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        deviceName: value.DeviceName,
        deviceNameLabel: value.DeviceNameLabel,
        siteId: siteID,
        deviceType: value.DeviceTypeId,
        activePower: value.RatingInkWp,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllDevices();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/deviceManagement/addDevice",
      cb,
      () => {},
      "Add Device",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to update device data */
  function updateDevice(value) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        deviceName: value.DeviceName,
        deviceNameLabel: value.DeviceNameLabel,
        siteId: siteID,
        deviceType: value.DeviceTypeId,
        activePower: value.RatingInkkWp,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllDevices();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/deviceManagement/${value.ID}`,
      cb,
      () => {},
      "Update Device",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to delete device data */
  function deleteDevice(id) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllDevices();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/deviceManagement/${id}`,
      cb,
      () => {},
      "Update Device",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  const configurationList = {
    deviceManagementTab: (
      <DeviceManagement
        tableData={deviceTable}
        editLoading={editLoading}
        editModalStatus={editModalOpen}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        deviceTypeOptions={deviceType}
        modalOpen={modalOpenForTab("deviceManagementTab")}
        editModalOpen={editModalOpenForTab("deviceManagementTab")}
        closeModal={closeModal}
        initialValues={editDeviceData}
        onAddSubmit={addDeviceSubmit}
        onEditSubmit={editDeviceSubmit}
      />
    ),
    dataReferenceSourcesTab: (
      <DataReferenceSources siteID={siteID} modalClose={modalClose} />
    ),
    pvSystDataEntry: (
      <PVSystDataEntry siteID={siteID} modalClose={modalClose} />
    ),
  };

  useEffect(() => {
    if (modalOpen) {
      getAllDevices();
    }

    getDeviceTypes();
  }, [modalOpen]);

  return (
    <Modal
      open={modalOpen}
      onCancel={() => modalClose()}
      footer={null}
      style={{ maxWidth: "90vw", minWidth: "70vw", height: "80vh" }}
    >
      <Card
        className="context-card management-card"
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        tabBarExtraContent={
          activeTabKey === "deviceManagementTab" && (
            <Button
              name="Add New Entry"
              type="text"
              icon={<PlusOutlined />}
              onClick={() => setAddModalOpen(true)}
            />
          )
        }
        style={{ marginTop: "30px" }}
        loading={isLoading}
      >
        <div
          style={{
            maxHeight: "calc(70vh - 62px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {configurationList[activeTabKey]}
        </div>
      </Card>
    </Modal>
  );
}

export default DeviceConfigurationModal;
