import { Form, Input, Flex, Button, Skeleton } from "antd";
import { FORM_REQUIRED } from "../../configuration";

function EmailForm(props) {
  const {
    setIsEmailFormModalOpen,
    mode,
    addEmail,
    updateEmail,
    form,
    submitButtonLoading,
    emailDetailsRetrievalLoading,
  } = props;

  return !emailDetailsRetrievalLoading ? (
    <Form
      layout="vertical"
      onFinish={(values) => {
        if (mode === "add") {
          addEmail(values);
        } else {
          updateEmail(values);
        }
      }}
      form={form}
      autoComplete="off"
    >
      <Form.Item
        label="PIC Name"
        name="PICName"
        rules={[{ required: FORM_REQUIRED, message: "Please input PIC name." }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="Email"
        rules={[
          {
            type: "email",
            message: "Please input a valid email.",
          },
          {
            required: FORM_REQUIRED,
            message: "Please input email.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Flex justify="center" gap="8px">
        <Button
          onClick={() => {
            setIsEmailFormModalOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" loading={submitButtonLoading}>
          Submit
        </Button>
      </Flex>
    </Form>
  ) : (
    <Skeleton
      active={true}
      style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
      paragraph={{ rows: 5 }}
    />
  );
}

export default EmailForm;
