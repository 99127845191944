import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import "../../css/component/dashboard/PVConditionAlarmContent.css";
import { useState, useEffect } from "react";
import { BACKEND_URL, MQTT_REFRESH_RATE } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { useNavigate, useOutletContext } from "react-router-dom";
import { message, Card, Skeleton } from "antd";

function PVConditionContent({ setRefreshCheck }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [siteSelect] = useOutletContext();

  const [noOutage, setNoOutage] = useState(true);
  const [noShading, setNoShading] = useState(true);
  const [noFuseBlow, setNoFuseBlow] = useState(true);
  const [noSoiling, setNoSoiling] = useState(true);

  const onCardClick = () => {
    navigate("/pvcondition");
  };

  const colorSetting = (condition) => {
    return condition ? { color: "green" } : { color: "red" };
  };

  const pvConditionIcon = (condition) => {
    return condition ? (
      <CheckCircleOutlined style={{ color: "green" }} />
    ) : (
      <CloseCircleOutlined style={{ color: "red" }} />
    );
  };

  function getPvConditionData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        if (data.pv_condition.length != 0) {
          setNoOutage(data.pv_condition[0]["NoOutage"]);
          setNoShading(data.pv_condition[0]["NoShading"]);
          setNoFuseBlow(data.pv_condition[0]["NoFuseBlow"]);
          setNoSoiling(data.pv_condition[0]["NoSoiling"]);
          setRefreshCheck(true);
        }
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getPvConditionData`,
      cb,
      setLoading,
      "Get Pv Condition Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getPvConditionData();

      const timer = setInterval(() => {
        getPvConditionData();
      }, MQTT_REFRESH_RATE * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelect]);

  return (
    <>
      <div
        onClick={() => {
          onCardClick();
        }}
      >
        {loading === false ? (
          <div className="pvcondition-alarm-content-container">
            <span>No Outage {pvConditionIcon(noOutage)}</span>
            <span>No Shading {pvConditionIcon(noShading)}</span>
            <span>No Fuse Blow {pvConditionIcon(noFuseBlow)}</span>
            <span>No Soiling {pvConditionIcon(noSoiling)}</span>
          </div>
        ) : (
          <Card
            style={{
              width: "100%",
              height: "100%",
            }}
          >
            <Skeleton
              active={true}
              style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
              paragraph={{ rows: 1 }}
            />
          </Card>
        )}
      </div>
    </>
  );
}

export default PVConditionContent;
