import { EllipsisOutlined } from "@ant-design/icons";
import { Card, Typography } from "antd";

const { Text } = Typography;

function DeviceCard({ data, setOpenModal }) {
  return (
    <Card
      bordered
      // extra={
      //   <EllipsisOutlined
      //     onClick={() => {
      //       setOpenModal(true);
      //     }}
      //   />
      // }
      style={{ borderRadius: 8 }}
      title={data.phasename}
    >
      <Card.Grid
        hoverable={false}
        style={{ width: "50%", paddingBlock: 12, paddingInline: 18 }}
      >
        <Text>Voltage</Text>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ width: "50%", paddingBlock: 12, paddingInline: 18 }}
      >
        <Text>{data.voltage}</Text>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ width: "50%", paddingBlock: 12, paddingInline: 18 }}
      >
        <Text>Current</Text>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ width: "50%", paddingBlock: 12, paddingInline: 18 }}
      >
        <Text>{data.current}</Text>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ width: "50%", paddingBlock: 12, paddingInline: 18 }}
      >
        <Text>Power Factor</Text>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ width: "50%", paddingBlock: 12, paddingInline: 18 }}
      >
        <Text>{data.powerfactor}</Text>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ width: "50%", paddingBlock: 12, paddingInline: 18 }}
      >
        <Text>Active Power</Text>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ width: "50%", paddingBlock: 12, paddingInline: 18 }}
      >
        <Text>{data.activepower}</Text>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ width: "50%", paddingBlock: 12, paddingInline: 18 }}
      >
        <Text>Reactive Power</Text>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={{ width: "50%", paddingBlock: 12, paddingInline: 18 }}
      >
        <Text>{data.reactivepower}</Text>
      </Card.Grid>
    </Card>
  );
}

export default DeviceCard;
