import { Card, Col, Row, Switch, Popconfirm, message, Skeleton } from "antd";
import "../css/pages/AiBess.css";
import { useEffect, useState } from "react";
import { BACKEND_URL, MQTT_REFRESH_RATE } from "../configuration";
import { fetchHelper } from "../functions/fetch";

function AiBess() {
  const [aiBessStatus, setAiBessStatus] = useState(null); // true means AI-BESS is on and vice versa
  const [aiBessStatusFirstLoad, setAiBessStatusFirstLoad] = useState(true);
  const [aiBessSwitchLoading, setAiBessSwitchLoading] = useState(false);

  const cancel = () => {
    message.success("Action is cancelled");
  };

  /** API to get AI-BESS status */
  function getAiBessStatus() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setAiBessStatus(data.is_ai_peak_shaving_on);
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/ai_bess/getAiBessStatus`,
      cb,
      setAiBessStatusFirstLoad,
      "Get AI-BESS Status",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to update AI-BESS status */
  function updateAiBessStatus(ai_bess_status_input) {
    setAiBessSwitchLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        ai_bess_status: ai_bess_status_input,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success("AI-BESS status is updated successfully.");
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/ai_bess/updateAiBessStatus`,
      cb,
      setAiBessSwitchLoading,
      "Update AI-BESS Status",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    getAiBessStatus();

    const timer = setInterval(() => {
      getAiBessStatus();
    }, MQTT_REFRESH_RATE * 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div
      style={{
        margin: "10px",
        width: "100%",
        height: "90vh",
        overflowY: "auto",
      }}
      className="ai-bess"
    >
      {aiBessStatusFirstLoad ? (
        <Card title={"AI-BESS Control"}>
          <Skeleton
            active={true}
            style={{ padding: "1rem", maxWidth: "90%", margin: "auto" }}
            paragraph={{ rows: 5 }}
          />
        </Card>
      ) : (
        <Card title={"AI-BESS Control"}>
          <Row style={{ width: "100%" }}>
            <Col span={12} style={{ textAlign: "right", padding: "0px 8px" }}>
              AI-BESS Status:
            </Col>
            <Col
              span={12}
              style={{
                color: aiBessStatus ? "green" : "red",
                fontWeight: "bold",
                padding: "0px 8px",
              }}
            >
              {aiBessStatus === true
                ? "ON"
                : aiBessStatus === false
                ? "OFF"
                : "UNKNOWN"}
            </Col>
          </Row>
          <Row style={{ width: "100%", marginTop: "24px" }}>
            <Col span={12} style={{ textAlign: "right", padding: "0px 8px" }}>
              AI-BESS Switch:
            </Col>
            <Col span={12} style={{ padding: "0px 8px" }}>
              <Popconfirm
                title="AI BESS Status"
                description={
                  aiBessStatus
                    ? "Are you sure to turn off AI-BESS mode?"
                    : "Are you sure to turn on AI-BESS mode?"
                }
                onConfirm={() => {
                  updateAiBessStatus(!aiBessStatus);
                  setAiBessStatus(!aiBessStatus);
                }}
                onCancel={cancel}
                okText="Yes"
                cancelText="No"
              >
                <Switch checked={aiBessStatus} disabled={aiBessSwitchLoading} />
              </Popconfirm>
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
}

export default AiBess;
