import "../../css/component/dashboard/PVConditionAlarmContent.css";
import { useState, useEffect } from "react";
import { BACKEND_URL, MQTT_REFRESH_RATE } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { useOutletContext } from "react-router-dom";
import { Tooltip, message, Card, Skeleton } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function AlarmContent({ setRefreshCheck }) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [siteSelect] = useOutletContext();

  const [online, setOnline] = useState(0);
  const [offline, setOffline] = useState(0);
  const [warning, setWarning] = useState(0);

  function getAlarmData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setOnline(data.online_count);
        setOffline(data.offline_count);
        setWarning(data.warning_count);
        setRefreshCheck(true);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getAlarmData`,
      cb,
      setLoading,
      "Get Alarm Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getAlarmData();

      const timer = setInterval(() => {
        getAlarmData();
      }, MQTT_REFRESH_RATE * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelect]);

  return (
    <>
      {loading === false ? (
        <div className="pvcondition-alarm-content-container">
          <span>
            Devices Offline: {offline}
            {offline !== "-" && offline !== 0 && (
              <Tooltip title="Click me to navigate to alarm page.">
                <InfoCircleOutlined
                  style={{ color: "#FF0000" }}
                  onClick={() => {
                    navigate("/alarm");
                  }}
                />
              </Tooltip>
            )}
          </span>

          <span>
            Has Warning: {warning}
            {warning !== "-" && warning !== 0 && (
              <Tooltip title="Click me to navigate to alarm page.">
                <InfoCircleOutlined
                  style={{ color: "#E4D00A" }}
                  onClick={() => {
                    navigate("/alarm");
                  }}
                />
              </Tooltip>
            )}
          </span>
        </div>
      ) : (
        <Card
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Skeleton
            active={true}
            style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
            paragraph={{ rows: 1 }}
          />
        </Card>
      )}
    </>
  );
}

export default AlarmContent;
