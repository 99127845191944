import "../../css/pages/Management.css";
import { Card } from "antd";
import { useState } from "react";
import { Modal } from "antd";
import Email from "./Email";
import Telegram from "./Telegram";

const tabList = [
  {
    key: "telegramTab",
    tab: "Telegram",
  },
  {
    key: "emailTab",
    tab: "Email",
  },
];

function AlarmNotificationConfigModal({ modalOpen, modalClose, siteID }) {
  const [activeTabKey, setactiveTabKey] = useState("telegramTab");
  const onTabChange = (key) => {
    setactiveTabKey(key);
  };

  const configurationList = {
    telegramTab: <Telegram siteID={siteID} />,
    emailTab: <Email siteID={siteID} />,
  };

  return (
    <Modal
      open={modalOpen}
      onCancel={() => modalClose()}
      footer={null}
      style={{ maxWidth: "90vw", minWidth: "70vw", height: "80vh" }}
    >
      <Card
        className="context-card management-card"
        tabList={tabList}
        activeTabKey={activeTabKey}
        onTabChange={onTabChange}
        style={{ marginTop: "30px" }}
      >
        <div
          style={{
            maxHeight: "calc(70vh - 62px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          {configurationList[activeTabKey]}
        </div>
      </Card>
    </Modal>
  );
}

export default AlarmNotificationConfigModal;
