import { useEffect, useState } from "react";
import { LabelValueUnitDisplayRow } from "../common/wordDisplay";
import { BACKEND_URL, MQTT_REFRESH_RATE } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { message, Skeleton } from "antd";

function LoadUsageContent({ systemData, firstLoad, systemLastUpdate }) {
  return firstLoad ? (
    <Skeleton active style={{ margin: "5px" }} />
  ) : (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          align: "center",
          marginBottom: "12px",
        }}
      >
        <h2 style={{ margin: "0px" }}>Load</h2>
        <span>Last Update: {systemLastUpdate}</span>
      </div>
      <div className="system-col">
        <div className="system-row">
          <div className="content-box">
            <LabelValueUnitDisplayRow
              label={"Real Power"}
              value={
                systemData?.realPower !== undefined ? systemData.realPower : "-"
              }
              unit={"kW"}
            />
            <LabelValueUnitDisplayRow
              label={"Reactive Power"}
              value={
                systemData?.reactivePower !== undefined
                  ? systemData.reactivePower
                  : "-"
              }
              unit={"kVAR"}
            />
            <LabelValueUnitDisplayRow
              label={"Apparent Power"}
              value={
                systemData?.apparentPower !== undefined
                  ? systemData.apparentPower
                  : "-"
              }
              unit={"kVA"}
            />
            <LabelValueUnitDisplayRow
              label={"Load Factor"}
              value={
                systemData?.loadFactor !== undefined
                  ? systemData.loadFactor
                  : "-"
              }
              unit={""}
            />
            <LabelValueUnitDisplayRow
              label={"Power Factor"}
              value={
                systemData?.powerFactor !== undefined
                  ? systemData.powerFactor
                  : "-"
              }
              unit={""}
            />
          </div>
          <div className="content-box">
            <LabelValueUnitDisplayRow
              label={"Energy Consumption (Peak) - Month"}
              value={
                systemData?.onPeakConsumption !== undefined
                  ? systemData.onPeakConsumption
                  : "-"
              }
              unit={"kWh"}
            />
            <LabelValueUnitDisplayRow
              label={"Energy Consumption (Off-Peak) - Month"}
              value={
                systemData?.offPeakConsumption !== undefined
                  ? systemData.offPeakConsumption
                  : "-"
              }
              unit={"kWh"}
            />
            <LabelValueUnitDisplayRow
              label={"Energy Consumption - Month"}
              value={
                systemData?.consumption !== undefined
                  ? systemData.consumption
                  : "-"
              }
              unit={"kWh"}
            />
            <LabelValueUnitDisplayRow
              label={"Reactive Energy Consumption - Month"}
              value={
                systemData?.reactiveConsumption !== undefined
                  ? systemData.reactiveConsumption
                  : "-"
              }
              unit={"kVARh"}
            />
            <LabelValueUnitDisplayRow
              label={"Maximum Demand - Month"}
              value={
                systemData?.maximumDemand !== undefined
                  ? systemData.maximumDemand
                  : "-"
              }
              unit={"kW"}
            />
          </div>
          <div className="content-box">{/* empty space */}</div>
        </div>
      </div>
    </div>
  );
}

function LoadUsage(props) {
  const [systemData, setSystemData] = useState({});
  const [firstLoad, setFirstLoad] = useState(true);

  const [systemLastUpdate, setSystemLastUpdate] = useState(null);

  const siteSelect = props?.siteSelect ? props.siteSelect : null;

  function getSystemData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setSystemData(data.data);
        setSystemLastUpdate(data.last_update);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/system/getSystemLoad/${siteSelect}`,
      cb,
      setFirstLoad,
      "Get load system Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    getSystemData();

    const timer = setInterval(() => {
      getSystemData();
    }, MQTT_REFRESH_RATE * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [siteSelect]);

  return (
    <LoadUsageContent
      systemData={systemData}
      firstLoad={firstLoad}
      systemLastUpdate={systemLastUpdate}
    />
  );
}

export default LoadUsage;
