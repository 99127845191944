import { Button, Table, Flex, Modal, message, Form, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import EmailForm from "./EmailForm";
import { fetchHelper } from "../../functions/fetch";
import { BACKEND_URL } from "../../configuration";

function Email(props) {
  const { siteID } = props;

  const [alarmEmailConfigForm] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isAddEmailFormModalOpen, setIsAddEmailFormModalOpen] = useState(false);
  const [isEditEmailFormModalOpen, setIsEditEmailFormModalOpen] =
    useState(false);

  const [alarmEmailTableData, setAlarmEmailTableData] = useState([]);
  const [editingAlarmEmailID, setEditingAlarmEmailID] = useState(null);
  const [deletingAlarmEmailID, setDeletingAlarmEmailID] = useState(null);

  // loading states
  const [alarmEmailTableDataLoading, setAlarmEmailTableDataLoading] =
    useState(false);
  const [saveSelectedEmailsLoading, setSaveSelectedEmailsLoading] =
    useState(false);
  const [submitEmailDetailsButtonLoading, setSubmitEmailDetailsButtonLoading] =
    useState(false);
  const [emailDetailsRetrievalLoading, setEmailDetailsRetrievalLoading] =
    useState(false);

  const columns = [
    {
      title: "PIC",
      dataIndex: "PICName",
      key: "PICName",
      width: "40%",
    },
    {
      title: "Email",
      dataIndex: "Email",
      key: "Email",
      width: "50%",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "10%",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-start" }}
        >
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => {
              setIsEditEmailFormModalOpen(true);
              getEmail(record.ID);
              setEditingAlarmEmailID(record.ID);
            }}
          />
          <Popconfirm
            title="Delete Email"
            description={"Are you sure to delete this email?"}
            onConfirm={() => {
              deleteEmail(record.ID);
            }}
            onCancel={() => {
              message.success("Action is cancelled");
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => {
                setDeletingAlarmEmailID(record.ID);
              }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  /** API to add email data*/
  function addEmail(value) {
    setSubmitEmailDetailsButtonLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteID,
        email: value.Email,
        pic: value.PICName,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setIsAddEmailFormModalOpen(false);
        alarmEmailConfigForm.resetFields();
        getAllEmail();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_email_notification_config/addEmail",
      cb,
      setSubmitEmailDetailsButtonLoading,
      "Add Alarm Email Config",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get all email data*/
  function getAllEmail() {
    setAlarmEmailTableDataLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteID,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setSelectedRowKeys(data.row_key_list);
        setAlarmEmailTableData(data.alarm_details);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_email_notification_config/getAllEmail",
      cb,
      setAlarmEmailTableDataLoading,
      "Get All Alarm Email",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get email that user choose to edit */
  function getEmail(alarm_email_id) {
    setEmailDetailsRetrievalLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        alarm_email_id: alarm_email_id,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        alarmEmailConfigForm.setFieldsValue(data.email_details);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_email_notification_config/getEmail",
      cb,
      setEmailDetailsRetrievalLoading,
      "Get Email User Chooses to Edit",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to update email that user choose to edit */
  function updateEmail(value) {
    setSubmitEmailDetailsButtonLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        alarm_email_id: editingAlarmEmailID,
        email: value.Email,
        pic: value.PICName,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setIsEditEmailFormModalOpen(false);
        alarmEmailConfigForm.resetFields();
        getAllEmail();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_email_notification_config/updateEmail",
      cb,
      setSubmitEmailDetailsButtonLoading,
      "Update Email User Chooses to Edit",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to delete email that user choose to delete */
  function deleteEmail(value) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        alarm_email_id: deletingAlarmEmailID,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllEmail();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_email_notification_config/deleteEmail",
      cb,
      () => {},
      "Delete Email User Chooses to Delete",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to save email that user selects */
  function saveSelectedEmails() {
    setSaveSelectedEmailsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        row_key_list: selectedRowKeys,
        site_id: siteID,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllEmail();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_email_notification_config/saveSelectedEmails",
      cb,
      setSaveSelectedEmailsLoading,
      "Save Selected Emails",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteID) {
      getAllEmail();
    }
  }, [siteID]);

  return (
    <Flex vertical gap={"8px"}>
      <Button
        icon={<PlusOutlined />}
        style={{ width: "200px" }}
        onClick={() => setIsAddEmailFormModalOpen(true)}
      >
        New Email
      </Button>

      <Modal
        title="Add New Email"
        open={isAddEmailFormModalOpen}
        footer={null}
        closable={false}
      >
        <EmailForm
          setIsEmailFormModalOpen={setIsAddEmailFormModalOpen}
          mode={"add"}
          addEmail={addEmail}
          form={alarmEmailConfigForm}
          submitButtonLoading={submitEmailDetailsButtonLoading}
        />
      </Modal>

      <Modal
        title="Edit Email"
        open={isEditEmailFormModalOpen}
        footer={null}
        closable={false}
      >
        <EmailForm
          setIsEmailFormModalOpen={setIsEditEmailFormModalOpen}
          mode={"edit"}
          updateEmail={updateEmail}
          form={alarmEmailConfigForm}
          submitButtonLoading={submitEmailDetailsButtonLoading}
          emailDetailsRetrievalLoading={emailDetailsRetrievalLoading}
        />
      </Modal>

      <Table
        loading={alarmEmailTableDataLoading}
        columns={columns}
        dataSource={alarmEmailTableData}
        pagination={false}
        scroll={{ y: 450, x: true }}
        size="small"
        rowSelection={{
          ...rowSelection,
          checkStrictly: false,
        }}
      />
      <Button
        style={{ width: "100px", alignSelf: "center" }}
        onClick={() => {
          saveSelectedEmails();
        }}
        loading={saveSelectedEmailsLoading}
        emailDetailsRetrievalLoading={emailDetailsRetrievalLoading}
      >
        Save
      </Button>
    </Flex>
  );
}

export default Email;
