import "../css/pages/Maintenance.css";
import { Card, Select, Button, Form, Input, message, Spin } from "antd";
import {
  PlusOutlined,
  DownloadOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { HorizontalDateFilter } from "../component/common/dateFilter";
import { CustomTable } from "../component/common/table";
import { useTableSearch } from "../component/common/tableSearch";
import dayjs from "dayjs";
import { ModalForm } from "../component/common/modalForm";
import TextArea from "antd/es/input/TextArea";
import { fetchHelper } from "../functions/fetch";
import { BACKEND_URL } from "../configuration";
import { useOutletContext } from "react-router-dom";

const { Option } = Select;

function MaintenanceTable({
  data,
  onEditClick,
  siteSelection,
  siteSelectionMap,
}) {
  const { getColumnSearchProps } = useTableSearch();

  const maintenanceTableColumns = [
    {
      title: "No. ",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Site",
      dataIndex: "SiteId",
      key: "SiteId",
      render: (SiteId) => siteSelectionMap[SiteId] || SiteId,
      filters: siteSelection
        ? Object.keys(siteSelection).map((key) => ({
            text: siteSelection[key].SiteShortName,
            value: siteSelection[key].ID,
          }))
        : [],
      onFilter: (value, record) => record.SiteId === value,
    },
    {
      title: "Issue Date Time",
      dataIndex: "IssueDateTime",
      key: "IssueDateTime",
    },
    {
      title: "Status",
      dataIndex: "Status",
      key: "Status",
      filters: [
        {
          text: "Pending",
          value: "Pending",
        },
        {
          text: "Completed",
          value: "Completed",
        },
      ],
      onFilter: (value, record) => record.Status.startsWith(value),
    },
    {
      title: "Assignee",
      dataIndex: "Assignee",
      key: "Assignee",
      ...getColumnSearchProps("Assignee"),
    },
    {
      title: "Completion Date Time",
      dataIndex: "CompletionDateTime",
      key: "CompletionDateTime",
      render: (text) => (text ? text : "-"),
    },
    {
      title: "Log",
      dataIndex: "log",
      key: "log",
      render: (_, record, index) => (
        <Button type="link" onClick={() => onEditClick(record.ID)}>
          Log
        </Button>
      ),
    },
  ];

  return (
    <CustomTable
      dataSource={data}
      columns={maintenanceTableColumns}
      row_per_page={8} // to fit the screen when at 100%
      tableHeight={500} // to fit the screen when at 100%
      tableContainerStyleObject={{ height: "80%", minWidth: "700px" }}
    />
  );
}

function EditMaintenanceFormBody({ siteSelection }) {
  return (
    <>
      <Form.Item label="Site" name="Site">
        <Select disabled>
          {siteSelection.map((data) => (
            <Option key={data.ID} value={data.ID}>
              {data.SiteShortName}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label="Assignee" name="Assignee">
        <Input disabled />
      </Form.Item>
      <Form.Item label="Status" name="Status">
        <Select>
          <Option key="Pending" name="Pending">
            Pending
          </Option>
          <Option key="Completed" name="Completed">
            Completed
          </Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Description"
        name="Description"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item
        label="Action log"
        name="ActionLog"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <TextArea rows={6} />
      </Form.Item>
    </>
  );
}

function EditMaintenanceModalForm({
  modalOpen,
  closeModal,
  editLoading,
  siteSelection,
  intialValues,
  onSubmit,
}) {
  const [editMaintenanceForm] = Form.useForm();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      modalTitle={"View/Edit Maintenance Log"}
      formObject={editMaintenanceForm}
      formName={"editMaintenanceForm"}
      formBody={
        editLoading ? (
          <Spin
            style={{ width: "100%" }}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 80,
                  color: "#000038",
                }}
                spin
              />
            }
          />
        ) : (
          <EditMaintenanceFormBody siteSelection={siteSelection} />
        )
      }
      formIntitialValues={intialValues}
      formSubmit={onSubmit}
    />
  );
}

function MaintenanceFormBody({ siteSelection }) {
  return (
    <>
      <Form.Item
        label="Site"
        name="Site"
        rules={[{ required: true, message: "Site cannot be empty" }]}
      >
        <Select>
          {siteSelection.map((data) => (
            <Option key={data.ID} value={data.ID}>
              {data.SiteShortName}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="Assignee"
        name="Assignee"
        rules={[{ required: true, message: "Assignee cannot be empty" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Description:"
        name="Description"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[{ required: true, message: "Description cannot be empty" }]}
      >
        <TextArea rows={6} />
      </Form.Item>
    </>
  );
}

function MaintenanceModalForm({
  modalOpen,
  closeModal,
  siteSelection,
  intialValues,
  onSubmit,
}) {
  const [maintenanceForm] = Form.useForm();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      modalTitle={"Create Work Order"}
      formObject={maintenanceForm}
      formName={"maintenanceForm"}
      formBody={<MaintenanceFormBody siteSelection={siteSelection} />}
      formIntitialValues={intialValues}
      formSubmit={onSubmit}
    />
  );
}

function Maintenance() {
  const [siteSelect, setSiteSelect, siteChange, setSiteChange] =
    useOutletContext();
  const [isLoading, setIsLoading] = useState(true);
  const [editLoading, setEditLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const [siteSelection, setSiteSelection] = useState([]);
  const [filterMaintenaceTable, setFilterMaintenanceTable] = useState();
  const [editMaintenanceData, setEditMaintenanceData] = useState({});

  const [startDateSelection, setStartDateSelection] = useState(
    dayjs().subtract(6, "day")
  );
  const [endDateSelection, setEndDateSelection] = useState(dayjs());

  const [isModalOpen, setIsModalOpen] = useState();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const closeModal = () => setIsModalOpen(false);
  const closeEditModal = () => setIsEditModalOpen(false);

  const filterData = (data) => {
    return setFilterMaintenanceTable(data);
  };

  const onFilterClick = () => {
    getAllMaintenances();
  };

  const onEditClick = (id) => {
    getMaintenance(id);
    return setIsEditModalOpen(true);
  };

  const onExportClick = () => {
    if (filterMaintenaceTable.length < 1) {
      message.error("Please get the data first");
    } else {
      saveMaintenance();
    }
  };

  const onAddSubmit = (value) => {
    addMaintenance(value);
  };

  const onEditSubmit = (value) => {
    editMaintenance(value);
  };

  const siteSelectionMap = {};

  if (siteSelection && typeof siteSelection === "object") {
    Object.keys(siteSelection).forEach((key) => {
      siteSelectionMap[siteSelection[key].ID] =
        siteSelection[key].SiteShortName;
    });
  }

  function getAllMaintenances() {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        startDate: startDateSelection.$d,
        endDate: endDateSelection.$d,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        filterData(data.data);
        setIsLoading(false);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/maintenance/getAllMaintenance",
      cb,
      () => {},
      "Get All Maintenances",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getSiteSelection() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setSiteSelection(data.data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/maintenance/getSiteSelection",
      cb,
      () => {},
      "Get Site Selection",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getMaintenance(id) {
    setEditLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setEditMaintenanceData(data.data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/maintenance/getMaintenance/${id}`,
      cb,
      setEditLoading,
      "Get Maintenance",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function addMaintenance(value) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        siteId: value.Site,
        assignee: value.Assignee,
        description: value.Description,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllMaintenances();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/maintenance/insertMaintenance",
      cb,
      () => {},
      "Insert Maintenance",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function editMaintenance(value) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        siteId: value.Site,
        status: value.Status,
        assignee: value.Assignee,
        description: value.Description,
        actionLog: value.ActionLog,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllMaintenances();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/maintenance/updateMaintenance/${value.ID}`,
      cb,
      () => {},
      "Insert Maintenance",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function saveMaintenance() {
    setSaveLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: filterMaintenaceTable,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        downloadMaintenanceData(data.download_link);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/maintenance/saveMaintenance",
      cb,
      setSaveLoading,
      "Save Maintenance Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function downloadMaintenanceData(filename) {
    const downloadLink = document.createElement("a");
    downloadLink.href = BACKEND_URL + `/maintenance/download/${filename}`;
    downloadLink.setAttribute("download", filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  useEffect(() => {
    getSiteSelection();
    getAllMaintenances();
  }, [siteSelect]);

  return (
    <Card
      title={"Maintenance"}
      className="context-card"
      style={{ overflowY: "hidden", margin: "10px" }}
      bodyStyle={{
        height: "95%",
        // overflowY: "scroll"
      }}
      loading={isLoading}
    >
      <div className="maintenance-filter">
        <div className="maintenance-status-sites"></div>
        <div className="maintenance-date-export-new">
          <HorizontalDateFilter
            startDateSelection={startDateSelection}
            setStartDateSelection={setStartDateSelection}
            endDateSelection={endDateSelection}
            setEndDateSelection={setEndDateSelection}
            onFilterClick={() => onFilterClick()}
            title={"Issue Date: "}
          />
          <Button
            icon={saveLoading ? <LoadingOutlined /> : <DownloadOutlined />}
            onClick={() => onExportClick()}
            disabled={saveLoading}
          />
          <Button
            icon={<PlusOutlined />}
            onClick={() => setIsModalOpen(true)}
          />
        </div>
      </div>
      <MaintenanceTable
        data={filterMaintenaceTable}
        onEditClick={onEditClick}
        siteSelection={siteSelection}
        siteSelectionMap={siteSelectionMap}
      />

      <MaintenanceModalForm
        modalOpen={isModalOpen}
        closeModal={closeModal}
        siteSelection={siteSelection}
        intialValues={{}}
        onSubmit={onAddSubmit}
      />

      <EditMaintenanceModalForm
        modalOpen={isEditModalOpen}
        closeModal={closeEditModal}
        editLoading={editLoading}
        siteSelection={siteSelection}
        intialValues={editMaintenanceData}
        onSubmit={onEditSubmit}
      />
    </Card>
  );
}

export default Maintenance;
