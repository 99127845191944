import {
  Progress,
  Tag,
  Segmented,
  Card,
  message,
  Flex,
  Tooltip,
  Skeleton,
} from "antd";
import { useState, useEffect } from "react";
import "../../css/component/dashboard/SummaryCard.css";
import { useOutletContext } from "react-router-dom";
import { BACKEND_URL } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  DARK_BLUE,
  LIGHT_BLUE,
  DARK_GREEN,
  LIGHT_GREEN,
} from "../../configuration";

function SingleContent({
  title,
  var1label,
  var2label,
  var1value,
  var2value,
  unit,
  tnbMDDateTime,
}) {
  const percentage1 = 100 * (var1value / (var1value + var2value));
  const percentage2 = 100 - percentage1;

  return (
    <div className="summary-content-content-container">
      <span>
        {title}:
        <span className="summary-content-value">
          {var1value !== "-" && var2value !== "-"
            ? `${parseFloat(var1value + var2value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${unit}`
            : "-"}
        </span>
      </span>
      <Progress
        percent={percentage1}
        strokeColor={DARK_GREEN}
        trailColor={DARK_BLUE}
        showInfo={false}
        size={["100%", 20]}
      />
      <div className="summary-content-detail">
        <Tag color={DARK_GREEN}></Tag>

        <span>
          {var1label}:
          <span className="summary-content-value">
            {var1value !== "-"
              ? `${parseFloat(var1value).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })} ${unit}`
              : `${var1value}`}
          </span>
        </span>
        <span className="summary-content-percentage">
          {percentage1
            ? `${percentage1.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`
            : percentage1 === 0
            ? `${percentage1.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`
            : null}
        </span>
      </div>

      <div className="summary-content-detail">
        <Tag
          color={title === "System production (AC)" ? LIGHT_GREEN : DARK_BLUE}
        ></Tag>

        <span>
          {var2label}:
          <span className="summary-content-value">
            {var2value !== "-" ? (
              <>
                {parseFloat(var2value).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                {unit}{" "}
                {title === "Max Demand" && (
                  <Tooltip title={"Maximum demand occurs at: " + tnbMDDateTime}>
                    <InfoCircleOutlined />
                  </Tooltip>
                )}
              </>
            ) : (
              `${var2value}`
            )}
          </span>
        </span>
        <span className="summary-content-percentage">
          {percentage2
            ? `${percentage2.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`
            : percentage2 === 0
            ? `${percentage2.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}%`
            : null}
        </span>
      </div>
    </div>
  );
}

function SingleContentMd({
  title,
  var1label,
  var2label,
  var3label,
  var1value,
  var2value,
  var3value,
  unit,
  tnbMDDateTime,
}) {
  let percentage1 = 100 * (var1value / (var1value + var2value + var3value));
  let percentage2 = 100 * (var2value / (var1value + var2value + var3value));
  let percentage3 = 100 - percentage1 - percentage2;

  return (
    <div className="summary-content-content-container">
      <span>
        {title}:
        <span className="summary-content-value">
          {var1value !== "-" && var2value !== "-" && var3value !== "-"
            ? `${parseFloat(var1value + var2value + var3value).toFixed(
                2
              )} ${unit}`
            : "-"}
        </span>
      </span>

      <Progress
        percent={percentage1 + percentage2}
        success={{ strokeColor: DARK_GREEN, percent: percentage1 }}
        strokeColor={LIGHT_BLUE}
        trailColor={DARK_BLUE}
        showInfo={false}
        size={["100%", 20]}
      />

      <div className="summary-content-detail">
        <Tag color={DARK_GREEN}></Tag>

        <span>{var1label}:</span>

        <span className="summary-content-percentage">
          {var1value !== "-"
            ? `${parseFloat(var1value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${unit}`
            : `${var1value}`}
        </span>
      </div>

      <div className="summary-content-detail">
        <Tag color={LIGHT_BLUE}></Tag>

        <span>{var2label}:</span>

        <span className="summary-content-percentage">
          {var2value !== "-"
            ? `${parseFloat(var2value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${unit}`
            : `${var2value}`}
        </span>
      </div>

      <div className="summary-content-detail">
        <Tag color={DARK_BLUE}></Tag>

        <span>{var3label}:</span>

        <span className="summary-content-percentage">
          {var3value !== "-" ? (
            <>
              {parseFloat(var3value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {unit}{" "}
              {title === "Max Demand" && (
                <Tooltip title={"Maximum demand occurs on: " + tnbMDDateTime}>
                  <InfoCircleOutlined />
                </Tooltip>
              )}
            </>
          ) : (
            `${var3value}`
          )}
        </span>
      </div>
    </div>
  );
}

function SingleContentMdNoBess({
  title,
  var1label,
  var2label,
  var1value,
  var2value,
  unit,
  tnbMDDateTime,
}) {
  let percentage1 = 100 * (var1value / (var1value + var2value));
  let percentage2 = 100 - percentage1;

  return (
    <div className="summary-content-content-container">
      <span>
        {title}:
        <span className="summary-content-value">
          {var1value !== "-" && var2value !== "-"
            ? `${parseFloat(var1value + var2value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${unit}`
            : "-"}
        </span>
      </span>

      <Progress
        percent={percentage1}
        strokeColor={DARK_GREEN}
        trailColor={DARK_BLUE}
        showInfo={false}
        size={["100%", 20]}
      />

      <div className="summary-content-detail">
        <Tag color={DARK_GREEN}></Tag>

        <span>{var1label}:</span>

        <span className="summary-content-percentage">
          {var1value !== "-"
            ? `${parseFloat(var1value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} ${unit}`
            : `${var1value}`}
        </span>
      </div>

      <div className="summary-content-detail">
        <Tag color={DARK_BLUE}></Tag>

        <span>{var2label}:</span>

        <span className="summary-content-percentage">
          {var2value !== "-" ? (
            <>
              {parseFloat(var2value).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              {unit}{" "}
              {title === "Max Demand" && (
                <Tooltip title={"Maximum demand occurs on: " + tnbMDDateTime}>
                  <InfoCircleOutlined />
                </Tooltip>
              )}
            </>
          ) : (
            `${var2value}`
          )}
        </span>
      </div>
    </div>
  );
}

function SummaryCard({ className }) {
  const [loading, setLoading] = useState(false);
  const [
    siteSelect,
    setSiteSelect,
    siteChange,
    setSiteChange,
    isDarkMode,
    setIsDarkMode,
    subscribePpaBilling,
    subscribeCarbonManagement,
    subscribeAiPvDiagnosis,
    subscribeAiBess,
  ] = useOutletContext();

  const summaryDateTypeOptions = [
    { label: "Day", value: "day" },
    { label: "Month", value: "month" },
    { label: "Year", value: "year" },
  ];

  const [summaryDateSelection, setSummaryDateSelection] = useState(
    summaryDateTypeOptions[0].value
  );

  const [pvSelfConsumption, setPvSelfConsumption] = useState(0);
  const [tnbGridExport, setTnbGridExport] = useState(0);
  const [tnbGridSupplied, setTnbGridSupplied] = useState(0);
  const [MDReducedByPv, setMDReducedByPv] = useState(0);
  const [MDReducedByBess, setMDReducedByBess] = useState(0);
  const [tnbMD, setTnbMD] = useState(0);
  const [tnbMDDateTime, setTnbMDDateTime] = useState("");

  function getDaySummary() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setPvSelfConsumption(data.pv_self_consumption);
        setTnbGridExport(data.tnb_grid_export);
        setTnbGridSupplied(data.tnb_grid_supplied);
        setTnbMD(data.tnb_day_MD);
        setMDReducedByPv(data.MD_reduced_by_pv);
        setMDReducedByBess(data.MD_reduced_by_bess);
        setTnbMDDateTime(data.tnb_day_MD_datetime);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getDaySummary`,
      cb,
      setLoading,
      "Get Day Summary",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getMonthSummary() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setPvSelfConsumption(data.pv_self_consumption);
        setTnbGridExport(data.tnb_grid_export);
        setTnbGridSupplied(data.tnb_grid_supplied);
        setTnbMD(data.tnb_month_MD);
        setMDReducedByPv(data.MD_reduced_by_pv);
        setMDReducedByBess(data.MD_reduced_by_bess);
        setTnbMDDateTime(data.tnb_month_MD_datetime);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getMonthSummary`,
      cb,
      setLoading,
      "Get Month Summary",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getYearSummary() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setPvSelfConsumption(data.pv_self_consumption);
        setTnbGridExport(data.tnb_grid_export);
        setTnbGridSupplied(data.tnb_grid_supplied);
        setTnbMD(data.tnb_year_MD);
        setMDReducedByPv(data.MD_reduced_by_pv);
        setMDReducedByBess(data.MD_reduced_by_bess);
        setTnbMDDateTime(data.tnb_year_MD_datetime);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getYearSummary`,
      cb,
      setLoading,
      "Get Year Summary",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      if (summaryDateSelection === "day") {
        getDaySummary();

        const timer = setInterval(() => {
          getDaySummary();
        }, 30 * 60 * 1000);

        return () => {
          clearInterval(timer);
        };
      } else if (summaryDateSelection === "month") {
        getMonthSummary();

        const timer = setInterval(() => {
          getMonthSummary();
        }, 30 * 60 * 1000);

        return () => {
          clearInterval(timer);
        };
      } else if (summaryDateSelection === "year") {
        getYearSummary();

        const timer = setInterval(() => {
          getYearSummary();
        }, 30 * 60 * 1000);

        return () => {
          clearInterval(timer);
        };
      }
    }
  }, [summaryDateSelection, siteSelect]);

  return (
    <>
      {loading === false ? (
        <Card
          className={className}
          title={
            <Flex justify="space-between">
              <span>Summary</span>
              <span>
                <Tooltip
                  title={"Disclaimer: The values are updated every 30 minutes."}
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            </Flex>
          }
          bodyStyle={{
            height: loading ? "100%" : "auto",
            display: loading ? "flex" : "block",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Segmented
            className="summary-content-segmented"
            block
            options={summaryDateTypeOptions}
            onChange={(value) => {
              setSummaryDateSelection(value);
            }}
            value={summaryDateSelection}
          />
          <div className="summary-content-container">
            <SingleContent
              title={"System production (AC)"}
              var1label={"Self-Consumption"}
              var2label={"Grid Export"}
              var1value={pvSelfConsumption}
              var2value={tnbGridExport}
              unit={"kWh"}
              tnbMDDateTime={tnbMDDateTime}
            />
            <SingleContent
              title={"Load Consumption"}
              var1label={"PV System"}
              var2label={"Grid Supplied"}
              var1value={pvSelfConsumption}
              var2value={tnbGridSupplied}
              unit={"kWh"}
              tnbMDDateTime={tnbMDDateTime}
            />
            {subscribeAiBess ? (
              <SingleContentMd
                title={"Max Demand"}
                var1label={"Reduced by PV"}
                var2label={"Reduced by BESS"}
                var3label={"Grid Supplied"}
                var1value={MDReducedByPv}
                var2value={MDReducedByBess}
                var3value={tnbMD}
                unit={"kW"}
                tnbMDDateTime={tnbMDDateTime}
              />
            ) : (
              <SingleContentMdNoBess
                title={"Max Demand"}
                var1label={"Reduced by PV"}
                var2label={"Grid Supplied"}
                var1value={MDReducedByPv}
                var2value={tnbMD}
                unit={"kW"}
                tnbMDDateTime={tnbMDDateTime}
              />
            )}
          </div>
        </Card>
      ) : (
        <Card style={{ width: "100%", height: "100%" }}>
          <Skeleton
            active={true}
            style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
            paragraph={{ rows: 10 }}
          />
        </Card>
      )}
    </>
  );
}

export default SummaryCard;
