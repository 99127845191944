import "../css/component/Analytics.css";
import { Card, Button, Drawer, Flex } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { useState, useContext } from "react";
import { BreakpointContext } from "../context";

function Analytics({
  title,
  contentComponent,
  filterComponent,
  isTrendingDataLoading,
  isParameterDataLoading,
}) {
  const isBroken = useContext(BreakpointContext);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const showDrawer = () => {
    setDrawerOpen(true);
  };

  const onDrawerClose = () => {
    setDrawerOpen(false);
  };

  return (
    <div className="analytics-container context-card">
      <Flex style={{ width: "100%", height: "100%" }} wrap="wrap">
        <Card
          className="analytics-filter-card"
          title={"Filter"}
          style={{
            display: isBroken ? "none" : "unset",
            // overflowY: "scroll",
          }}
          loading={isParameterDataLoading}
        >
          {filterComponent}
        </Card>
        <Card
          className="analytics-content-card"
          title={
            <div className="analytics-content-card-title">
              <span>{title}</span>
              {isBroken ? (
                <Button icon={<FilterOutlined />} onClick={showDrawer}></Button>
              ) : (
                <></>
              )}
            </div>
          }
          bodyStyle={{ padding: "3% 3% 5% 3%", height: "95%" }}
          loading={isTrendingDataLoading}
        >
          {contentComponent}
        </Card>
      </Flex>

      <Drawer
        title={"Filter"}
        placement="top"
        onClose={onDrawerClose}
        open={drawerOpen}
        height={"auto"}
        rootStyle={{ overflowY: "scroll" }} // !important: refer to docs
      >
        {filterComponent}
      </Drawer>
    </div>
  );
}

export default Analytics;
