import { Modal, Tabs } from "antd";
import * as _ from "@antv/util";
import { Col, Row, Flex, Tag, Image, Typography, Divider } from "antd";
import PM5000 from "../../assets/PM5000-power-meter.jpeg";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";

const { Text, Title } = Typography;

function GeneralTab({ themeToken, data }) {
  return (
    <div
      style={{
        maxHeight: "calc(70vh - 62px)",
        paddingTop: 12,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Row gutter={[24, 24]} style={{ overflow: "hidden" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={{ flex: "300px" }}>
          <Flex justify="center" align="center">
            <Image width={200} src={PM5000} />
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={6}>
          <Flex vertical gap={12}>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Model
              </Title>
              <Text>{data?.model}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Brand
              </Title>
              <Text>{data?.brand}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                ProtocolType
              </Title>
              <Text>{data?.protocoltype}</Text>
            </div>
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={6}>
          <Flex vertical gap={12}>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Last Update
              </Title>
              <Text>{data?.lastupdate}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Status
              </Title>
              <Flex>
                <Tag
                  color={
                    data?.status == "Online"
                      ? themeToken.colorSuccess
                      : themeToken.colorWarning
                  }
                >
                  <Text style={{ color: "white" }}>{data?.status}</Text>
                </Tag>
                {data?.status == "Online" ? (
                  <CheckCircleFilled
                    style={{ fontSize: "20px", color: themeToken.colorSuccess }}
                  />
                ) : (
                  <ExclamationCircleFilled
                    style={{ fontSize: "20px", color: themeToken.colorWarning }}
                  />
                )}
              </Flex>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Last Alarm
              </Title>
              <Text>{data?.lastalarm}</Text>
            </div>
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={{ flex: "auto" }}>
          <Flex vertical gap={12}>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Today Usage
              </Title>
              <Text>{data?.todayusage}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Past 7 Day Usage
              </Title>
              <Text>{data?.past7dayusage}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Month Up-to-date
              </Title>
              <Text>{data?.monthuptodate}</Text>
            </div>
          </Flex>
        </Col>
      </Row>
    </div>
  );
}

function PowerEnergyTab({ data }) {
  return (
    <div
      style={{
        maxHeight: "calc(70vh - 62px)",
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Row gutter={[48, 24]}>
        {data?.data.map((category, index) => (
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Flex vertical className="pr-5">
              <Title level={5} style={{ marginTop: 0 }}>
                {category.title}
              </Title>
              {category.values.map((value, idx) => (
                <Flex key={idx} justify="space-between" align="center">
                  <Text>{value.label}</Text>
                  <Flex gap="small">
                    <Text>{value.value}</Text>
                  </Flex>
                </Flex>
              ))}
            </Flex>
          </Col>
        ))}
      </Row>
      <Divider />
      <Row gutter={[48, 24]}>
        {data?.data2.map((category, index) => (
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Flex vertical className="pr-5">
              <Title level={5} style={{ marginTop: 0 }}>
                {category.title}
              </Title>
              {category.values.map((value, idx) => (
                <Flex key={idx} justify="space-between" align="center">
                  <Text>{value.label}</Text>
                  <Text>{value.value}</Text>
                </Flex>
              ))}
            </Flex>
          </Col>
        ))}
      </Row>
      <Divider />
      <Row gutter={[48, 24]}>
        {data?.data3.map((category, index) => (
          <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <Flex vertical className="pr-5">
              <Title level={5} style={{ marginTop: 0 }}>
                {category.title}
              </Title>
              {category.values.map((value, idx) => (
                <Flex key={idx} justify="space-between" align="center">
                  <Text>{value.label}</Text>
                  <Text>{value.value}</Text>
                </Flex>
              ))}
            </Flex>
          </Col>
        ))}
      </Row>
    </div>
  );
}

function DeviceInformation({
  deviceCardData,
  openModal,
  setOpenModal,
  themeToken,
  title,
}) {
  const items = [
    {
      key: "0",
      label: "General",
      children: (
        <GeneralTab themeToken={themeToken} data={deviceCardData.General} />
      ),
    },
    {
      key: "1",
      label: "Power & Energy",
      children: <PowerEnergyTab data={deviceCardData.PowerEnergy} />,
    },
  ];

  const handleOk = () => {
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      title={title}
      open={openModal}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
      style={{ maxWidth: "90vw", minWidth: "70vw", height: "80vh" }}
    >
      <Tabs style={{ height: "70vh" }} items={items} destroyInactiveTabPane />
    </Modal>
  );
}

export default DeviceInformation;
