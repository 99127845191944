import "../../css/component/Management/DataReferenceSources.css";
import {
  Button,
  Flex,
  Form,
  Select,
  Skeleton,
  Typography,
  message,
  Image,
} from "antd";
import DataReferenceSourcesCheckbox from "./DateReferenceSourcesCheckbox";
import { useEffect, useState } from "react";
import { fetchHelper } from "../../functions/fetch";
import { BACKEND_URL } from "../../configuration";
import image_consumption from "../../assets/sma_consumption_2.png";
import image_grid from "../../assets/sma_grid_2.png";
import image_pv from "../../assets/sma_pv_2.png";

const { Title, Text } = Typography;

const buttonData = [
  { label: "PV meter", key: "pvMeter" },
  { label: "Inverter", key: "inverter" },
  { label: "Utility Meter", key: "utilityMeter" },
  { label: "Weather Station", key: "weatherStation" },
];

function DataReferenceSources({ siteID, modalClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [dataReferenceSourcesLoading, setDataReferenceSourcesLoading] =
    useState(false);
  const [activeCheckbox, setActiveCheckbox] = useState("pvMeter");

  const [inverterDevice, setInverterDevice] = useState([]);
  const [pvDevice, setPvMeterDevice] = useState([]);
  const [utilityDevice, setUtilityDevice] = useState([]);
  const [weatherStationDevice, setWeatherStationDevice] = useState([]);

  const [weatherStationCalculate, setWeatherStationCalculate] = useState(null);
  const [pvSystemCalculate, setPvSystemCalculate] = useState(null);
  const [utilityCalculate, setUtilityCalculate] = useState(null);

  const [pvMeterChecked, setPvMeterChecked] = useState([]);
  const [inverterChecked, setInverterChecked] = useState([]);
  const [utilityChecked, setUtilityChecked] = useState([]);
  const [weatherStationChecked, setWeatherStationChecked] = useState([]);

  const [form] = Form.useForm();

  const renderCheckbox = () => {
    if (activeCheckbox === "inverter") {
      return (
        <DataReferenceSourcesCheckbox
          data={inverterDevice}
          checkedValues={inverterChecked}
          onChange={setInverterChecked}
          formName="Inverter Form"
        />
      );
    } else if (activeCheckbox === "pvMeter") {
      return (
        <DataReferenceSourcesCheckbox
          data={pvDevice}
          checkedValues={pvMeterChecked}
          onChange={setPvMeterChecked}
          formName="PV Meter Form"
        />
      );
    } else if (activeCheckbox === "utilityMeter") {
      return (
        <DataReferenceSourcesCheckbox
          data={utilityDevice}
          checkedValues={utilityChecked}
          onChange={setUtilityChecked}
          formName="Utility Form"
        />
      );
    } else if (activeCheckbox === "weatherStation") {
      return (
        <DataReferenceSourcesCheckbox
          data={weatherStationDevice}
          checkedValues={weatherStationChecked}
          onChange={setWeatherStationChecked}
          form="Weather Station Form"
        />
      );
    }
  };

  const handleButtonClick = (data) => {
    setActiveCheckbox(data);
  };

  const handleReset = () => {
    setPvMeterChecked([]);
    setInverterChecked([]);
    setUtilityChecked([]);
    setWeatherStationChecked([]);
  };

  const handleSubmit = () => {
    updateDeviceSelect();
  };

  /** API to get all device that seperate by device type */
  function getAllDeviceSeperateByType() {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      header: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setPvMeterDevice(data.data.PV);
        setInverterDevice(data.data.INVERTER);
        setUtilityDevice(data.data.TNB);
        setWeatherStationDevice(data.data.WEATHER);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/dataReferenceSources/${siteID}`,
      cb,
      setIsLoading,
      "Get All Device Seperate By Device Type",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to update the device select */
  function updateDeviceSelect() {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: {
          pvMeter: pvMeterChecked,
          inverter: inverterChecked,
          utility: utilityChecked,
          weatherStation: weatherStationChecked,
        },
        verify: {
          pvSystemCalculate: pvSystemCalculate,
          utilityCalculate: utilityCalculate,
          weatherStationCalculate: weatherStationCalculate,
        },
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        modalClose();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/dataReferenceSources/updateDeviceSelect/${siteID}`,
      cb,
      () => {},
      "Update Device Select",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get data reference sources */
  function getDataReferenceSources() {
    setDataReferenceSourcesLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteID,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setWeatherStationCalculate(data.weather);
        setPvSystemCalculate(data.pv);
        setUtilityCalculate(data.tnb);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/dataReferenceSources/getDataReferenceSources`,
      cb,
      setDataReferenceSourcesLoading,
      "Get Data Reference Sources",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteID !== undefined) {
      getAllDeviceSeperateByType();
      getDataReferenceSources();
    }
  }, []);

  const getSelectedDevices = (devices) =>
    devices
      .filter((device) => device.IsSelected)
      .map((device) => `${device.ID}:${device.DeviceTypeID}`);

  useEffect(() => {
    if (pvDevice.length > 0) {
      setPvMeterChecked(getSelectedDevices(pvDevice));
    }
  }, [pvDevice]);

  useEffect(() => {
    if (inverterDevice.length > 0) {
      setInverterChecked(getSelectedDevices(inverterDevice));
    }
  }, [inverterDevice]);

  useEffect(() => {
    if (utilityDevice.length > 0) {
      setUtilityChecked(getSelectedDevices(utilityDevice));
    }
  }, [utilityDevice]);

  useEffect(() => {
    if (weatherStationDevice.length > 0) {
      setWeatherStationChecked(getSelectedDevices(weatherStationDevice));
    }
  }, [weatherStationDevice]);

  return (
    <div className="data-reference-content">
      <Form form={form} onFinish={handleSubmit}></Form>
      <div className="left-content">
        <div className="data-reference-image-triangle">
          <div className="data-reference-image-top">
            <Image width={125} src={image_consumption} />
          </div>
          <div className="data-reference-image-left">
            <Image width={125} src={image_grid} />
          </div>
          <div className="data-reference-image-right">
            <Image width={125} src={image_pv} />
          </div>
        </div>
        <div>
          <Title level={4}>Select the Data Reference Sources of:</Title>
          <Flex gap="middle" vertical>
            <Flex
              gap="small"
              align="center"
              wrap="wrap"
              justify="space-between"
            >
              <Text className="label-style">Weather station: </Text>
              <Select
                loading={dataReferenceSourcesLoading}
                style={{ width: 150 }}
                onChange={(value) => setWeatherStationCalculate(value)}
                value={weatherStationCalculate}
                options={[
                  {
                    value: "weatherStationYes",
                    label: "Weather Station",
                  },
                  {
                    value: "weatherStationNo",
                    label: "N/A",
                  },
                ]}
              />
            </Flex>
            <Flex
              gap="small"
              align="center"
              wrap="wrap"
              justify="space-between"
            >
              <Text className="label-style">Solar PV System: </Text>
              <Select
                loading={dataReferenceSourcesLoading}
                style={{ width: 150 }}
                onChange={(value) => setPvSystemCalculate(value)}
                value={pvSystemCalculate}
                options={[
                  {
                    value: "pvMeterYes",
                    label: "Power Meter",
                  },
                  {
                    value: "inverterYes",
                    label: "Inverter",
                  },
                  {
                    value: "pvSystemNo",
                    label: "N/A",
                  },
                ]}
              />
            </Flex>
            <Flex
              gap="small"
              align="center"
              wrap="wrap"
              justify="space-between"
            >
              <Text className="label-style">Utility Incoming: </Text>
              <Select
                loading={dataReferenceSourcesLoading}
                style={{ width: 150 }}
                onChange={(value) => setUtilityCalculate(value)}
                value={utilityCalculate}
                options={[
                  {
                    value: "utilityYes",
                    label: "Utility",
                  },
                  {
                    value: "utilityNo",
                    label: "N/A",
                  },
                ]}
              />
            </Flex>
            <Flex
              gap="small"
              align="center"
              wrap="wrap"
              justify="space-between"
            >
              <Text className="label-style">Load Consumption: </Text>
              <Text>Estimation based on generation and incoming</Text>
            </Flex>
          </Flex>

          <div style={{ marginTop: "20px" }}>
            <Text style={{ fontSize: "12px" }}>
              The system accuracy may be subject to inaccuracies due to factors
              such as meter accuracy, environmental conditions, maintenance,
              data processing, and manufacturer specifications, and should be
              used for general guidance only.
            </Text>
          </div>
        </div>
      </div>
      <div className="right-content">
        <Title level={3}>Data Reference Sources:</Title>
        <Flex
          gap="small"
          align="center"
          wrap="wrap"
          style={{ marginBottom: "10px" }}
        >
          {buttonData.map(({ label, key }) => {
            return (
              <Button
                key={key}
                type={activeCheckbox === key ? "primary" : "default"}
                onClick={() => handleButtonClick(key)}
              >
                {label}
              </Button>
            );
          })}
        </Flex>
        <Form form={form} onFinish={handleSubmit}>
          <div
            style={{
              display: "flex",
              border: "1px solid lightgrey",
              borderRadius: "10px",
              width: " 100%",
              height: "40vh",
              justifyContent: "space-between",
            }}
          >
            {isLoading ? (
              <Skeleton active style={{ margin: "10px" }} />
            ) : (
              renderCheckbox()
            )}
          </div>
          <Form.Item>
            <div className="button-container">
              <Button htmlType="reset" onClick={() => handleReset()}>
                Cancel
              </Button>
              <Button
                htmlType="submit"
                style={{ backgroundColor: "#000038", color: "white" }}
              >
                Save
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}

export default DataReferenceSources;
