import { Form, Input } from "antd";

function PVSystDataEntryInputGroup({ label }) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "Year",
  ];

  const shortLabel = (label) => {
    let result = "";
    if (label === "Effective Energy at the Output of the Array (MWh)") {
      result = "MWh";
    } else if (label === "Global Horizontal Irradiation (kWh/m²)") {
      result = "kWh";
    } else if (label === "Performance Ratio") {
      result = "ratio";
    }
    return result;
  };

  return (
    <div>
      {months.map((month, index) => (
        <div style={{ marginLeft: "5px", marginRight: "5px" }} key={index}>
          <Form.Item
            name={`${shortLabel(label)}-${month}`}
            label={month}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
          >
            <Input aria-label={`${shortLabel(label)} ${month}`} />
          </Form.Item>
        </div>
      ))}
    </div>
  );
}

export default PVSystDataEntryInputGroup;
