import { Checkbox, Col, Empty, Form, Row } from "antd";
import { useEffect } from "react";

function DataReferenceSourcesCheckbox({
  data,
  checkedValues,
  onChange,
}) {
  const handleChange = (values) => {
    onChange(values);
  };

  return (
    <div style={{ height: "96%", overflowY: "auto", padding: "5px 10px" }}>
        <Checkbox.Group
          style={{ width: "100%" }}
          value={checkedValues}
          onChange={handleChange}
        >
          <Row>
            {data.length > 0 ? (
              data.map((item) => {
                return (
                  <Col key={item.ID} span={24} style={{ padding: "5px 0px" }}>
                    <Checkbox value={`${item.ID}:${item.DeviceTypeID}`}>
                      {item.DeviceNameLabel}
                    </Checkbox>
                  </Col>
                )
              })
            ) : (
              <Col span={24} style={{ padding: "5px 0px" }}>
                <Empty />
              </Col>
            )}
          </Row>
        </Checkbox.Group>
    </div>
  );
}

export default DataReferenceSourcesCheckbox;
