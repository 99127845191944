import React from "react";
import { Table, message, Button } from "antd";
import { useState, useEffect } from "react";
import { HorizontalDateMonthYearFilter } from "../common/dateFilter";
import "../../css/component/SystemReport/DailyReportContent.css";
import dayjs from "dayjs";
import { BACKEND_URL } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { useOutletContext } from "react-router-dom";
import {
  LoadingOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Column, ColumnGroup } = Table;

function DailyReportContent() {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [siteSelect, setSiteSelect, siteChange, setSiteChange] =
    useOutletContext();

  const [dateSelection, setDateSelection] = useState(dayjs());
  const [data, setData] = useState([]);

  const formatToTwoDecimalPlaces = (value) => {
    if (value === undefined || value === null || value === "-") {
      return value;
    }
    return value.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  function getDailyReportData() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
        date_graph: dateSelection.startOf("day").format("YYYY-MM-DD HH:mm:ss"),
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setData(data.data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/daily_report/getDailyReportData`,
      cb,
      setLoading,
      "Get Daily Report Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function downloadDailyReport() {
    setSaveLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: data,
      }),
    };
    fetch(BACKEND_URL + "/daily_report/downloadDailyReport", requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "DailyReport_" + moment().format("YYYYMMDDThhmm") + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setSaveLoading(false);
      });
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getDailyReportData();
    }
  }, [siteSelect, dateSelection]);

  return (
    <>
      <div className="daily-report-date-selection">
        <Button
          className="daily-report-download-button"
          onClick={() => {
            downloadDailyReport();
          }}
          disabled={loading}
        >
          {saveLoading ? <LoadingOutlined /> : <VerticalAlignBottomOutlined />}
        </Button>
        <HorizontalDateMonthYearFilter
          startDateSelection={dateSelection}
          setStartDateSelection={setDateSelection}
          onFilter={() => {}}
          pickerType={"month"}
          showTitle={false}
        />
      </div>
      <Table dataSource={data} scroll={{ x: 800 }} loading={loading}>
        <Column title="Date" dataIndex="date" key="date" />
        <Column
          title="Energy Generation"
          dataIndex="energyGeneration"
          key="energyGeneration"
          render={(value) => formatToTwoDecimalPlaces(value)}
        />
        <ColumnGroup title="Energy Utilization">
          <Column
            title="PV Self Consumption"
            dataIndex="pvSelfConsumption"
            key="pvSelfConsumption"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
          <Column
            title="Self Consumption Ratio"
            dataIndex="selfConsumptionRatio"
            key="selfConsumptionRatio"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
          <Column
            title="PV Export"
            dataIndex="pvExport"
            key="pvExport"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
          <Column
            title="PV Energy Export Ratio"
            dataIndex="pvEnergyExportRatio"
            key="pvEnergyExportRatio"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
          <Column
            title="Import From Grid"
            dataIndex="importFromGrid"
            key="importFromGrid"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
        </ColumnGroup>
      </Table>
    </>
  );
}

export default DailyReportContent;
