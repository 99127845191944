import "../../css/component/dashboard/PowerEnergyCard.css";
import { Column, Mix } from "@ant-design/plots";
import { Segmented, Card, message, Empty, Skeleton, Flex, Tooltip } from "antd";
import { useState, useEffect, useRef } from "react";
import { HorizontalDateMonthYearFilter } from "../common/dateFilter";
import dayjs from "dayjs";
import { BACKEND_URL } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { useOutletContext } from "react-router-dom";
import { Chart } from "@antv/g2";
import {
  DARK_BLUE,
  LIGHT_BLUE,
  LIGHT_LIGHT_BLUE,
  DARK_GREEN,
  LIGHT_GREEN,
  ORANGE,
} from "../../configuration";
import { InfoCircleOutlined } from "@ant-design/icons";

const systemTypeOptions = [
  { label: "PV System", value: "pv" },
  { label: "Overall", value: "overall" },
];

const powerEnergyDateTypeOptions = [
  { label: "Day", value: "date" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

const powerTrendTypeOptions = [
  { label: "Power", value: "power" },
  { label: "Power Demand", value: "powerDemand" },
];

function PowerEnergyCard({ className }) {
  const [
    siteSelect,
    setSiteSelect,
    siteChange,
    setSiteChange,
    isDarkMode,
    setIsDarkMode,
    subscribePpaBilling,
    subscribeCarbonManagement,
    subscribeAiPvDiagnosis,
    subscribeAiBess,
  ] = useOutletContext();

  const [systemTypeSelection, setSystemTypeSelection] = useState(
    systemTypeOptions[0].value
  );
  const [powerEnergyDateTypeSelection, setPowerEnergyDateType] = useState(
    powerEnergyDateTypeOptions[0].value
  );
  const [powerEnergyDateSelection, setPowerEnergyDateSelection] = useState(
    dayjs()
  );

  const [powerTrendTypeSelection, setPowerTrendTypeSelection] = useState(
    powerTrendTypeOptions[0].value
  );

  // Chart Data
  // energy chart data
  const [energyChartData, setEnergyChartData] = useState([]);
  const [loadEnergyData, setLoadEnergyData] = useState([]);
  const [energyConsumptionLoading, setEnergyConsumptionLoading] =
    useState(false);

  // power chart data
  const [powerChartData, setPowerChartData] = useState([]);
  const [irradianceData, setIrradianceData] = useState([]);
  const [powerTrendLoading, setPowerTrendLoading] = useState(false);

  // power demand chart data
  const [powerDemandChartData, setPowerDemandChartData] = useState([]);
  const [loadPowerDemandData, setLoadPowerDemandData] = useState([]);
  const [powerDemandLoading, setPowerDemandLoading] = useState(false);

  // pv power demand chart config
  const columnChartConfigPowerDemand = {
    autoFit: true,
    data: powerDemandChartData,
    isStack: true,
    xField: "date",
    yField: "value",
    seriesField: "type",
    yAxis: {
      title: {
        text: "Power Demand (kW)",
        position: "center",
      },
    },
    meta: {
      value: {
        formatter: (value) =>
          value
            ? value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : value === 0
            ? value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : value, // format value to 2 decimal places
      },
    },
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    colorField: "type", // Specify the field to determine colors
    color: ({ type }) => {
      const colorMapping = {
        "PV (Self-Consumption)": DARK_GREEN,
        "PV (Export)": LIGHT_GREEN,
      };
      return colorMapping[type];
    },
  };

  // power and irradiance chart config
  const comboConfig = {
    autoFit: true,
    appendPadding: 0,
    tooltip: {
      shared: true,
      position: "left",
    },
    legend: {
      position: "bottom",
      maxHeight: 3,
    },
    syncViewPadding: true,
    plots: [
      {
        type: "line",
        options: {
          data: powerChartData,
          xField: "date",
          yField: "value",
          seriesField: "type1",
          xAxis: false,
          xAxis: {
            range: [0.01, 0.99], // to add spaces around axis (setting range to [0,1] - graph will stick to axis)
          },
          yAxis: {
            line: null,
            title: {
              text: `Power (kW)`,
              position: "center",
            },
            grid: null,
            position: "left",
          },
          meta: {
            date: {
              sync: "date",
            },
            value: {
              formatter: (value) =>
                value
                  ? value.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : value === 0
                  ? value.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : value, // format value to 2 decimal places
            },
          },
          smooth: true,
          colorField: "type1", // Specify the field to determine colors
          color: ({ type1 }) => {
            const colorMapping = {
              "Power (TNB)": DARK_BLUE,
              "Power (PV)": DARK_GREEN,
              "Power (Load)": ORANGE,
              "Power (BESS)": LIGHT_BLUE,
            };
            return colorMapping[type1];
          },
        },
      },
      {
        type: "area",
        options: {
          data: irradianceData,
          xField: "date",
          yField: "value",
          seriesField: "type",
          xAxis: {
            range: [0.01, 0.99],
          },
          yAxis: {
            title: {
              text: `Irradiance W/m\u00B2`,
              position: "center",
            },
            position: "right",
          },
          meta: {
            date: {
              sync: true,
            },
            value: {
              formatter: (value) =>
                value
                  ? value.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : value === 0
                  ? value.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : value, // format value to 2 decimal places
            },
          },
          smooth: true,
          color: "grey",
          line: {
            color: "white",
          },
        },
      },
    ],
  };

  // pv energy chart config
  const columnChartConfig = {
    autoFit: true,
    data: energyChartData,
    isStack: true,
    xField: "date",
    yField: "value",
    seriesField: "type",
    yAxis: {
      title: {
        text: "Energy (kWh)",
        position: "center",
      },
    },
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    meta: {
      value: {
        formatter: (value) =>
          value
            ? value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : value === 0
            ? value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : value, // format value to 2 decimal places
      },
    },
    colorField: "type", // Specify the field to determine colors
    color: ({ type }) => {
      const colorMapping = {
        "PV (Export)": LIGHT_GREEN,
        "PV (Self-Consumption)": DARK_GREEN,
      };
      return colorMapping[type];
    },
  };

  /** API to get energy trending chart data */
  function getEnergyTrendingChartData() {
    setEnergyConsumptionLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
        date_selection: powerEnergyDateTypeSelection,
        date_graph: powerEnergyDateSelection
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        system_type: systemTypeSelection,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setEnergyChartData(data.energy_trending_data_df);
        setLoadEnergyData(data.load_energy_df);
      } else {
        message.error(data.message);
      }
      setEnergyConsumptionLoading(false);
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getEnergyTrendingChartData`,
      cb,
      setEnergyConsumptionLoading,
      "Get Energy Trending Chart Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get power demand trending chart data */
  function getPowerDemandTrendingChartData() {
    setPowerDemandLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
        date_selection: powerEnergyDateTypeSelection,
        date_graph: powerEnergyDateSelection
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        system_type: systemTypeSelection,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setPowerDemandChartData(data.power_demand_trending_data_df);
        setLoadPowerDemandData(data.load_power_demand_df);
      } else {
        message.error(data.message);
      }
      setPowerDemandLoading(false);
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getPowerDemandTrendingChartData`,
      cb,
      setPowerDemandLoading,
      "Get Power Demand Trending Chart Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get power chart data */
  function getPowerChartData() {
    setPowerTrendLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
        date_graph: powerEnergyDateSelection
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        system_type: systemTypeSelection,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setPowerChartData(JSON.parse(data.kWT_df));
        setIrradianceData([]);
      } else {
        message.error(data.message);
      }
      setPowerTrendLoading(false);
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getPowerChartData`,
      cb,
      setPowerTrendLoading,
      "Get Power Chart Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  // overall power demand chart config
  function OverallPowerDemandChart(props) {
    const { container, data_line, data_chart } = props;
    const chartRef = useRef(null);

    let chart_domain = ["PV (Self-Consumption)", "PV (Export)", "TNB", "Load"];
    let chart_range = [DARK_GREEN, LIGHT_GREEN, DARK_BLUE, ORANGE];
    if (subscribeAiBess === true) {
      chart_domain = [
        "PV (Self-Consumption)",
        "PV (Export)",
        "BESS Discharge",
        "BESS Charge",
        "TNB",
        "Load",
      ];
      chart_range = [
        DARK_GREEN,
        LIGHT_GREEN,
        LIGHT_BLUE,
        LIGHT_LIGHT_BLUE,
        DARK_BLUE,
        ORANGE,
      ];
    }

    useEffect(() => {
      document.getElementById(container).innerHTML = "";
    }, []);

    useEffect(() => {
      const chart = new Chart({
        container: container,
        autoFit: true,
      });

      chart
        .interval()
        .data(data_chart)
        .encode("x", "date")
        .encode("y", "value")
        .encode("color", "type")
        .scale("color", {
          domain: chart_domain,
          range: chart_range,
        })
        .transform({ type: "stackY" })
        .interaction("elementHighlight", { background: true })
        .axis("x", { title: "Time" })
        .axis("y", {
          title: "Energy (kWh)",
        })
        .interaction("tooltip", {
          render: (event, { title, items }) => {
            return `<div style="width:150px;">
              <h3 style="padding:0;margin:0">${title}</h3>
              <ul style="padding: 0; margin: 0; list-style: none;">
                ${items
                  .map((d) => {
                    // Format the value with commas and 2 decimal places
                    const formattedValue = d.value
                      ? Number(d.value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : d.value === 0
                      ? Number(d.value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : d.value;
                    return `
                      <li style="display: flex; justify-content: space-between; align-items: center; margin: 4px 0;">
                        <span style="display: inline-flex; align-items: center;">
                          <span style="width: 8px; height: 8px; background-color: ${d.color}; border-radius: 50%; margin-right: 8px;"></span>
                          <span>${d.name}</span>
                        </span>
                        <span>${formattedValue}</span>
                      </li>`;
                  })
                  .join("")}
              </ul>
            </div>`;
          },
        });

      chart
        .line()
        .data(data_line)
        .encode("x", "date")
        .encode("y", "Load")
        .encode("color", "type")
        .encode("shape", "smooth")
        .style("stroke", ORANGE)
        .style("lineWidth", 3);

      document.getElementById(container).innerHTML = "";
      chartRef.current = chart;

      chart.render();

      window.onresize = () => {
        chart.render();
      };
    }, [container]);

    return <div style={{ width: "100%", height: "100%" }} id={container} />;
  }

  // overall energy demand chart config
  function OverallEnergyChart(props) {
    const { container, data_line, data_chart } = props;
    const chartRef = useRef(null);

    let chart_domain = ["PV (Self-Consumption)", "PV (Export)", "TNB", "Load"];
    let chart_range = [DARK_GREEN, LIGHT_GREEN, DARK_BLUE, ORANGE];
    if (subscribeAiBess === true) {
      chart_domain = [
        "PV (Self-Consumption)",
        "PV (Export)",
        "BESS Discharge",
        "BESS Charge",
        "TNB",
        "Load",
      ];
      chart_range = [
        DARK_GREEN,
        LIGHT_GREEN,
        LIGHT_BLUE,
        LIGHT_LIGHT_BLUE,
        DARK_BLUE,
        ORANGE,
      ];
    }

    useEffect(() => {
      document.getElementById(container).innerHTML = "";
    }, []);

    useEffect(() => {
      const chart = new Chart({
        container: container,
        autoFit: true,
      });

      chart
        .interval()
        .data(data_chart)
        .encode("x", "date")
        .encode("y", "value")
        .encode("color", "type")
        .scale("color", {
          domain: chart_domain,
          range: chart_range,
        })
        .transform({ type: "stackY" })
        .interaction("elementHighlight", { background: true })
        .axis("x", { title: "Time" })
        .axis("y", { title: "Energy (kWh)" })
        .interaction("tooltip", {
          render: (event, { title, items }) => {
            return `<div style="width:150px;">
              <h3 style="padding:0;margin:0">${title}</h3>
              <ul style="padding: 0; margin: 0; list-style: none;">
                ${items
                  .map((d) => {
                    // Format the value with commas and 2 decimal places
                    const formattedValue = d.value
                      ? Number(d.value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : d.value === 0
                      ? Number(d.value).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : d.value;
                    return `
                      <li style="display: flex; justify-content: space-between; align-items: center; margin: 4px 0;">
                        <span style="display: inline-flex; align-items: center;">
                          <span style="width: 8px; height: 8px; background-color: ${d.color}; border-radius: 50%; margin-right: 8px;"></span>
                          <span>${d.name}</span>
                        </span>
                        <span>${formattedValue}</span>
                      </li>`;
                  })
                  .join("")}
              </ul>
            </div>`;
          },
        });

      chart
        .line()
        .data(data_line)
        .encode("x", "date")
        .encode("y", "Load")
        .encode("color", "type")
        .encode("shape", "smooth")
        .style("stroke", ORANGE)
        .style("lineWidth", 3);

      chartRef.current = chart;

      chart.render();

      window.onresize = () => {
        chart.render();
      };
    }, [container]);

    return <div style={{ width: "100%", height: "100%" }} id={container}></div>;
  }

  // Use Effect
  useEffect(() => {
    if (siteSelect !== undefined) {
      setEnergyChartData([]);
      setLoadEnergyData([]);
      setPowerDemandChartData([]);
      setLoadPowerDemandData([]);
      getEnergyTrendingChartData();
      getPowerDemandTrendingChartData();

      const timer = setInterval(() => {
        setEnergyChartData([]);
        setLoadEnergyData([]);
        setPowerDemandChartData([]);
        setLoadPowerDemandData([]);
        getEnergyTrendingChartData();
        getPowerDemandTrendingChartData();
      }, 30 * 60 * 1000); // refresh every 30 mins

      return () => {
        clearInterval(timer);
      };
    }
  }, [
    siteSelect,
    powerEnergyDateTypeSelection,
    powerEnergyDateSelection,
    systemTypeSelection,
  ]);

  useEffect(() => {
    if (siteSelect !== undefined) {
      setPowerChartData([]);
      setIrradianceData([]);
      getPowerChartData();
    }
  }, [siteSelect, powerEnergyDateSelection, systemTypeSelection]);

  return (
    <>
      <Card
        className={className}
        title={
          <div className="power-energy-card-title">
            <Segmented
              options={systemTypeOptions}
              onChange={(value) => {
                setSystemTypeSelection(value);
              }}
              value={systemTypeSelection}
            />
            <div style={{ display: "flex", gap: "5px" }}>
              <HorizontalDateMonthYearFilter
                startDateSelection={powerEnergyDateSelection}
                setStartDateSelection={setPowerEnergyDateSelection}
                onFilter={() => {}}
                pickerType={powerEnergyDateTypeSelection}
                showTitle={false}
              />
              <Segmented
                options={powerEnergyDateTypeOptions}
                onChange={(value) => {
                  setPowerEnergyDateType(value);
                }}
                value={powerEnergyDateTypeSelection}
              />
            </div>
          </div>
        }
      >
        <div className="power-energy-content">
          <Flex justify="space-between">
            <span className="energy-title">Energy Consumption</span>
            <span>
              <Tooltip
                title={
                  "Disclaimer: The values of these charts are updated every 30 minutes."
                }
              >
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          </Flex>
          <Card
            className="graph-container"
            bordered={false}
            style={{ height: "40%" }}
          >
            {systemTypeSelection == "pv" ? (
              <div style={{ height: "100%" }}>
                <Column
                  style={{
                    display: energyConsumptionLoading
                      ? "none"
                      : energyChartData.length == 0
                      ? "none"
                      : "block",
                    height: "100%",
                  }}
                  {...columnChartConfig}
                />
                <Skeleton
                  size="small"
                  active
                  style={{
                    display: energyConsumptionLoading ? "table" : "none",
                  }}
                  paragraph={{ rows: 5 }}
                />
                <Empty
                  style={{
                    padding: "40px 0px",
                    display:
                      energyChartData.length == 0
                        ? energyConsumptionLoading
                          ? "none"
                          : "block"
                        : "none",
                  }}
                />
              </div>
            ) : (
              <div style={{ height: "100%" }}>
                <Empty
                  style={{
                    padding: "40px 0px",
                    display:
                      loadEnergyData.length == 0
                        ? energyConsumptionLoading
                          ? "none"
                          : "block"
                        : "none",
                  }}
                />
                <div
                  style={{
                    display: energyConsumptionLoading
                      ? "none"
                      : energyChartData.length == 0
                      ? "none"
                      : "block",
                    height: "100%",
                  }}
                >
                  <OverallEnergyChart
                    data_chart={energyChartData}
                    data_line={loadEnergyData}
                    container={"overall-energy-chart"}
                  />
                </div>
                <Skeleton
                  size="small"
                  active
                  style={{
                    display: energyConsumptionLoading ? "table" : "none",
                  }}
                  paragraph={{ rows: 5 }}
                />
              </div>
            )}
          </Card>

          <div className="power-title">
            <span>Power Trends</span>
            {powerEnergyDateTypeSelection === "date" ? (
              <Segmented
                block
                options={powerTrendTypeOptions}
                onChange={(value) => {
                  setPowerTrendTypeSelection(value);
                }}
                value={powerTrendTypeSelection}
                style={{ marginBottom: 10 }}
              />
            ) : (
              <></>
            )}
          </div>
          <Card
            className="graph-container"
            bordered={false}
            style={{ height: "40%" }}
          >
            {powerEnergyDateTypeSelection == "date" &&
            powerTrendTypeSelection === "power" ? (
              <div style={{ height: "100%" }}>
                <Mix
                  style={{
                    display: powerTrendLoading
                      ? "none"
                      : powerChartData.length == 0
                      ? "none"
                      : "block",
                    height: "100%",
                  }}
                  {...comboConfig}
                />
                <Skeleton
                  size="small"
                  active
                  style={{ display: powerTrendLoading ? "table" : "none" }}
                  paragraph={{ rows: 5 }}
                />
                <Empty
                  style={{
                    padding: "40px 0px",
                    display:
                      powerChartData.length == 0
                        ? powerTrendLoading
                          ? "none"
                          : "block"
                        : "none",
                  }}
                />
              </div>
            ) : (
              <div style={{ height: "100%" }}>
                {systemTypeSelection === "pv" ? (
                  <div style={{ height: "100%" }}>
                    <Empty
                      style={{
                        padding: "40px 0px",
                        display:
                          powerDemandChartData.length == 0
                            ? powerDemandLoading
                              ? "none"
                              : "block"
                            : "none",
                      }}
                    />
                    <Skeleton
                      size="small"
                      active
                      style={{
                        display: powerDemandLoading ? "table" : "none",
                      }}
                      paragraph={{ rows: 5 }}
                    />
                    <Column
                      style={{
                        display: powerDemandLoading
                          ? "none"
                          : powerDemandChartData.length == 0
                          ? "none"
                          : "block",
                        height: "100%",
                      }}
                      {...columnChartConfigPowerDemand}
                    />
                  </div>
                ) : (
                  <div style={{ height: "100%" }}>
                    <div
                      style={{
                        display: powerDemandLoading
                          ? "none"
                          : powerDemandChartData.length == 0
                          ? "none"
                          : "block",
                        height: "100%",
                      }}
                    >
                      <OverallPowerDemandChart
                        data_chart={powerDemandChartData}
                        data_line={loadPowerDemandData}
                        container={"overall-power-demand-chart"}
                      />
                    </div>
                    <Skeleton
                      size="small"
                      active
                      style={{
                        display: powerDemandLoading ? "table" : "none",
                      }}
                      paragraph={{ rows: 5 }}
                    />
                    <Empty
                      style={{
                        padding: "40px 0px",
                        display:
                          powerDemandChartData.length == 0
                            ? powerDemandLoading
                              ? "none"
                              : "block"
                            : "none",
                      }}
                    />
                  </div>
                )}
              </div>
            )}
          </Card>
        </div>
      </Card>
    </>
  );
}

export default PowerEnergyCard;
