// custom node example: https://g6.antv.antgroup.com/en/examples/item/customNode#jsxNode
// line animation: https://g6.antv.antgroup.com/en/examples/scatter/edge#edge
// custom node documentation: https://antv-g6.gitee.io/en/docs/manual/middle/elements/nodes/jsx-node
import G6 from "@antv/g6";
import { useEffect, useState } from "react";
import { Card, message, Skeleton } from "antd";
import { BACKEND_URL, MQTT_REFRESH_RATE } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import image_consumption from "../../assets/sma_consumption_2.png";
import image_grid from "../../assets/sma_grid_2.png";
import image_pv from "../../assets/sma_pv_2.png";
import image_bess from "../../assets/BESS.png";
import "../../css/component/dashboard/SystemInformationCard.css";
import { useOutletContext } from "react-router-dom";

/**
 * TO-DO
 *
 * add line color
 * set speed based on percentage
 */

const FILL = "#01063B";
const FONT_SIZE = 13;
const BAR_SIZE = 75;
let TEXT_COLOR = "#ffffff";

G6.registerNode(
  "label",
  (cfg) => `
    <group>
      <text style={{ fill: ${TEXT_COLOR}, fontSize: ${FONT_SIZE}, fontWeight: 600}}>{{title1}}</text>
      <text style={{ fill: ${TEXT_COLOR}, fontSize: ${FONT_SIZE}, fontWeight: 600, marginTop: -3}}>{{title2}}</text>
      <rect style={{ width: ${BAR_SIZE}, height: 10, fill: '#ffffff', stroke: ${FILL} }} keyshape='true'>
      <rect style={{ width: {{percent}}, height: 10, fill: ${FILL}, stroke: ${FILL} }} keyshape='true' >
      <text style={{ fill: ${TEXT_COLOR}, fontSize: ${FONT_SIZE}, fontWeight: 600, marginTop: 10}}>{{value}} {{unit}}</text>
    </group>
    `
);

const lineDash = [8, 2, 2, 4];
G6.registerEdge(
  "polyline-dash",
  {
    afterDraw(cfg, group) {
      const shape = group.get("children")[0];
      let index = cfg.speed;
      shape.animate(
        () => {
          index += cfg.speed;
          if (index > 1000) {
            index = 0;
          }

          const res = {
            lineDash,
            lineDashOffset: -index,
          };
          return res;
        },
        {
          repeat: true,
          duration: 1000,
        }
      );
    },
  },
  "line" // extend the built-in edge
);

/**
 * prereq: state object has variables:
 *  - pv_value
 *  - load_value
 *  - grid_value
 *  - bess_value (if subscribe AI-BESS)
 *  - pv_max
 *  - load_max
 *  - grid_max
 *  - bess_max (if subscribe AI-BESS)
 */
function SystemInformationCard({ className, containerID }) {
  const [loading, setLoading] = useState(true);
  const [
    siteSelect,
    setSiteSelect,
    siteChange,
    setSiteChange,
    isDarkMode,
    setIsDarkMode,
    subscribePpaBilling,
    subscribeCarbonManagement,
    subscribeAiPvDiagnosis,
    subscribeAiBess,
  ] = useOutletContext();
  // const userTheme = localStorage.getItem("Theme");
  const userTheme = "light";

  const [systemInformationData, setSystemInformationData] = useState({
    pv_value: 0,
    load_value: 0,
    grid_value: 0,
    bess_value: 0,
    pv_max: 0,
    load_max: 0,
    grid_max: 0,
    bess_max: 0,
  });

  function getSystemInfo() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setSystemInformationData(data.system_info_data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getSystemInfo`,
      cb,
      setLoading,
      "Get System Info",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getSystemInfo();

      const timer = setInterval(() => {
        getSystemInfo();
      }, MQTT_REFRESH_RATE * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelect]);

  function renderGraph(graphData) {
    const container = document.getElementById(containerID);
    cleanCharting(containerID);
    const width = container.scrollWidth || 500;
    const height = container.scrollHeight || 500;
    const temp = new G6.Graph({
      container: containerID,
      width,
      height,
      linkCenter: true,
      fitCenter: false,
      defaultNode: {
        type: "image",
        size: [70, 70],
      },
      defaultEdge: {
        type: "polyline-dash",
        style: { lineWidth: 4 },
      },
      // modes: { default: [{type:'zoom-canvas', sensitivity:0.4},'drag-canvas']},
    });
    temp.data(graphData);
    temp.render();

    setGraph(temp);
  }

  function changeTextColor(userTheme) {
    if (userTheme == "light") {
      TEXT_COLOR = "#000000";
    } else {
      TEXT_COLOR = "#ffffff";
    }
  }

  function cleanCharting(container) {
    document.getElementById(container).innerHTML = "";
  }

  const getPercentage = (val, max) => {
    if (val === "-") return 0.1;
    if (max == 0 && val == 0) return 0.1; // if return 0 it causes weird behaviour (showing half of the bar)

    max = max > 0 ? max : val;
    return Math.round(BAR_SIZE * (val / max));
  };

  const [isFirstRender, setIsFirstRender] = useState(true);

  const [graph, setGraph] = useState(null);

  useEffect(() => {
    // UPDATE NODE
    const graphData = {
      nodes: subscribeAiBess
        ? [
            {
              id: "pvimage",
              img: image_pv,
              x: 105,
              y: 40,
            },
            {
              x: 0,
              y: 20,
              title1: "PV",
              title2:
                systemInformationData.pv_value === "-"
                  ? "DPM Offline"
                  : systemInformationData.pv_value > 0
                  ? "Producing"
                  : "Consuming",
              percent: getPercentage(
                systemInformationData.pv_value,
                systemInformationData.pv_max
              ),
              value:
                systemInformationData.pv_value !== "-"
                  ? `${systemInformationData.pv_value.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : "-",
              unit: systemInformationData.pv_value !== "-" ? "kW" : "",
              id: "pvnode",
              type: "label",
            },
            {
              id: "bessimage",
              img: image_bess,
              x: 180,
              y: 55,
            },
            {
              x: 220,
              y: 35,
              title1: "BESS",
              title2:
                systemInformationData.bess_value == 0
                  ? "Idle"
                  : systemInformationData.bess_value === "-"
                  ? "Offline"
                  : systemInformationData.bess_value < 0
                  ? "Charging"
                  : "Discharging",
              image: image_bess,
              percent: getPercentage(
                systemInformationData.bess_value,
                systemInformationData.bess_max
              ),
              value:
                systemInformationData.bess_value !== "-"
                  ? `${systemInformationData.bess_value.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : "-",
              unit: systemInformationData.bess_value !== "-" ? "kW" : "",
              id: "bessnode",

              type: "label",
            },
            {
              id: "loadimage",
              img: image_consumption,
              x: 90,
              y: 110,
            },
            {
              x: 10,
              y: 120,
              title1: "Load",
              title2:
                systemInformationData.load_value === "-"
                  ? "DPM Offline"
                  : "Consuming",
              image: image_consumption,
              percent: getPercentage(
                systemInformationData.load_value,
                systemInformationData.load_max
              ),
              value:
                systemInformationData.load_value !== "-"
                  ? `${systemInformationData.load_value.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : "-",
              unit: systemInformationData.load_value !== "-" ? "kW" : "",
              id: "loadnode",
              type: "label",
            },
            {
              id: "gridimage",
              img: image_grid,
              x: 180,
              y: 130,
            },
            {
              x: 220,
              y: 110,
              title1: "Grid",
              title2:
                systemInformationData.grid_value == "-"
                  ? "DPM Offline"
                  : systemInformationData.grid_value > 0
                  ? "Importing"
                  : "Exporting",
              labelRight: false,
              image: image_grid,
              percent: getPercentage(
                systemInformationData.grid_value,
                systemInformationData.grid_max
              ),
              value:
                systemInformationData.grid_value !== "-"
                  ? `${systemInformationData.grid_value.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : "-",
              unit: systemInformationData.grid_value !== "-" ? "kW" : "",
              id: "gridnode",
              type: "label",
            },
          ]
        : [
            {
              id: "pvimage",
              img: image_pv,
              x: 160,
              y: 30,
            },
            {
              x: 200,
              y: 10,
              title1: "PV",
              title2:
                systemInformationData.pv_value === "-"
                  ? "DPM Offline"
                  : systemInformationData.pv_value > 0
                  ? "Producing"
                  : "Consuming",
              percent: getPercentage(
                systemInformationData.pv_value,
                systemInformationData.pv_max
              ),
              value:
                systemInformationData.pv_value !== "-"
                  ? `${systemInformationData.pv_value.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : "-",
              unit: systemInformationData.pv_value !== "-" ? "kW" : "",
              id: "pvnode",
              type: "label",
            },
            {
              id: "loadimage",
              img: image_consumption,
              x: 90,
              y: 80,
            },
            {
              x: 10,
              y: 100,
              title1: "Load",
              title2:
                systemInformationData.load_value === "-"
                  ? "DPM Offline"
                  : "Consuming",
              image: image_consumption,
              percent: getPercentage(
                systemInformationData.load_value,
                systemInformationData.load_max
              ),
              value:
                systemInformationData.load_value !== "-"
                  ? `${systemInformationData.load_value.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : "-",
              unit: systemInformationData.load_value !== "-" ? "kW" : "",
              id: "loadnode",
              type: "label",
            },
            {
              id: "gridimage",
              img: image_grid,
              x: 170,
              y: 130,
            },
            {
              x: 210,
              y: 110,
              title1: "Grid",
              title2:
                systemInformationData.grid_value == "-"
                  ? "DPM Offline"
                  : systemInformationData.grid_value > 0
                  ? "Importing"
                  : "Exporting",
              labelRight: false,
              image: image_grid,
              percent: getPercentage(
                systemInformationData.grid_value,
                systemInformationData.grid_max
              ),
              value:
                systemInformationData.grid_value !== "-"
                  ? `${systemInformationData.grid_value.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : "-",
              unit: systemInformationData.grid_value !== "-" ? "kW" : "",
              id: "gridnode",
              type: "label",
            },
          ],
      edges: [],
    };

    // BUILD EDGES
    // Load consuming = PV + Grid (+ BESS)
    if (systemInformationData.pv_value > 0) {
      if (systemInformationData.load_value > 0) {
        // consuming from PV
        graphData.edges.push({
          source: "pvimage",
          target: "loadimage",
          speed: 1,
          color: "#696969",
        });
      }
      if (systemInformationData.grid_value < 0) {
        // exporting PV to Grid
        graphData.edges.push({
          source: "pvimage",
          target: "gridimage",
          speed: 0.4,
          color: "#696969",
        });
      }
    }

    if (systemInformationData.bess_value > 0) {
      if (systemInformationData.load_value > 0) {
        // BESS discharging
        graphData.edges.push({
          source: "bessimage",
          target: "loadimage",
          speed: 1,
          color: "#696969",
        });
      }
    } else if (systemInformationData.bess_value < 0) {
      // BESS charging from PV
      if (
        systemInformationData.pv_value + systemInformationData.grid_value >
        systemInformationData.load_value
      ) {
        graphData.edges.push({
          source: "pvimage",
          target: "bessimage",
          speed: 1,
          color: "#696969",
        });
      }
      // BESS charging from TNB
      else {
        graphData.edges.push({
          source: "gridimage",
          target: "bessimage",
          speed: 1,
          color: "#696969",
        });
      }
    }

    if (systemInformationData.grid_value > 0) {
      // consuming from Grid
      graphData.edges.push({
        source: "gridimage",
        target: "loadimage",
        speed: 0.4,
        color: "#666666",
      });
    }

    // RENDER GRAPH
    if (isFirstRender) {
      setIsFirstRender(false);
      changeTextColor(userTheme);
      if (subscribeAiBess !== null && !loading) {
        renderGraph(graphData);
      }
    } else {
      changeTextColor(userTheme);
      if (subscribeAiBess !== null && !loading) {
        if (graph) {
          graph.changeData(graphData);
        }
        renderGraph(graphData);
      }
    }
  }, [systemInformationData, userTheme, subscribeAiBess, loading]);

  return (
    <>
      <Card className={className} title={"System Information"}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {loading || subscribeAiBess === null ? (
            <Skeleton
              active={true}
              style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
            />
          ) : (
            <div
              id={containerID}
              style={{
                width: "300px",
                height: "180px",
                display: loading ? "none" : "block",
              }}
            />
          )}
        </div>
      </Card>
    </>
  );
}

export default SystemInformationCard;
