import { TinyArea } from "@ant-design/plots";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";
import "../../css/component/common/StatisticGraphContent.css";

function StatisticGraphContent({
  data,
  value,
  change,
  title,
  fillColor,
  fillColorGradient,
}) {
  const config = {
    width: "auto",
    autoFit: true,
    padding: 0,
    data,
    smooth: true,
    areaStyle: {
      fill: `l(270) 0:${fillColor} 0.5:${fillColor} 1:${fillColorGradient}`,
    },
    line: {
      color: fillColor,
    },
  };

  return (
    <div className="statistic-graph-content">
      <div className="statistic-graph-detail">
        <span className="statistic-graph-value">{value}</span>
        {/* <span className='statistic-graph-change'>{change}%<CaretUpOutlined/></span> */}
        <span className="statistic-graph-title">{title}</span>
      </div>

      <div className="statistic-graph-chart">
        <TinyArea {...config} />
      </div>
    </div>
  );
}

export default StatisticGraphContent;
