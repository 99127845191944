import { Card } from "antd";
import { useState } from "react";
import InverterReportContent from "../component/SystemReport/InverterReportContent";
import DailyReportContent from "../component/SystemReport/DailyReportContent";
import OverallReportContent from "../component/SystemReport/OverallReportContent";
import AnnualReportContent from "../component/SystemReport/AnnualReportContent";
import { useOutletContext } from "react-router-dom";

function SystemReport() {
  const [
    siteSelect,
    setSiteSelect,
    siteChange,
    setSiteChange,
    isDarkMode,
    setIsDarkMode,
    subscribePpaBilling,
    subscribeCarbonManagement,
    subscribeAiPvDiagnosis,
    subscribeAiBess,
    hasInverter,
  ] = useOutletContext();

  const tabList = hasInverter
    ? [
        {
          key: "dailyReportTab",
          tab: "Daily Report",
        },
        {
          key: "annualReportTab",
          tab: "Annual Report",
        },
        {
          key: "overallReportTab",
          tab: "Overall Report",
        },
        {
          key: "interverReportTab",
          tab: "Inverter Report",
        },
      ]
    : [
        {
          key: "dailyReportTab",
          tab: "Daily Report",
        },
        {
          key: "annualReportTab",
          tab: "Annual Report",
        },
        {
          key: "overallReportTab",
          tab: "Overall Report",
        },
      ];

  const [activeTabKey, setActiveTabKey] = useState(tabList[0].key);
  const onTabChange = (key) => {
    setActiveTabKey(key);
  };

  const contentList = {
    dailyReportTab: <DailyReportContent />,
    overallReportTab: <OverallReportContent />,
    annualReportTab: <AnnualReportContent />,
    interverReportTab: <InverterReportContent />,
  };

  return (
    <Card
      className="context-card"
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      style={{
        overflowY: "hidden",
        marginLeft: "10px",
        marginRight: "10px",
      }}
      bodyStyle={{
        height: "90%",
        overflowX: "hidden",
      }}
    >
      {contentList[activeTabKey]}
    </Card>
  );
}

export default SystemReport;
