import "../css/pages/Alarm.css";
import { useState, useContext, useEffect } from "react";
import { Card, Button, Divider, Drawer, Skeleton } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import { Pie } from "@ant-design/plots";
import dayjs from "dayjs";
import { BreakpointContext } from "../context";
import {
  VerticalDateFilter,
  HorizontalDateFilter,
} from "../component/common/dateFilter";
import { CustomTable } from "../component/common/table";
import { LabelValueDisplayRow } from "../component/common/wordDisplay";
import { filterDataByDateRange } from "../functions/dateFilter";
import WorkInProgress from "./WorkInProgress";
import useGetAlarmSummary from "../functions/alarm_page/getAlarmSummary";
import useGetAlarmList from "../functions/alarm_page/getAlarmList";
import { useOutletContext } from "react-router-dom";

function AlarmEvent({ data }) {
  return (
    <div>
      <span className="alarm-display-title">Selected Event:</span>
      <div>
        <LabelValueDisplayRow label="Device Name:" value={data?.deviceName || "-"} />
        <LabelValueDisplayRow label="Occurrence Time:" value={data?.occurenceTime || "-"} />
        <LabelValueDisplayRow label="Alarm Code:" value={data?.alarmCode || "-"} />
        <LabelValueDisplayRow label="Description:" value={data?.description || "-"} />
        <LabelValueDisplayRow label="Model:" value={data?.model || "-"} />
        <LabelValueDisplayRow label="Status:" value={data?.status || "-"} />
      </div>
    </div>
  );
}

const AlarmSummaryDonut = ({ value }) => {
  const data = [
    { type: "Device Offline", value: value.active_alarm },
    { type: "Device Online", value: value.total_events - value.active_alarm },
  ];

  const config = {
    data,
    angleField: "value",
    colorField: "type",
    radius: 0.8,
    innerRadius: 0.7,
    label: {
      type: "outer",
      content: "{name}: \n{percentage}",
    },
    legend: false,
    interactions: [
      { type: "element-selected" },
      { type: "element-active" },
    ],
    color: ["#5BD197", "#01063B"],
  };

  return <Pie {...config} />;
};

function Alarm() {
  const isBroken = useContext(BreakpointContext);
  const [done, setDone] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [startDateSelection, setStartDateSelection] = useState(dayjs().startOf("month"));
  const [endDateSelection, setEndDateSelection] = useState(dayjs());
  const [selectedAlarm, setSelectedAlarm] = useState(null);
  const [siteSelect] = useOutletContext();

  const { getAlarmSummary, alarmSummary, alarmSummaryLoading} = useGetAlarmSummary();
  const { getAlarmList, alarmList, alarmListLoading } = useGetAlarmList();

  const showDrawer = () => setDrawerOpen(true);
  const onDrawerClose = () => setDrawerOpen(false);

  const onViewClick = (row) => setSelectedAlarm(row);

  const onFilterClick = () => {
    if (siteSelect) {
      getAlarmSummary(startDateSelection, endDateSelection, siteSelect);
      getAlarmList(startDateSelection, endDateSelection, siteSelect);
    }
    onDrawerClose();
  };

  const alarmTableColumns = [
    { title: "ID", dataIndex: "number", key: "number" },
    { title: "Device Name", dataIndex: "deviceName", key: "deviceName" },
    { title: "Occurrence Time", dataIndex: "occurenceTime", key: "occurenceTime" },
    { title: "End Date Time", dataIndex: "endDateTime", key: "endDateTime" },
    { title: "Alarm Code", dataIndex: "alarmCode", key: "alarmCode" },
    { title: "Description", dataIndex: "description", key: "description" },
    {
      title: "",
      dataIndex: "view",
      key: "view",
      render: (_, record) => (
        <Button type="link" onClick={() => onViewClick(record)}>
          View
        </Button>
      ),
    },
  ];

  useEffect(() => {
    if (siteSelect) {
      getAlarmSummary(startDateSelection, endDateSelection, siteSelect);
      getAlarmList(startDateSelection, endDateSelection, siteSelect);
    }
  }, [siteSelect]);

  return (
    <>
      {done ? (
        <Card
          className="context-card alarm-card"
          title={
            <div className="alarm-card-title">
              <span>Alarm Record</span>
              {isBroken ? (
                <Button icon={<FilterOutlined />} onClick={showDrawer} />
              ) : (
                <HorizontalDateFilter
                  startDateSelection={startDateSelection}
                  setStartDateSelection={setStartDateSelection}
                  endDateSelection={endDateSelection}
                  setEndDateSelection={setEndDateSelection}
                  onFilterClick={onFilterClick}
                />
              )}
            </div>
          }
          style={{ overflowY: "hidden" }}
          bodyStyle={{ height: "90%", overflowX: "hidden" }}
        >
          <div className="alarm-col">
            {alarmSummaryLoading 
            ? 
            <div className="alarm-row">
              <Skeleton />
            </div>
            :
            <div className="alarm-row">
              <AlarmSummaryDonut value={alarmSummary} />
              <div>
                <span className="alarm-display-title">Summary:</span>
                <div>
                  <LabelValueDisplayRow label="Total Events" value={alarmSummary?.total_events || '-'} />
                  <LabelValueDisplayRow label="Device Offline:" value={alarmSummary?.device_offline || '-'} />
                  <LabelValueDisplayRow label="Active Alarm:" value={alarmSummary?.active_alarm || '-'} />
                  <LabelValueDisplayRow label="Most Recent:" value={alarmSummary?.most_recent || '-'} />
                  <LabelValueDisplayRow label="Date:" value={alarmSummary?.date || '-'} />
                  <LabelValueDisplayRow label="Status:" value={alarmSummary?.status || '-'} />
                </div>
              </div>
              <Divider type="vertical" style={{ height: "200px" }} />
              <AlarmEvent data={selectedAlarm} />
            </div>
            }
             {alarmSummaryLoading 
            ? 
            <div className="alarm-content">
              <Skeleton />
            </div>
            :
            <div className="alarm-content">
              <CustomTable
                dataSource={alarmList}
                columns={alarmTableColumns}
                row_per_page={4}
                tableHeight={250}
                tableContainerStyleObject={{ flex: "0 0 60%", minWidth: "700px" }}
              />
            </div>
            }
          </div>

          <Drawer
            title="Filter"
            placement="top"
            onClose={onDrawerClose}
            open={drawerOpen}
            height="auto"
            rootStyle={{ overflowY: "scroll" }}
          >
            <VerticalDateFilter
              startDateSelection={startDateSelection}
              setStartDateSelection={setStartDateSelection}
              endDateSelection={endDateSelection}
              setEndDateSelection={setEndDateSelection}
              onFilterClick={onFilterClick}
            />
          </Drawer>
        </Card>
      ) : (
        <WorkInProgress />
      )}
    </>
  );
}

export default Alarm;
