import "../css/pages/Billing.css";
import { useEffect, useState } from "react";
import {
  Card,
  Button,
  DatePicker,
  Form,
  Input,
  Flex,
  Modal,
  message,
  Spin,
  Tooltip,
  InputNumber,
} from "antd";
import {
  BankFilled,
  DownloadOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  MailOutlined,
} from "@ant-design/icons";
import { FigureDisplay } from "../component/common/figureDisplay";
import { CustomTable } from "../component/common/table";
import { formatMoneyNumber } from "../functions/numberFormatting";
import WorkInProgress from "./WorkInProgress";
import { ModalForm } from "../component/common/modalForm";
import { fetchHelper } from "../functions/fetch";
import { BACKEND_URL } from "../configuration";
import dayjs from "dayjs";

const BILLING_TYPE = {
  generated: (
    <span className="flexbox-container-row align-center">
      <div
        className="circle"
        style={{ backgroundColor: "#1DAE8A", margin: "0 5px" }}
      />
      Generated
    </span>
  ),
  invoiceSent: (
    <span className="flexbox-container-row align-center">
      <div
        className="circle"
        style={{ backgroundColor: "#ADD8E6", margin: "0 5px" }}
      />
      Invoice Sent
    </span>
  ),
  pending: (
    <span className="flexbox-container-row align-center">
      <div
        className="circle"
        style={{ backgroundColor: "#FFB701", margin: "0 5px" }}
      />
      Pending Configuration
    </span>
  ),
  "-": (
    <span className="flexbox-container-row align-center">
      <div
        className="circle"
        style={{ backgroundColor: "#FF0000", margin: "0 5px" }}
      />
      None
    </span>
  ),
};

const SUBSCRIPTION_TYPE = {
  true: (
    <span className="flexbox-container-row align-center">
      <div
        className="circle"
        style={{ backgroundColor: "#1DAE8A", margin: "0 5px" }}
      />
      True
    </span>
  ),
  false: (
    <span className="flexbox-container-row align-center">
      <div
        className="circle"
        style={{ backgroundColor: "#bb2124", margin: "0 5px" }}
      />
      False
    </span>
  ),
};

function BillingTypeSummary({ generated, invoiceSent, pending }) {
  return (
    <>
      <div className="flexbox-container-col" style={{ minWidth: "180px" }}>
        {BILLING_TYPE.generated}
        {BILLING_TYPE.invoiceSent}
        {BILLING_TYPE.pending}
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <span>{`:`}</span>
        <span>{`:`}</span>
        <span>{`:`}</span>
      </div>
      <div
        className="flexbox-container-col"
        style={{ flexShrink: "0", flexGrow: "0" }}
      >
        <span>{`${generated} site(s)`}</span>
        <span>{`${invoiceSent} site(s)`}</span>
        <span>{`${pending} site(s)`}</span>
      </div>
    </>
  );
}

function GeneratedFormBody({ onGenerateDatePicker, loading, initialValue }) {
  return (
    <>
      <Form.Item
        label="Billing Cycle"
        name="BillingCycle"
        rules={[{ required: true, message: "Billiy Cycle cannot be empty" }]}
      >
        <DatePicker.RangePicker
          onChange={onGenerateDatePicker}
          disabled={loading}
        />
      </Form.Item>
      <Form.Item
        label="Total Generation"
        name="TotalGeneration"
        rules={[
          {
            required: true,
            message: "Total Generation cannot be empty",
          },
        ]}
      >
        <InputNumber suffix="kWh" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Adjustment"
        name="Adjustment"
        rules={[{ required: true, message: "Adjustment cannot be empty" }]}
      >
        <InputNumber suffix="kWh" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Tariff"
        name="Tariff"
        rules={[{ required: true, message: "Tariff cannot be empty" }]}
      >
        <InputNumber suffix="RM/kWh" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item label="Meter">
        <Flex gap="middle" justify="space-between">
          {loading || initialValue == null ? (
            <>
              <Spin
                style={{ width: "100%" }}
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 20, color: "#000038" }}
                    spin
                  />
                }
              />
              <Tooltip title="Please chooose Billing Cycle to get meter">
                <InfoCircleOutlined style={{ color: "#000000" }} />
              </Tooltip>
            </>
          ) : (
            <>
              <Form.Item name="currentMeter">
                <Input
                  disabled
                  suffix={
                    <>
                      <span>kWh</span>
                      <Tooltip title="This is Current Meter">
                        <InfoCircleOutlined style={{ color: "#000000" }} />
                      </Tooltip>
                    </>
                  }
                />
              </Form.Item>
              <Form.Item name="latestMeter">
                <Input
                  disabled
                  suffix={
                    <>
                      <span>kWh</span>
                      <Tooltip title="This is Last Meter">
                        <InfoCircleOutlined style={{ color: "#000000" }} />
                      </Tooltip>
                    </>
                  }
                />
              </Form.Item>
            </>
          )}
        </Flex>
      </Form.Item>
    </>
  );
}
function GeneratedModalForm({
  modalOpen,
  closeModal,
  onSubmit,
  onGenerateDatePicker,
  initialValue,
  loading,
}) {
  const [generateForm] = Form.useForm();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      modalTitle={"Generate Form"}
      formObject={generateForm}
      formName={"generateForm"}
      formBody={
        <GeneratedFormBody
          onGenerateDatePicker={onGenerateDatePicker}
          loading={loading}
          initialValue={initialValue}
        />
      }
      formIntitialValues={initialValue}
      formSubmit={onSubmit}
    />
  );
}

function SettingFormBody() {
  return (
    <>
      <Form.Item
        label="Billing Cycle Start Date"
        name="StartDate"
        rules={[
          {
            required: true,
            message: "Billing Cycle Start Date cannot be empty.",
          },
        ]}
      >
        <DatePicker style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Tariff"
        name="Tariff"
        rules={[{ required: true, message: "Tariff cannot be empty." }]}
      >
        <InputNumber suffix="RM/kWh" style={{ width: "100%" }} />
      </Form.Item>
      <Form.Item
        label="Detail"
        name="Detail"
        rules={[{ required: true, message: "Detail cannot be empty." }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email of Receipent"
        name="Email"
        rules={[
          {
            required: true,
            type: "email",
            message: "The input is not valid E-mail",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
}
function SettingModalForm({
  modalOpen,
  initialValue,
  closeModal,
  onSubmit,
  loading,
}) {
  const [settingForm] = Form.useForm();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      modalTitle={"Setting Form"}
      formObject={settingForm}
      formName={"settingForm"}
      formBody={
        loading ? (
          <Spin
            style={{ width: "100%" }}
            indicator={
              <LoadingOutlined
                style={{ fontSize: 80, color: "#000038" }}
                spin
              />
            }
          />
        ) : (
          <SettingFormBody />
        )
      }
      formIntitialValues={initialValue}
      formSubmit={onSubmit}
    />
  );
}

function InvoiceTable({
  tableData,
  onInvoiceExport,
  onInvoiceEmail,
  emailDisable,
  exportLoading,
  invoiceId,
}) {
  const invoiceTabColumns = [
    { title: "No.", dataIndex: "number", key: "number" },
    {
      title: "Month",
      dataIndex: "Month",
      key: "Month",
    },
    {
      title: "Period",
      dataIndex: "Period",
      key: "Period",
      render: (_, record, index) => (
        <span>
          {record.PeriodStartDate} to {record.PeriodEndDate}
        </span>
      ),
      // width: 200,
    },
    {
      title: "Total Generation",
      dataIndex: "TotalGeneration",
      key: "TotalGeneration",
    },
    {
      title: "Tariff",
      dataIndex: "Tariff",
      key: "Tariff",
    },
    {
      title: "Adjustment",
      dataIndex: "Adjustment",
      key: "Adjustment",
    },
    {
      title: "Total Amount",
      dataIndex: "TotalAmount",
      key: "TotalAmount",
      render: (text) => `RM ${text}`,
    },
    {
      title: "Generate Date Time",
      dataIndex: "GenerateDateTime",
      key: "GenerateDateTime",
      // width: 200,
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}
        >
          <Button
            type="default"
            icon={
              invoiceId == record.ID && exportLoading ? (
                <LoadingOutlined />
              ) : (
                <DownloadOutlined />
              )
            }
            onClick={() => onInvoiceExport(record.ID)}
            disabled={invoiceId == record.ID && exportLoading}
          />
          <Tooltip
            title={
              record.Status === "invoiceSent" && "Already sent the invoice"
            }
          >
            <Button
              type="default"
              icon={<MailOutlined />}
              onClick={() => {
                onInvoiceEmail(record.ID);
              }}
              disabled={emailDisable || record.Status === "invoiceSent"}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <CustomTable
      dataSource={tableData && tableData.length > 0 ? tableData : []}
      columns={invoiceTabColumns}
      row_per_page={8}
      tableHeight={500}
      tableContainerStyleObject={{ height: "80%", minWidth: "700px" }}
    />
  );
}
function InvoiceModalTable({
  modalOpen,
  closeModal,
  tableData,
  onInvoiceExport,
  onInvoiceEmail,
  emailDisable,
  exportLoading,
  invoiceId,
  loading,
}) {
  return (
    <Modal
      title="Invoice Table"
      open={modalOpen}
      onCancel={() => closeModal()}
      footer={null}
      style={{ maxWidth: "90vw", minWidth: "70vw", height: "80vh" }}
    >
      <Card loading={loading}>
        <InvoiceTable
          tableData={tableData}
          onInvoiceExport={onInvoiceExport}
          onInvoiceEmail={onInvoiceEmail}
          emailDisable={emailDisable}
          exportLoading={exportLoading}
          invoiceId={invoiceId}
        />
      </Card>
    </Modal>
  );
}

function BillingTable({
  data,
  setGenerateModal,
  setSettingModal,
  setInvoiceModal,
  generateDisable,
}) {
  const userTableColumns = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
      width: 80,
    },
    {
      title: "Site",
      dataIndex: "SiteName",
      key: "SiteName",
    },
    {
      title: "Billing Period",
      dataIndex: "billingPeriod",
      key: "billingPeriod",
      render: (_, record, index) => (
        <span>
          {record.BillingPeriodStart} to {record.BillingPeriodEnd}
        </span>
      ),
      width: 230,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, record, index) => (
        <span>RM {formatMoneyNumber(record.Amount)}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, record, index) => BILLING_TYPE[record.Status],
    },
    {
      title: "Subscription",
      dataIndex: "Subscription",
      key: "Subscription",
      render: (_, record, index) => SUBSCRIPTION_TYPE[record.Subscription],
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}
        >
          <Tooltip
            title={
              generateDisable && "Please go to configuration page setup first"
            }
          >
            <Button
              type="default"
              onClick={() => {
                setGenerateModal(record.ID);
              }}
              disabled={generateDisable}
            >
              Generate
            </Button>
          </Tooltip>
          <Button
            type="default"
            onClick={() => {
              setSettingModal(record.ID);
            }}
          >
            Settings
          </Button>
          <Button
            type="default"
            onClick={() => {
              setInvoiceModal(record.ID);
            }}
          >
            List of Invoice
          </Button>
        </div>
      ),
      width: 220,
    },
  ];

  return (
    <CustomTable
      dataSource={data}
      columns={userTableColumns}
      row_per_page={7} // to fit the screen when at 100%
      tableHeight={430} // to fit the screen when at 100%
      tableContainerStyleObject={{ height: "80%", minWidth: "700px" }}
      tableWidth={1355}
    />
  );
}

function Billing() {
  const [isLoading, setIsLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [inputLoading, setInputLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  const [billingSummary, setBillingSummary] = useState({
    enrolledSites: 0,
    totalSites: 0,
    generatedSites: 0,
    invoiceSentSites: 0,
    pendingSites: 0,
    billIssuedThisMonth: 0,
    billIssuedThisYear: 0,
  });

  const [billingTypeSummary, setBillingTypeSummary] = useState([
    { type: "Generated", value: 0 },
    { type: "Incomplete", value: 0 },
    { type: "Pending", value: 0 },
  ]);

  const [billingId, setBillingId] = useState(null);
  const [billingTable, setBillingTable] = useState([]);
  const [billingSetting, setBillingSetting] = useState([]);
  const [billingMeter, setBillingMeter] = useState();
  const [invoiceTable, setInvoiceTable] = useState([]);
  const [emailDisable, setEmailDisable] = useState(false);
  const [generateDisable, setGenerateDisable] = useState(false);
  const [invoiceId, setInvoiceId] = useState(null);

  const [generatedModal, setGenerateModal] = useState(false);
  const [settingModal, setSettingModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);

  const onGenerateModalOpen = (id) => {
    setBillingId(id);
    setGenerateModal(true);
  };

  const onSettingModalOpen = (id) => {
    getBilling(id);
    setSettingModal(true);
  };

  const onInvoiceModalOpen = (id) => {
    getAllInvoice(id);
    setInvoiceModal(true);
  };

  const onGenerateDatePicker = (value) => {
    getBillingMeter(value);
  };

  const closeGenerateModal = () => {
    setGenerateModal(false);
    setBillingMeter(null);
  };
  const closeSettingModal = () => setSettingModal(false);
  const closeInvoiceModal = () => setInvoiceModal(false);

  const addGenerateSubmit = (value) => {
    let check = value.currentMeter == undefined && value.lastMeter == undefined;

    if (check) {
      message.error(
        "You cannot generate invoice if don't have any PV or Inverter device"
      );
    } else {
      addInvoice(value);
    }
  };

  const addSettingSubmit = (value) => {
    updateBilling(value);
  };

  const onInvoiceExport = (value) => {
    setInvoiceId(value);
    saveInvoice(value);
  };

  const onInvoiceEmail = (value) => {
    sendEmail(value);
  };

  function getBillingSummary() {
    setIsLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setBillingSummary(data.data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/billing/getBillingSummary",
      cb,
      setIsLoading,
      "Get Billing Summary",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getAllBilling() {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setBillingTable(data.data);
        setGenerateDisable(data.configuration);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/billing/getAllBilling",
      cb,
      setIsLoading,
      "Get All Billing",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getBilling(id) {
    setModalLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        if (data.data.StartDate != null) {
          data.data.StartDate = dayjs(
            data.data.StartDate,
            "ddd, DD MMM YYYY HH:mm:ss [GMT]"
          );
        }
        setBillingSetting(data.data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/billing/getBilling/${id}`,
      cb,
      setModalLoading,
      "Get Billing Setting",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function updateBilling(data) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: data,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllBilling();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/billing/updateBilling/${data.ID}`,
      cb,
      () => {},
      "Update Billing",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getBillingMeter(data) {
    setInputLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: data,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setBillingMeter(data.data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/billing/getBillingMeter/${billingId}`,
      cb,
      setInputLoading,
      "Get Billing Meter",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function addInvoice(data) {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: data,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllBilling();
        getBillingSummary();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/invoice/addInvoice/${billingId}`,
      cb,
      setIsLoading,
      "Add Invoice",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getAllInvoice(id) {
    setModalLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setInvoiceTable(data.data);
        setEmailDisable(data.email);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/invoice/getAllInvoice/${id}`,
      cb,
      setModalLoading,
      "Get All Invoice",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function saveInvoice(id) {
    setExportLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        downloadInvoiceData(data.download_link);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/invoice/saveInvoice/${id}`,
      cb,
      setExportLoading,
      "Save Invoice",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function downloadInvoiceData(filename) {
    const downloadLink = document.createElement("a");
    downloadLink.href = BACKEND_URL + `/invoice/downloadFile/${filename}`;
    downloadLink.setAttribute("download", filename);
    document.body.append(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  function sendEmail(id) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllBilling();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/emailMessage/sendEmail/${id}`,
      cb,
      () => {},
      "Send Email Message",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    setBillingTypeSummary([
      {
        type: "Generated",
        value: billingSummary.generatedSites,
      },
      {
        type: "Incomplete",
        value: billingSummary.invoiceSentSites,
      },
      {
        type: "Pending",
        value: billingSummary.pendingSites,
      },
    ]);
    getAllBilling();
    getBillingSummary();
  }, []);

  return (
    <>
      <Card
        title={"PPA Billing"}
        className="context-card"
        style={{ overflowY: "hidden" }}
        bodyStyle={{
          height: "95%",
          // overflowY: "scroll"
        }}
        loading={isLoading}
      >
        <div
          className="flexbox-container-row"
          style={{
            flexWrap: "wrap",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <FigureDisplay
            figure={billingSummary.totalSites}
            unit={""}
            caption={"Sites"}
          />
          <div
            className="flexbox-container-row summary-box-margin center-alignment-both-axis"
            style={{ margin: "5px 30px" }}
          >
            <BillingTypeSummary
              generated={billingSummary.generatedSites}
              invoiceSent={billingSummary.invoiceSentSites}
              pending={billingSummary.pendingSites}
            />
          </div>
          <FigureDisplay
            figure={formatMoneyNumber(billingSummary.billIssuedThisMonth)}
            unit={"RM"}
            caption={"Bills issued this Month"}
          />
          <FigureDisplay
            figure={formatMoneyNumber(billingSummary.billIssuedThisYear)}
            unit={"RM"}
            caption={"Bills issued this Year"}
          />
          <FigureDisplay
            figure={billingSummary.enrolledSites}
            unit={`out of ${billingSummary.totalSites}`}
            caption={"Enrolled Sites (by Subscription)"}
          />
        </div>
        <BillingTable
          data={billingTable}
          setGenerateModal={onGenerateModalOpen}
          setSettingModal={onSettingModalOpen}
          setInvoiceModal={onInvoiceModalOpen}
          generateDisable={generateDisable}
        />
      </Card>

      <GeneratedModalForm
        modalOpen={generatedModal}
        closeModal={closeGenerateModal}
        onSubmit={addGenerateSubmit}
        onGenerateDatePicker={onGenerateDatePicker}
        initialValue={billingMeter}
        loading={inputLoading}
      />
      <SettingModalForm
        modalOpen={settingModal}
        initialValue={billingSetting}
        closeModal={closeSettingModal}
        onSubmit={addSettingSubmit}
        loading={modalLoading}
      />
      <InvoiceModalTable
        modalOpen={invoiceModal}
        closeModal={closeInvoiceModal}
        tableData={invoiceTable}
        onInvoiceExport={onInvoiceExport}
        onInvoiceEmail={onInvoiceEmail}
        emailDisable={emailDisable}
        exportLoading={exportLoading}
        invoiceId={invoiceId}
        loading={modalLoading}
      />
    </>
  );
}

export default Billing;
