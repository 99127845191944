import "../../css/component/Management/PvSystDataEntry.css";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  Switch,
  Upload,
  Tabs,
  message,
  Flex,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import PVSystDataEntryInputGroup from "./PVSystDataEntryInputGroup";
import { fetchHelper } from "../../functions/fetch";
import { BACKEND_URL } from "../../configuration";

const tabList = [
  {
    key: "kWhTab",
    label: "Global Horizontal Irradiation (kWh/m²)",
    children: (
      <PVSystDataEntryInputGroup label="Global Horizontal Irradiation (kWh/m²)" />
    ),
    forceRender: true,
  },
  {
    key: "MWhTab",
    label: "Effective Energy at the Output of the Array (MWh)",
    children: (
      <PVSystDataEntryInputGroup label="Effective Energy at the Output of the Array (MWh)" />
    ),
    forceRender: true,
  },
  {
    key: "ratioTab",
    label: "Performance Ratio",
    children: <PVSystDataEntryInputGroup label="Performance Ratio" />,
    forceRender: true,
  },
];

function PVSystDataEntry({ siteID, modalClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("kWhTab");
  const [fileName, setFileName] = useState(null);
  const [oldFileName, setOldFileName] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [pvSystID, setPvSystID] = useState(null);
  const [pvSystDataID, setPvSystDataID] = useState(null);
  const [pvsystForm] = Form.useForm();

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const handleSubmitForm = (value) => {
    setIsLoading(true);
    const categories = {
      pvsystId: pvSystID,
      pvsystDataId: pvSystDataID,
      fileName: fileName,
      oldFileName: oldFileName,
      data: {},
      pvSyst: {
        kWh: {},
        MWh: {},
        ratio: {},
      },
    };

    Object.entries(value).forEach(([key, value]) => {
      if (key.startsWith("kWh")) {
        categories.pvSyst.kWh[key.split("-")[1]] = value;
      } else if (key.startsWith("MWh")) {
        categories.pvSyst.MWh[key.split("-")[1]] = value;
      } else if (key.startsWith("ratio")) {
        categories.pvSyst.ratio[key.split("-")[1]] = value;
      } else {
        categories.data[key] = value;
      }
    });

    insertPVSystData(categories);
  };

  const handleSubmitFail = (value) => {
    message.error("Fail to Submit Form");
  };

  const beforeUpload = (file) => {
    const isPdf = file.type === "application/pdf";
    if (!isPdf) {
      message.error("You can only upload pdf files!");
    }

    return isPdf || Upload.LIST_IGNORE;
  };

  /** API to insert PVSyst data */
  function insertPVSystData(data) {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: data,
        siteId: siteID,
      }),
    };
    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        modalClose();
      } else {
        message.error(data.message);
        deletePVSystFile();
      }
    };
    fetchHelper(
      BACKEND_URL + "/pvSystDataEntry/insertPVSystData",
      cb,
      setIsLoading,
      "Insert PVSyst Data Successfully",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function uploadPVSystFile(file) {
    const formData = new FormData();
    formData.append("file", file.file);
    formData.append("filename", fileName);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setFileName(data.filename);

        const updatedFileList = [...fileList];
        if (updatedFileList.length > 0) {
          updatedFileList[0].status = "done";
          setFileList(updatedFileList);
        }
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/pvSystDataEntry/uploadPVSystFile",
      cb,
      () => {},
      "Upload PVSyst File Successfully",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function deletePVSystFile() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: fileName,
      }),
    };
    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setFileName();
      } else {
        message.error(data.message);
      }
    };
    fetchHelper(
      BACKEND_URL + "/pvSystDataEntry/deletePVSystFile",
      cb,
      () => {},
      "Remove PVSyst File Successfully",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getPVSystData() {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        if (Object.keys(data.pvsystData).length > 0) {
          pvsystForm.setFieldsValue(data.monthData.kWh);
          pvsystForm.setFieldsValue(data.monthData.MWh);
          pvsystForm.setFieldsValue(data.monthData.ratio);
          pvsystForm.setFieldsValue(data.pvsystData);
          setFileName(data.pdfName);
          setOldFileName(data.pdfName);
          setPvSystID(data.pvsystData.ID);
          setPvSystDataID(data.monthDataID);
        } else {
          pvsystForm.setFieldValue({ PVSystResult: false });
        }
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/pvSystDataEntry/getPVSystData/${siteID}`,
      cb,
      () => {},
      "Get PVSyst Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getPdfFile() {
    const downloadLink = document.createElement("a");
    downloadLink.href =
      BACKEND_URL + `/pvSystDataEntry/getPdfFile/${oldFileName}`;
    downloadLink.setAttribute("download", oldFileName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  useEffect(() => {
    getPVSystData();
  }, []);

  return (
    <div className="pvsyst-data-entry">
      <Form
        layout="horizontal"
        labelAlign="left"
        requiredMark={false}
        style={{ width: "100%" }}
        onFinish={handleSubmitForm}
        onFinishFailed={handleSubmitFail}
        form={pvsystForm}
      >
        <div className="details-form-content">
          <div className="form-section">
            <Form.Item
              label="Include PVSyst Results in Reporting"
              name="PVSystResult"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              valuePropName="checked"
            >
              <Switch defaultChecked={false} />
            </Form.Item>
            <Form.Item
              label="Project Location"
              name="ProjectLocation"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: "Project Location cannot be empty.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Total PV Power, Nominal(STC),in MWp"
              name="PVPower"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                { required: true, message: "Total PV Power cannot be empty." },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Total Inverter Power, in MWac"
              name="InverterPower"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: "Total Inverter Power cannot be empty.",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="form-section">
            <Form.Item
              label="Produced Energy, in MWh/year"
              name="ProducedEnergy"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                { required: true, message: "Produced Energy cannot be empty." },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Specific Production, in kWh/kWp/year"
              name="SpecificProduction"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: "Specific Production cannot be empty.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Performance Ratio"
              name="PerformanceRatio"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              rules={[
                {
                  required: true,
                  message: "Performance Ratio cannot be empty.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="PVSyst Report"
              name="PVSystReport"
              labelCol={{ span: 14 }}
              wrapperCol={{ span: 12 }}
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
            >
              <Flex gap="middle">
                <Upload
                  beforeUpload={beforeUpload}
                  customRequest={uploadPVSystFile}
                  onRemove={deletePVSystFile}
                  accept=".pdf"
                  maxCount={1}
                  fileList={fileList}
                  onChange={({ fileList }) => setFileList(fileList)}
                >
                  <Button
                    icon={<UploadOutlined />}
                    disabled={fileList.length > 0}
                  >
                    Upload
                  </Button>
                </Upload>
                {oldFileName != null ? (
                  <Tooltip title="Click me to download the file upload before ">
                    <InfoCircleOutlined
                      onClick={() => {
                        getPdfFile();
                      }}
                    />
                  </Tooltip>
                ) : (
                  <></>
                )}
              </Flex>
            </Form.Item>
          </div>
        </div>

        <Tabs
          defaultActiveKey={activeTab}
          onChange={onTabChange}
          items={tabList}
        />

        <div className="button-container">
          {isLoading ? (
            <Button
              style={{ backgroundColor: "#000038", color: "white" }}
              loading
            >
              Saving
            </Button>
          ) : (
            <>
              <Button htmlType="reset">Cancel</Button>
              <Button
                htmlType="submit"
                style={{ backgroundColor: "#000038", color: "white" }}
              >
                Save
              </Button>
            </>
          )}
        </div>
      </Form>
    </div>
  );
}

export default PVSystDataEntry;
