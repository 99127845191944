import Analytics from "../component/Analytics";
import { useState, useEffect } from "react";
import { BACKEND_URL } from "../configuration";
import { fetchHelper } from "../functions/fetch";
import { Empty, Tree, message } from "antd";
import { Line } from "@ant-design/plots";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { VerticalDateFilter } from "../component/common/dateFilter";
import { filterDataByDateRange } from "../functions/dateFilter";
import { useOutletContext } from "react-router-dom";

// Extend Day.js with the plugin
dayjs.extend(isSameOrBefore);

/*
 * data keys: [DEVICE-NAME]-[PARAMETER-NAME]
 *
 */

function DeviceParameterFilter({ treeData, checkedKeys, setCheckedKeys }) {
  const [expandedKeys, setExpandedKeys] = useState([]);
  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
  };

  const onCheck = (checkedKeysValue) => {
    const filteredData = checkedKeysValue.filter((item) => item.includes(":"));
    setCheckedKeys(filteredData);
  };

  return (
    <Tree
      checkable
      onExpand={onExpand}
      expandedKeys={expandedKeys}
      autoExpandParent={true}
      onCheck={onCheck}
      checkedKeys={checkedKeys}
      treeData={treeData}
      selectable={false}
      height={"300px"}
      style={{ overflowY: "scroll" }}
    />
  );
}
function ParametersTrending() {
  const [siteSelect, setSiteSelect, siteChange, setSiteChange] =
    useOutletContext();
  const [isParameterDataLoading, setIsParameterDataLoading] = useState(false);
  const [isTrendingDataLoading, setIsTrendingDataLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [startDateSelection, setStartDateSelection] = useState(
    dayjs().subtract(6, "day")
  );
  const [endDateSelection, setEndDateSelection] = useState(dayjs());

  const [trendingDownloadData, setTrendingDownloadData] = useState([]);
  const [filteredParametersTrendingData, setFilteredParametersTrendingData] =
    useState([]);

  const [treeData, setTreeData] = useState([]);

  const onFilterClick = () => {
    if (deviceParameterFiltercheckedKeys.length > 5) {
      message.error("Please choose below 6 parameter");
    } else {
      const startDate = dayjs(startDateSelection)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
      const endDate = dayjs(endDateSelection)
        .set("hour", 0)
        .set("minute", 0)
        .set("second", 0)
        .set("millisecond", 0);
      const oneMonthAfter = startDate.add(1, "month");

      const isWithinOneMonth = endDate.isSameOrBefore(oneMonthAfter);

      if (isWithinOneMonth) {
        getTrendingData();
      } else {
        message.error("You cannot choose more than 1 month.");
      }
    }
  };

  const filterData = (data) => {
    return setFilteredParametersTrendingData(
      filterDataByDateRange(data, startDateSelection, endDateSelection)
    );
  };

  const onExportClick = () => {
    if (trendingDownloadData.length < 1) {
      message.error("Please get the data first");
    } else {
      saveTrendingData();
    }
  };

  const lineChartConfig = {
    autoFit: true,
    data: filteredParametersTrendingData,
    xField: "date",
    yField: "value",
    seriesField: "deviceParameter",
    smooth: true,
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    point: true,
    xAxis: {
      title: {
        text: "Date",
        style: {
          fontSize: 16,
        },
      },
    },
    yAxis: {
      title: {
        text: "Value",
        style: {
          fontSize: 16,
        },
      },
    },
    meta: {
      value: {
        formatter: (value) =>
          value
            ? value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : value === 0
            ? value.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : value, // format value to 2 decimal places
      },
    },
  };

  const [
    deviceParameterFiltercheckedKeys,
    setDeviceParameterFilterCheckedKeys,
  ] = useState([]);

  const transformData = (data) => {
    return Object.entries(data.data).map(([deviceName, parameters]) => ({
      title: deviceName,
      key: deviceName,
      children: parameters.map((param) => ({
        title: param,
        key: `${deviceName}:${param}`,
      })),
    }));
  };

  /** API to get the device parameter */
  function getDeviceParameter() {
    setIsParameterDataLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setTreeData(transformData(data));
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/parameter/getDeviceParameter/${siteSelect}`,
      cb,
      setIsParameterDataLoading,
      "Get Device Parameter",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get the trending data */
  function getTrendingData() {
    setIsTrendingDataLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: deviceParameterFiltercheckedKeys,
        siteId: siteSelect,
        startDate: startDateSelection
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        endDate: endDateSelection.endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      }),
    };

    const cb = (data) => {
      if (data.status) {
        filterData(data.data);
        setTrendingDownloadData(data.fullData);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/parameter/getTrendingData`,
      cb,
      setIsTrendingDataLoading,
      "Get Trending Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to save trending data as excel */
  function saveTrendingData() {
    setSaveLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: trendingDownloadData,
        siteId: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        downloadTrendingData(data.download_link);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/parameter/saveTrendingData`,
      cb,
      setSaveLoading,
      "Save Trending Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function downloadTrendingData(filename) {
    const downloadLink = document.createElement("a");
    downloadLink.href = BACKEND_URL + `/parameter/download/${filename}`;
    downloadLink.setAttribute("download", filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  useEffect(() => {
    setTrendingDownloadData([]);
    setFilteredParametersTrendingData([]);
    setDeviceParameterFilterCheckedKeys([]);
    if (siteSelect) {
      getDeviceParameter();
    }
  }, [siteSelect]);

  return (
    <Analytics
      title={"Trending"}
      contentComponent={
        filteredParametersTrendingData.length > 0 ? (
          <Line {...lineChartConfig} />
        ) : (
          <Empty />
        )
      }
      isTrendingDataLoading={isTrendingDataLoading}
      isParameterDataLoading={isParameterDataLoading}
      filterComponent={
        <div style={{ display: "flex", flexDirection: "column", gap: "40px" }}>
          <VerticalDateFilter
            startDateSelection={startDateSelection}
            setStartDateSelection={setStartDateSelection}
            endDateSelection={endDateSelection}
            setEndDateSelection={setEndDateSelection}
            saveLoading={saveLoading}
            onFilterClick={onFilterClick}
            onExportClick={onExportClick}
          />
          <span style={{ fontWeight: "bold" }}>Parameter List</span>
          <DeviceParameterFilter
            treeData={treeData}
            checkedKeys={deviceParameterFiltercheckedKeys}
            setCheckedKeys={setDeviceParameterFilterCheckedKeys}
          />
        </div>
      }
    />
  );
}

export default ParametersTrending;
