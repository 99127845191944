import { Card, Segmented, Carousel, Skeleton } from "antd";
import { useEffect, useState, useRef } from "react";
import "../css/pages/Dashboard.css";
import moment from "moment";
import { useOutletContext } from "react-router-dom";

import WeatherCard from "../component/dashboard/WeatherCard.js";
import HeatmapCard from "../component/dashboard/HeatmapCard.js";
import SocChartCard from "../component/dashboard/SocChartCard.js";
import PowerEnergyCard from "../component/dashboard/PowerEnergyCard.js";
import PVConditionContent from "../component/dashboard/PVConditionContent";
import AlarmContent from "../component/dashboard/AlarmContent.js";
import SummaryCard from "../component/dashboard/SummaryCard.js";
import SystemInformationCard from "../component/dashboard/SystemInformationCard.js";

import StatisticGraph from "../component/dashboard/StatisticGraph.js";

/**
 *
 * update weather periodically: 30mins - 1hr (but it shouldn't be in useEffect ba?)
 *  - solar irradiance & performance ratio update every 30 seconds
 *  - time need update < every 1 min
 *
 *  irradiance on (1) weather and (2) graph only show if site has weather station
 *  overall = pvstation & tnb & utility
 */
function Dashboard() {
  const [
    siteSelect,
    setSiteSelect,
    siteChange,
    setSiteChange,
    isDarkMode,
    setIsDarkMode,
    subscribePpaBilling,
    subscribeCarbonManagement,
    subscribeAiPvDiagnosis,
    subscribeAiBess,
    hasInverter,
  ] = useOutletContext();

  const heightRef = useRef();
  const [firstLoad, setFirstLoad] = useState(true); // this is used so that alarm and carousel width can be set correctly before rendering the components

  const pvConditionAlarmRadioOptions = [
    { label: "Alarm", value: "alarm" },
    { label: "PV Condition", value: "pvcondition" },
  ];
  const [pvConditionAlarmRadioSelection, setPvConditionAlarmRadioSelection] =
    useState(pvConditionAlarmRadioOptions[0].value);

  const [refreshCheck, setRefreshCheck] = useState(false);
  const [checkDate, setCheckDate] = useState(
    moment().local().format("YYYY-MM-DD HH:mm")
  );

  useEffect(() => {
    if (refreshCheck) {
      setCheckDate(moment().local().format("YYYY-MM-DD HH:mm"));
      setRefreshCheck(false);
    }
  }, [refreshCheck]);

  useEffect(() => {
    setFirstLoad(false);
  }, []);

  return (
    <div className="dashboard-container">
      <div className="weather-sysinfo-summary">
        <WeatherCard className={"weather-card"} />

        <SystemInformationCard
          className={"sys-info-card"}
          containerID={"dashboard-system-information-graph"}
        />

        <SummaryCard className={"summary-card"} />
      </div>
      <div className="all-graphs">
        <StatisticGraph
          className={"statistic-graphs"}
          cardClassName={"statistic-graph-card"}
        />
        <div className="other-graphs">
          <PowerEnergyCard className={"power-energy"} />

          <div className="condition-heatmap">
            <Card
              className="condition-alarm-card"
              title={
                <div className="condition-alarm-card-title">
                  {subscribeAiPvDiagnosis ? (
                    <Segmented
                      options={pvConditionAlarmRadioOptions}
                      onChange={(value) => {
                        setPvConditionAlarmRadioSelection(value);
                      }}
                      value={pvConditionAlarmRadioSelection}
                    />
                  ) : (
                    <div>Alarm</div>
                  )}

                  <span className="condition-alarm-card-title-span">
                    Last Check:{" "}
                    <span>
                      <b>{checkDate}</b>
                    </span>
                  </span>
                </div>
              }
            >
              {pvConditionAlarmRadioSelection == "pvcondition" ? (
                <PVConditionContent setRefreshCheck={setRefreshCheck} />
              ) : (
                <AlarmContent setRefreshCheck={setRefreshCheck} />
              )}
            </Card>
            {hasInverter !== null && subscribeAiBess !== null ? (
              <Card ref={heightRef} style={{ height: "100%" }}>
                {!firstLoad ? (
                  <Carousel
                    style={{ height: "100%" }}
                    autoplay={false}
                    dotPosition="bottom"
                    dots={{ className: "bgPrimary" }}
                    infinite={false}
                  >
                    {hasInverter ? (
                      <div>
                        <HeatmapCard
                          className={"heatmap-card"}
                          heightRef={heightRef}
                        />
                      </div>
                    ) : null}
                    {subscribeAiBess ? (
                      <div>
                        <SocChartCard heightRef={heightRef} />
                      </div>
                    ) : null}
                  </Carousel>
                ) : (
                  <div>
                    <Skeleton
                      active={true}
                      style={{
                        padding: "1rem",
                        maxWidth: "70%",
                        margin: "auto",
                      }}
                      paragraph={{ rows: 10 }}
                    />
                  </div>
                )}
              </Card>
            ) : (
              <Card ref={heightRef} style={{ height: "100%" }}>
                <div>
                  <Skeleton
                    active={true}
                    style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
                    paragraph={{ rows: 10 }}
                  />
                </div>
              </Card>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
