import "../css/pages/Management.css";
import { Card, Button, message } from "antd";
import { useState, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { BACKEND_URL } from "../configuration";
import { fetchHelper } from "../functions/fetch";
import SiteManagement from "../component/Management/SiteManagement";
import UserManagement from "../component/Management/UserManagement";
import { useOutletContext } from "react-router-dom";

/**
 * TO-DO
 *
 * extract buttons from both columns into 1 component
 * need to pull
 *  - tariff type
 *  - connection type
 *  - site list
 */

const tabList = [
  {
    key: "userManagementTab",
    tab: "User Management",
  },
  {
    key: "siteManagementTab",
    tab: "Site Management",
  },
];

/**
 * Main: Management Page
 */
function Management() {
  const [isLoading, setIsLoading] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [
    siteSelect,
    setSiteSelect,
    siteChange,
    setSiteChange,
    isDarkMode,
    setIsDarkMode,
  ] = useOutletContext();

  const [activeTabKey, setactiveTabKey] = useState("userManagementTab");
  const onTabChange = (key) => {
    setactiveTabKey(key);
  };

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [editModalStatus, setEditModalStatus] = useState(false);
  const modalOpenForTab = (tab) => {
    return isAddModalOpen && activeTabKey === tab;
  };
  const editModalOpenForTab = (tab) => {
    return editModalStatus && activeTabKey === tab;
  };
  const closeModal = () => {
    isAddModalOpen ? setIsAddModalOpen(false) : setEditModalStatus(false);
  };

  // User Management
  const [currentUserDetails, setCurrentUserDetails] = useState();
  const [userTableData, setUserTableData] = useState([]);
  const [editUserData, setEditUserData] = useState({});

  // Site Management
  const [siteTableData, setSiteTableData] = useState([]);
  const [editSiteData, setEditSiteData] = useState({});
  const [addSiteLoading, setAddSiteLoading] = useState({
    loading: false,
    api: false,
  });

  const onEditClick = (id, tab) => {
    if (tab === "userManagementTab") {
      setEditUserData(id);
    } else if (tab === "siteManagementTab") {
      getSite(id);
    }
    return setEditModalStatus(true);
  };

  const onDeleteClick = (id, tab) => {
    if (tab === "userManagementTab") {
      deleteUser(id);
    } else if (tab === "siteManagementTab") {
      deleteSite(id);
    }
  };
  const onUserSubmit = (user_data) => {
    insertUser(user_data);
  };

  const onEditUserSubmit = (user_data) => {
    updateUser(user_data);
  };

  const addSiteSubmit = (site_data) => {
    insertSite(site_data);
  };

  const editSiteSubmit = (value) => {
    updateSite(value);
  };

  // Site Management
  /** API to insert site to database */
  function insertSite(site_data) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        address: site_data.Address,
        connection_type: site_data.ConnectionTypeId,
        contact: site_data.Contact,
        email: site_data.Email,
        latitude: site_data.Latitude,
        longitude: site_data.Longitude,
        pic: site_data.Name,
        short_site_name: site_data.SiteShortName,
        site_name: site_data.SiteName,
        tariff: site_data.TariffTypeId,
        capacity: site_data.CapacityInkWp,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllSiteData();
        setSiteChange(true);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/site_management/insertSite",
      cb,
      () => {},
      "Insert Site",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get all the site data */
  function getAllSiteData() {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        // message.success(data.message);
        setSiteTableData(data.site_data);
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/site_management/getAllSiteData`,
      cb,
      setIsLoading,
      "Get All Site Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to delete site data */
  function deleteSite(id) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllSiteData();
        setSiteChange(true);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/site_management/${id}`,
      cb,
      () => {},
      "Delete Site",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get site data that user wants to edit */
  function getSite(id) {
    setEditLoading(true);
    const requestOptions = {
      method: "GET",
      header: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setEditSiteData(data.site_data);
    };

    fetchHelper(
      `${BACKEND_URL}/site_management/${id}`,
      cb,
      setEditLoading,
      "Get Site",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to update site data */
  function updateSite(site_data) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        address: site_data.Address,
        connection_type: site_data.ConnectionTypeId,
        contact: site_data.Contact,
        email: site_data.Email,
        latitude: site_data.Latitude,
        longitude: site_data.Longitude,
        pic: site_data.Name,
        short_site_name: site_data.SiteShortName,
        site_name: site_data.SiteName,
        tariff: site_data.TariffTypeId,
        capacity: site_data.CapacityInkWp,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllSiteData();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/site_management/${site_data.ID}`,
      cb,
      () => {},
      "Update Site",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function checkSiteNameFunc(e) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_name: e,
      }),
    };

    return new Promise((resolve, reject) => {
      fetchHelper(
        `${BACKEND_URL}/site_management/checkUniqueSiteName`,
        (data) => {
          resolve(data);
        },
        () => {},
        "Check Unique Site Name",
        () => {},
        () => {},
        requestOptions,
        true
      );
    });
  }

  // User Management Func
  /** API to get all the user data */
  function getAllUserData() {
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        // message.success(data.message);
        setUserTableData(data.user_data);
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/user_management/getAllUserData`,
      cb,
      setIsLoading,
      "Get All User Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getCurrentUserDetails() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setCurrentUserDetails(data.user_data);
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/user_management/getCurrentUserDetails`,
      cb,
      () => {},
      "Get Current User Details",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function insertUser(user_data) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        user_details: user_data,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        getAllUserData();
        getCurrentUserDetails();
        message.success(data.message);
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/user_management/insertUser`,
      cb,
      () => {},
      "Insert User",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function checkUserNameFunc(e) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        checkUsername: e,
      }),
    };

    return new Promise((resolve, reject) => {
      fetchHelper(
        `${BACKEND_URL}/user_management/checkUsername`,
        (data) => {
          resolve(data);
        },
        () => {},
        "Check Username",
        () => {},
        () => {},
        requestOptions,
        true
      );
    });
  }

  function updateUser(user_data) {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        user_details: user_data,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllUserData();
        getCurrentUserDetails();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/user_management/${user_data.ID}`,
      cb,
      () => {},
      "Update User",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function deleteUser(id) {
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllUserData();
        getCurrentUserDetails();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/user_management/${id["ID"]}`,
      cb,
      () => {},
      "Delete User",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  const contentList = {
    userManagementTab: (
      <UserManagement
        tableData={userTableData}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        modalOpen={modalOpenForTab("userManagementTab")}
        editModalOpen={editModalOpenForTab("userManagementTab")}
        closeModal={closeModal}
        initialValues={editUserData}
        userDetails={currentUserDetails}
        onSubmit={onUserSubmit}
        editSubmit={onEditUserSubmit}
        checkUserName={checkUserNameFunc}
      />
    ),
    siteManagementTab: (
      <SiteManagement
        tableData={siteTableData}
        editModalStatus={editModalStatus}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        modalOpen={modalOpenForTab("siteManagementTab")}
        editModalOpen={editModalOpenForTab("siteManagementTab")}
        closeModal={closeModal}
        initialValues={editSiteData}
        onAddSubmit={addSiteSubmit}
        onEditSubmit={editSiteSubmit}
        checkSiteName={checkSiteNameFunc}
        editLoading={editLoading}
      />
    ),
  };

  // this useEffect is to initialise all the table data
  useEffect(() => {
    getAllSiteData();
    getAllUserData();
    getCurrentUserDetails();
  }, []);

  return (
    <Card
      className="context-card management-card"
      tabList={tabList}
      activeTabKey={activeTabKey}
      onTabChange={onTabChange}
      tabBarExtraContent={
        <Button
          name="Add New Entry"
          type="text"
          icon={<PlusOutlined />}
          onClick={() => setIsAddModalOpen(true)}
        />
      }
      style={{ overflowY: "hidden", margin: "10px" }}
      bodyStyle={{
        height: "95%",
        // overflowY: "scroll"
      }}
      loading={isLoading}
    >
      {contentList[activeTabKey]}
    </Card>
  );
}

export default Management;
