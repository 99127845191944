import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import AssetLayout from "./pages/AssetLayout";
import SiteList from "./pages/SiteList";
import Management from "./pages/Management";
import LandingPage from "./pages/LoadingPage";

import "./css/pages/index.css";
import Dashboard from "./pages/Dashboard";
import Maintenance from "./pages/Maintenance";
import Billing from "./pages/Billing";
import AiBess from "./pages/AiBess.js";
import PowerDemand from "./pages/PowerDemand.js";
import ParametersTrending from "./pages/ParametersTrending.js";
import EnergyDemand from "./pages/EnergyDemand.js";
import System from "./pages/System.js";
import Alarm from "./pages/Alarm.js";
import SystemReport from "./pages/SystemReport.js";
import STReport from "./pages/STReport.js";
import SavingReport from "./pages/SavingReport.js";
import ChangePassword from "./pages/ChangePassword.js";
import PvCondition from "./pages/PvCondition.js";
import Configuration from "./pages/Configuration.js";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/" element={<AssetLayout />}>
        <Route index element={<Navigate to="/login" />} />
        <Route path="dashboard" element={<Dashboard />}></Route>
        <Route path="powerdemand" element={<PowerDemand />}></Route>
        <Route path="energydemand" element={<EnergyDemand />}></Route>
        <Route path="parameter" element={<ParametersTrending />}></Route>
        <Route path="system" element={<System />}></Route>
        <Route path="alarm" element={<Alarm />}></Route>
        <Route path="pvcondition" element={<PvCondition />}></Route>
        <Route path="systemreport" element={<SystemReport />}></Route>
        <Route path="streport" element={<STReport />}></Route>
        <Route path="savingreport" element={<SavingReport />}></Route>
        <Route path="carbonmanagement" element={<LandingPage />}></Route>
        <Route path="sitelist" element={<SiteList />}></Route>
        <Route path="management" element={<Management />}></Route>
        <Route path="maintenance" element={<Maintenance />}></Route>
        <Route path="billing" element={<Billing />}></Route>
        <Route path="aibess" element={<AiBess />}></Route>
        <Route path="configuration" element={<Configuration />}></Route>
        <Route path="changepassword" element={<ChangePassword />}></Route>
        <Route path="other" element={<LandingPage />}></Route>
      </Route>
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  </BrowserRouter>
);
