import { Table } from "antd";
import { useState } from "react";

/*
    TODO:
        add Loading


    PROBLEM:
        column width can't be set when table squeezes
            -> Fix:
                - set table scroll-x to false
                - wrap table with DOUBLE container
                    - one container to overflow-x: scroll
                    - set min-width for table container 

        pageSizeOption dissapers when screen size is small
*/

/**
 * Component: Custom Table Template
 */
export function CustomTable({
  dataSource,
  columns,
  row_per_page,
  tableHeight,
  pageSizeOptions = [10, 30, 31],
  tableContainerStyleObject,
  tableWidth,
}) {
  if (!pageSizeOptions.includes(row_per_page)) {
    pageSizeOptions.push(row_per_page);
    pageSizeOptions.sort((a, b) => a - b); // to sort numeric value
  }

  const [pageSize, setPageSize] = useState(row_per_page);
  return (
    <div style={{ width: "100%" }}>
      {" "}
      {/* scroll container */}
      <div style={tableContainerStyleObject}>
        {" "}
        {/* size container */}
        <Table
          dataSource={dataSource.map((item, index) => ({
            ...item,
            number: index + 1,
          }))}
          columns={columns}
          rowKey="number"
          pagination={{
            showSizeChanger: tableHeight != null, // show changer when table can scroll (when tableHeight is not null). else the changeer won't work anyways
            pageSize: pageSize,
            pageSizeOptions: pageSizeOptions,
            onShowSizeChange: (_, pageSize) => setPageSize(pageSize),
          }}
          scroll={{ x: tableWidth ? tableWidth : false, y: tableHeight }}
        />
      </div>
    </div>
  );
}
