import { useEffect, useState } from "react";
import { Card, Skeleton, message } from "antd";
import StatisticGraphContent from "../common/StatisticGraphContent";
import { BACKEND_URL, MQTT_REFRESH_RATE } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { useOutletContext } from "react-router-dom";

function StatisticGraph({ className, cardClassName }) {
  const [siteSelect] = useOutletContext();

  // Loading
  const [statisticDataLoading, setStatisticDataLoading] = useState(true);
  const [statisticGraphDataLoading, setStatisticGraphDataLoading] =
    useState(true);

  // Pv Yield
  const [pvYieldGraphData, setPvYieldGraphData] = useState([]);
  const [pvYield, setPvYield] = useState(0);

  // Load Consumption
  const [loadConsumptionGraphData, setLoadConsumptionGraphData] = useState([]);
  const [loadConsumption, setLoadConsumption] = useState(0);

  // Revenue
  const [revenueGraphData, setRevenueGraphData] = useState([]);
  const [revenue, setRevenue] = useState(0);

  // CO2 Emission
  const [co2EmissionGraphData, setCo2EmissionGraphData] = useState([]);
  const [co2Emission, setCo2Emission] = useState(0);

  function getStatisticData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setPvYield(data.pv_system_production);
        setLoadConsumption(data.load_consumption);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getMonthSummary`,
      cb,
      setStatisticDataLoading,
      "Get Statistic Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getStatisticGraphData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setPvYieldGraphData(data.pv_kWh_I);
        setLoadConsumptionGraphData(data.load_consumption_graph_data);
        setRevenueGraphData(data.daily_revenue_list);
        setRevenue(data.sum_revenue);
        // setCo2Emission(data.sum_co2_emission);
        setCo2EmissionGraphData(data.co2_emission_list);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getStatisticsGraphData`,
      cb,
      setStatisticGraphDataLoading,
      "Get Statistic Graph Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getStatisticData();
      getStatisticGraphData();

      const timer = setInterval(() => {
        getStatisticData();
        getStatisticGraphData();
      }, 30 * 60 * 1000); // refresh every 30 mins

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelect]);

  return (
    <>
      {statisticDataLoading === false && statisticGraphDataLoading === false ? (
        <div className={className}>
          <Card
            className={cardClassName}
            loading={statisticDataLoading || statisticGraphDataLoading}
            style={{
              padding:
                statisticDataLoading || statisticGraphDataLoading
                  ? "5px"
                  : "0px",
            }}
          >
            <StatisticGraphContent
              data={pvYieldGraphData}
              value={
                pvYield === "-"
                  ? `-`
                  : `${pvYield.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} kWh`
              }
              change={0} // it's dummy for now, will be changed later if figure out what to show
              title={"PV Yield"}
              fillColor={"#03C19C"}
              fillColorGradient={"#9ce29c"}
            />
          </Card>
          <Card
            className={cardClassName}
            loading={statisticDataLoading || statisticGraphDataLoading}
            style={{
              padding:
                statisticDataLoading || statisticGraphDataLoading
                  ? "5px"
                  : "0px",
            }}
          >
            <StatisticGraphContent
              data={loadConsumptionGraphData}
              value={
                loadConsumption === "-"
                  ? `-`
                  : `${loadConsumption.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} kWh`
              }
              change={0}
              title={"Consumption"}
              fillColor={"#000038"}
              fillColorGradient={"rgba(184,186,200,1)"}
            />
          </Card>
          <Card
            className={cardClassName}
            loading={statisticDataLoading || statisticGraphDataLoading}
            style={{
              padding:
                statisticDataLoading || statisticGraphDataLoading
                  ? "5px"
                  : "0px",
            }}
          >
            <StatisticGraphContent
              data={revenueGraphData}
              value={
                pvYield === "-"
                  ? "-"
                  : revenue === "-"
                  ? `-`
                  : `RM ${revenue.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
              }
              change={0}
              title={"PV Revenue"}
              fillColor={"rgba(184,186,200,1)"}
              fillColorGradient={"#dadada"}
            />
          </Card>
          <Card
            className={cardClassName}
            loading={statisticDataLoading || statisticGraphDataLoading}
            style={{
              padding:
                statisticDataLoading || statisticGraphDataLoading
                  ? "8px"
                  : "0px",
            }}
          >
            <StatisticGraphContent
              data={co2EmissionGraphData}
              value={
                pvYield === "-"
                  ? `-`
                  : `${((pvYield * 0.758) / 1000).toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} Tons`
              }
              change={0}
              title={"Total CO\u2082 Avoidance"} //\u2082 is the unicode for subscript 2
              fillColor={"#03C19C"}
              fillColorGradient={"#9ce29c"}
            />
          </Card>
        </div>
      ) : (
        <div className={className}>
          <Card style={{ width: "100%", height: "100%" }}>
            <Skeleton
              active={true}
              style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
              paragraph={{ rows: 2 }}
            />
          </Card>
          <Card style={{ width: "100%", height: "100%" }}>
            <Skeleton
              active={true}
              style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
              paragraph={{ rows: 2 }}
            />
          </Card>
          <Card style={{ width: "100%", height: "100%" }}>
            <Skeleton
              active={true}
              style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
              paragraph={{ rows: 2 }}
            />
          </Card>
          <Card style={{ width: "100%", height: "100%" }}>
            <Skeleton
              active={true}
              style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
              paragraph={{ rows: 2 }}
            />
          </Card>
        </div>
      )}
    </>
  );
}

export default StatisticGraph;
