import cloud_video from "../../assets/video.mp4";
import "../../css/component/dashboard/WeatherCard.css";
import { BACKEND_URL } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { useEffect, useState } from "react";
import { Card, Skeleton, message } from "antd";
import { useOutletContext } from "react-router-dom";

function WeatherCard({ className }) {
  const [siteSelect, setSiteSelect, siteChange, setSiteChange] =
    useOutletContext();
  const [weatherForecastLoading, setWeatherForecastLoading] = useState(true);
  const [performanceRatioLoading, setPerformanceRatioLoading] = useState(true);

  const time = new Date()
    .toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    })
    .toLowerCase();

  const [temp, setTemp] = useState(null);
  const [weather, setWeather] = useState(null);
  const [location, setLocation] = useState(null);
  const [solar, setSolar] = useState(null);
  const [performanceRatio, setPerformanceRatio] = useState(null);
  const [hasWeatherStation, setHasWeatherStation] = useState(false);

  function getWeatherForecast() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setLocation(data.city);
        setWeather(data.description);
        setTemp(data.temp);
      } else {
        setLocation(null);
        setWeather(null);
        setTemp(null);
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getWeatherForecast`,
      cb,
      setWeatherForecastLoading,
      "Get Weather Forecast",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getPerformanceRatio() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setSolar(data.performance_ratio_data.irradiance_value);
        setPerformanceRatio(data.performance_ratio_data.performance_ratio);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getPerformanceRatio`,
      cb,
      setPerformanceRatioLoading,
      "Get Performance Ratio",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getWeatherForecast();
      getPerformanceRatio();

      const timer = setInterval(() => {
        getWeatherForecast();
        getPerformanceRatio();
      }, 300000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelect]);

  return (
    <>
      {weatherForecastLoading === false && performanceRatioLoading === false ? (
        <Card
          className={className}
          bodyStyle={{
            height:
              weatherForecastLoading || performanceRatioLoading
                ? "100%"
                : "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="weather-card-content">
            <video className="weather-background" autoPlay loop muted>
              <source src={cloud_video} type="video/mp4"></source>
            </video>
            <span className="weather-location">
              {location ?? "Unknown Site"}
            </span>
            <span className="weather-time">{time ?? " "}</span>
            <span className="weather-temperature">
              {temp ? temp + "°C" : " "}
            </span>
            <span className="weather-weather">{weather ?? " "}</span>
            {hasWeatherStation ? (
              <span className="weather-solar-irradiance">
                Solar Irradiance:{" "}
                {solar ? (
                  solar != "-" ? (
                    <>
                      {solar} W/m<sup>2</sup>
                    </>
                  ) : (
                    solar
                  )
                ) : solar == 0 ? (
                  solar
                ) : (
                  "-"
                )}
              </span>
            ) : null}
            {hasWeatherStation ? (
              <span className="weather-performance-ratio">
                Performance Ratio:{" "}
                {performanceRatio
                  ? performanceRatio != "-"
                    ? performanceRatio + " %"
                    : performanceRatio
                  : performanceRatio == 0
                  ? performanceRatio
                  : "-"}
              </span>
            ) : null}
          </div>
        </Card>
      ) : (
        <Card style={{ width: "100%", height: "100%" }}>
          <Skeleton
            active={true}
            style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
            paragraph={{ rows: 2 }}
          />
        </Card>
      )}
    </>
  );
}

export default WeatherCard;
