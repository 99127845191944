// import './css/LoadingPage.css';
import { Skeleton } from 'antd';

function LandingPage() {

  return (
    <>
        <Skeleton active={true} paragraph={{rows:10}} style={{padding:'2rem', maxWidth:'70%', margin:'auto'}} />
    </>
  );
}

export default LandingPage;