import { Heatmap } from "@ant-design/plots";
import { Card, Empty, Skeleton, message } from "antd";
import "../../css/component/dashboard/HeatmapCard.css";
import { BACKEND_URL } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";

function HeatmapCard({ className, heightRef }) {
  const [loading, setLoading] = useState(false);
  const [siteSelect] = useOutletContext();

  const [heatmapData, setHeatmapData] = useState([]);

  const [data, setData] = useState([]);

  useEffect(() => {
    asyncFetch();
  }, []);

  const asyncFetch = () => {
    fetch(
      "https://gw.alipayobjects.com/os/basement_prod/a719cd4e-bd40-4878-a4b4-df8a6b531dfe.json"
    )
      .then((response) => response.json())
      .then((json) => setData(json))
      .catch((error) => {
        console.log("fetch data failed", error);
      });
  };

  function getPowerHeatmapData() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setHeatmapData(data.power_heatmap_data_df);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getHeatmapData`,
      cb,
      setLoading,
      "Get Power Heatmap Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getPowerHeatmapData();
    }
  }, [siteSelect]);

  const config1 = {
    autoFit: true,
    data,
    xField: "Month of Year",
    yField: "District",
    colorField: "AQHI",
    color: ["#ccece6", "#99d8c9", "#66c2a4", "#2ca25f", "#004a1e"], //['#d9d9d9', '#bdbdbd', '#969696', '#737373'],
    legend: {
      layout: "horizontal",
      position: "bottom",
      title: {
        spacing: 5,
      },
      slidable: false,
    },
    meta: {
      "Month of Year": {
        type: "cat", // specify that a particular field should be treated as a categorical (or discrete) variable
      },
    },
    style: {
      height: "100%",
    },
  };

  const config = {
    autoFit: true,
    data: heatmapData,
    xField: "Date",
    yField: "Device",
    colorField: "Performance Ratio",
    color: ["#ccece6", "#99d8c9", "#66c2a4", "#2ca25f", "#004a1e"], //['#d9d9d9', '#bdbdbd', '#969696', '#737373'],
    legend: {
      layout: "horizontal",
      position: "bottom",
      title: {
        spacing: 5,
      },
      slidable: false,
    },
    meta: {
      Date: {
        type: "cat", // specify that a particular field should be treated as a categorical (or discrete) variable
      },
      "Performance Ratio": {
        formatter: (value) =>
          value.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }), // format Performance Ratio to 2 decimal places
      },
    },
    style: {
      height: "100%",
    },
  };

  return (
    <>
      {loading === false ? (
        <Card
          className={className}
          style={{
            height: heightRef.current?.clientHeight,
          }}
          title="Power Heatmap"
        >
          <div className="heatmap-content">
            {heatmapData.length > 0 ? (
              <Heatmap {...config} />
            ) : (
              <Empty
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              />
            )}
          </div>
        </Card>
      ) : (
        <Card
          className={className}
          style={{
            height: heightRef.current?.clientHeight,
          }}
        >
          <div className="heatmap-content">
            <Skeleton
              active={true}
              style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
              paragraph={{ rows: 10 }}
            />
          </div>
        </Card>
      )}
    </>
  );
}

export default HeatmapCard;
