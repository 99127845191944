import { EyeOutlined } from "@ant-design/icons";
import { Button, Spin, message } from "antd";
import { CustomTable } from "../common/table";
import DeviceInformation from "./DeviceInformation";
import { useTableSearch } from "../common/tableSearch";
import { useState } from "react";
import { BACKEND_URL } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";

function InverterTable({ tableData, handleInverterClick, inverterLoading }) {
  const { getColumnSearchProps } = useTableSearch();

  const inverterListTableColumns = [
    {
      title: "ID.",
      dataIndex: "number",
      key: "number", // give this key to auto generate sequence
    },
    {
      title: "Name",
      dataIndex: "DeviceName",
      key: "DeviceName",
      ...getColumnSearchProps("DeviceName"),
    },
    {
      title: "Installed Power",
      dataIndex: "InstalledPower",
      key: "InstalledPower",
    },
    {
      title: "Active Power",
      dataIndex: "ActivePower",
      key: "ActivePower",
    },
    {
      title: "Equivalent Hours",
      dataIndex: "EquivalentHours",
      key: "EquivalentHours",
    },
    {
      title: "Daily Generation",
      dataIndex: "DailyGeneration",
      key: "DaillyGeneration",
    },
    {
      title: "Total Generation",
      dataIndex: "TotalGeneration",
      key: "TotalGeneration",
    },
    {
      title: "Active Alarm",
      dataIndex: "ActiveAlarm",
      key: "ActiveAlarm",
    },
    {
      title: "",
      dataIndex: "view",
      key: "view",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}
        >
          {inverterLoading ? (
            <Spin />
          ) : (
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => {
                handleInverterClick(record.Key);
              }}
            >
              View
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <CustomTable
      dataSource={tableData}
      columns={inverterListTableColumns}
      row_per_page={7} // to fit the screen when at 100%
      tableHeight={450} // to fit the screen when at 100%
      tableContainerStyleObject={{
        width: "100%",
        height: "100%",
        minWidth: "700px",
      }}
    />
  );
}

function Inverter({ tableData, openModal, setOpenModal, token }) {
  const [inverterLoading, setInverterLoading] = useState(false);
  const [inverterCardData, setInverterCardData] = useState([]);

  const handleInverterClick = (data) => {
    getInverterMQTTData(data);
  };

  function getInverterMQTTData(data) {
    setInverterLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setOpenModal(true);
        setInverterCardData(data.data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/system/getDeviceMQTT/${data}`,
      cb,
      setInverterLoading,
      "Get Inveter Device MQTT Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  return (
    <>
      <InverterTable
        tableData={tableData}
        openModal={openModal}
        handleInverterClick={handleInverterClick}
        inverterLoading={inverterLoading}
      />
      <DeviceInformation
        deviceCardData={inverterCardData}
        openModal={openModal}
        setOpenModal={setOpenModal}
        themeToken={token}
        title="Inverter Card"
      />
    </>
  );
}

export default Inverter;
