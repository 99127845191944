import { Button, Table, Flex, Modal, message, Form, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import TelegramForm from "./TelegramForm";
import { fetchHelper } from "../../functions/fetch";
import { BACKEND_URL } from "../../configuration";

function Telegram(props) {
  const { siteID } = props;

  const [alarmTelegramConfigForm] = Form.useForm();

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [isAddTelegramFormModalOpen, setIsAddTelegramFormModalOpen] =
    useState(false);
  const [isEditTelegramFormModalOpen, setIsEditTelegramFormModalOpen] =
    useState(false);

  const [alarmTelegramTableData, setAlarmTelegramTableData] = useState([]);
  const [editingAlarmTelegramID, setEditingAlarmTelegramID] = useState(null);
  const [deletingAlarmTelegramID, setDeletingAlarmTelegramID] = useState(null);

  // loading states
  const [alarmTelegramTableDataLoading, setAlarmTelegramTableDataLoading] =
    useState(false);
  const [saveSelectedTelegramsLoading, setSaveSelectedTelegramsLoading] =
    useState(false);
  const [
    submitTelegramDetailsButtonLoading,
    setSubmitTelegramDetailsButtonLoading,
  ] = useState(false);
  const [telegramDetailsRetrievalLoading, setTelegramDetailsRetrievalLoading] =
    useState(false);

  const columns = [
    {
      title: "Group Name",
      dataIndex: "Name",
      key: "Name",
      width: "50%",
    },
    {
      title: "Chat ID",
      dataIndex: "ChatID",
      key: "ChatID",
      width: "40%",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      width: "10%",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-start" }}
        >
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => {
              setIsEditTelegramFormModalOpen(true);
              getTelegram(record.ID);
              setEditingAlarmTelegramID(record.ID);
            }}
          />
          <Popconfirm
            title="Delete Telegram"
            description={"Are you sure to delete this telegram?"}
            onConfirm={() => {
              deleteTelegram(record.ID);
            }}
            onCancel={() => {
              message.success("Action is cancelled");
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button
              type="text"
              icon={<DeleteOutlined />}
              onClick={() => {
                setDeletingAlarmTelegramID(record.ID);
              }}
            />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  /** API to add telegram data*/
  function addTelegram(value) {
    setSubmitTelegramDetailsButtonLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteID,
        telegram_group_name: value.Name,
        telegram_chat_id: value.ChatID,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setIsAddTelegramFormModalOpen(false);
        alarmTelegramConfigForm.resetFields();
        getAllTelegram();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_telegram_notification_config/addTelegram",
      cb,
      setSubmitTelegramDetailsButtonLoading,
      "Add Alarm Telegram Config",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get all telegram data*/
  function getAllTelegram() {
    setAlarmTelegramTableDataLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteID,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setSelectedRowKeys(data.row_key_list);
        setAlarmTelegramTableData(data.alarm_details);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_telegram_notification_config/getAllTelegram",
      cb,
      setAlarmTelegramTableDataLoading,
      "Get All Alarm Telegram",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get telegram that user choose to edit */
  function getTelegram(alarm_telegram_id) {
    setTelegramDetailsRetrievalLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        alarm_telegram_id: alarm_telegram_id,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        alarmTelegramConfigForm.setFieldsValue(data.telegram_details);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_telegram_notification_config/getTelegram",
      cb,
      setTelegramDetailsRetrievalLoading,
      "Get Telegram User Chooses to Edit",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to update telegram that user choose to edit */
  function updateTelegram(value) {
    setSubmitTelegramDetailsButtonLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        alarm_telegram_id: editingAlarmTelegramID,
        telegram_group_name: value.Name,
        telegram_chat_id: value.ChatID,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setIsEditTelegramFormModalOpen(false);
        alarmTelegramConfigForm.resetFields();
        getAllTelegram();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_telegram_notification_config/updateTelegram",
      cb,
      setSubmitTelegramDetailsButtonLoading,
      "Update Telegram User Chooses to Edit",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to delete telegram that user choose to delete */
  function deleteTelegram(value) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        alarm_telegram_id: deletingAlarmTelegramID,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllTelegram();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_telegram_notification_config/deleteTelegram",
      cb,
      () => {},
      "Delete Telegram User Chooses to Delete",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to save telegram that user selects */
  function saveSelectedTelegrams() {
    setSaveSelectedTelegramsLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        row_key_list: selectedRowKeys,
        site_id: siteID,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        getAllTelegram();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/alarm_telegram_notification_config/saveSelectedTelegrams",
      cb,
      setSaveSelectedTelegramsLoading,
      "Save Selected Telegrams",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteID) {
      getAllTelegram();
    }
  }, [siteID]);

  return (
    <Flex vertical gap={"8px"}>
      <Button
        icon={<PlusOutlined />}
        style={{ width: "200px" }}
        onClick={() => setIsAddTelegramFormModalOpen(true)}
      >
        New Telegram Group
      </Button>

      <Modal
        title="Add New Telegram Group"
        open={isAddTelegramFormModalOpen}
        footer={null}
        closable={false}
      >
        <TelegramForm
          setIsTelegramFormModalOpen={setIsAddTelegramFormModalOpen}
          mode={"add"}
          addTelegram={addTelegram}
          form={alarmTelegramConfigForm}
          submitButtonLoading={submitTelegramDetailsButtonLoading}
        />
      </Modal>

      <Modal
        title="Edit Telegram Group"
        open={isEditTelegramFormModalOpen}
        footer={null}
        closable={false}
      >
        <TelegramForm
          setIsTelegramFormModalOpen={setIsEditTelegramFormModalOpen}
          mode={"edit"}
          updateTelegram={updateTelegram}
          form={alarmTelegramConfigForm}
          submitButtonLoading={submitTelegramDetailsButtonLoading}
          telegramDetailsRetrievalLoading={telegramDetailsRetrievalLoading}
        />
      </Modal>

      <Table
        loading={alarmTelegramTableDataLoading}
        columns={columns}
        dataSource={alarmTelegramTableData}
        pagination={false}
        scroll={{ y: 450, x: true }}
        size="small"
        rowSelection={{
          ...rowSelection,
          checkStrictly: false,
        }}
      />
      <Button
        style={{ width: "100px", alignSelf: "center" }}
        onClick={() => {
          saveSelectedTelegrams();
        }}
        loading={saveSelectedTelegramsLoading}
        telegramDetailsRetrievalLoading={telegramDetailsRetrievalLoading}
      >
        Save
      </Button>
    </Flex>
  );
}

export default Telegram;
