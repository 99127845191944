import { Modal, Form } from "antd";
import { useEffect } from "react";

/**
 * Component: A Form within a Modal template
 */
export function ModalForm({
  modalOpen,
  closeModal,
  modalTitle,
  formObject,
  formName,
  formBody,
  formIntitialValues,
  formSubmit,
}) {
  const onCancel = () => {
    formObject.resetFields();
    return closeModal();
  };
  const submitForm = () => {
    formObject
      .validateFields()
      .then((values) => {
        if (formIntitialValues) {
          values.ID = formIntitialValues.ID;
        }
        formSubmit(values);
        formObject.resetFields();
        closeModal();
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  useEffect(() => {
    if (modalOpen) {
      formObject.setFieldsValue(formIntitialValues);
    }
  }, [modalOpen, formIntitialValues]);

  return (
    <Modal
      className="modal-form-modal"
      title={modalTitle}
      centered
      open={modalOpen}
      closable={false}
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={submitForm}
      confirmLoading={false} // use this to set loading of form submission
      okButtonProps={{ style: { backgroundColor: "#01063B" } }}
      maskClosable={false} // not closable when click on outside region
      // width={'700px'}
    >
      <Form
        form={formObject}
        name={formName}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
        initialValues={formIntitialValues}
        onFinish={closeModal}
        onCancel={onCancel}
        layout="horizontal"
        labelAlign="left"
        requiredMark={false}
      >
        {formBody}
      </Form>
    </Modal>
  );
}
