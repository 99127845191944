import { displayErrorNotification } from "../component/common/notification";

const default_request_options = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
  credentials: "include",
};

export const fetchHelper = (
  url,
  fetchSucessCallback,
  setLoading,
  title = "",
  fetchErrorCallback = () => {},
  fetchDataErrorCallback = () => {},
  requestOptions = default_request_options,
  error_notification = true
) => {
  fetch(url, requestOptions)
    .then((response) => {
      if (response.status === 401) {
        // set to redirect after 3 seconds.
        setTimeout(() => {
          window.location = "/login";
        }, 3000);
      }

      if (response.ok) return response.json();
      else {
        throw new Error(
          `[${title}] Error ${response.status} (${response.statusText}).`
        );
      }
    })
    .then((data) => {
      if ("error" in data) {
        fetchDataErrorCallback(data);
        console.log(data.error);
        if (error_notification)
          displayErrorNotification(title, "Data Fetching Error");
      } else {
        fetchSucessCallback(data);
      }

      setLoading(false);
    })
    .catch((error) => {
      fetchErrorCallback(error);
      console.log(error);

      if (error_notification)
        displayErrorNotification(title, `${error.message}.`);

      setLoading(false);
    });
};
