import React from "react";
import { Table, message, Button } from "antd";
import { useState, useEffect } from "react";
import "../../css/component/SystemReport/OverallReportContent.css";
import dayjs from "dayjs";
import { BACKEND_URL } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { useOutletContext } from "react-router-dom";
import {
  LoadingOutlined,
  VerticalAlignBottomOutlined,
} from "@ant-design/icons";
import moment from "moment";

const { Column, ColumnGroup } = Table;

function OverallReportContent() {
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [siteSelect] = useOutletContext();

  const [dateSelection, setDateSelection] = useState(dayjs());
  const [data, setData] = useState([]);

  const [includePvSyst, setIncludePvSyst] = useState(false);

  const formatToTwoDecimalPlaces = (value) => {
    if (value === undefined || value === null || value === "-") {
      return value;
    }
    return parseFloat(value).toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  function getOverallReportData() {
    setLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setData(data.data);
        setIncludePvSyst(data.include_pv_syst);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/overall_report/getOverallReportData`,
      cb,
      setLoading,
      "Get Overall Report Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function downloadOverallReport() {
    setSaveLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: data,
        site_id: siteSelect,
      }),
    };
    fetch(BACKEND_URL + "/overall_report/downloadOverallReport", requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "OverallReport_" + moment().format("YYYYMMDDThhmm") + ".xlsx"
        );
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        setSaveLoading(false);
      });
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getOverallReportData();
    }
  }, [siteSelect, dateSelection]);

  return (
    <>
      <div className="overall-report-date-selection">
        <Button
          className="overall-report-download-button"
          disabled={loading}
          onClick={() => {
            downloadOverallReport();
          }}
        >
          {saveLoading ? <LoadingOutlined /> : <VerticalAlignBottomOutlined />}
        </Button>
      </div>
      {includePvSyst ? (
        <Table dataSource={data} scroll={{ x: 800 }} loading={loading}>
          <Column title="Year" dataIndex="year" key="year" />
          <ColumnGroup title="Energy Generation">
            <Column
              title="Actual"
              dataIndex="actualEnergyGeneration"
              key="actualEnergyGeneration"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PVSyst (Earray)"
              dataIndex="pvSystEnergyGeneration"
              key="pvSystEnergyGeneration"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Deviation"
              dataIndex="deviationEnergyGeneration"
              key="deviationEnergyGeneration"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
          <ColumnGroup title="Energy Utilization">
            <Column
              title="PV Self Consumption"
              dataIndex="pvSelfConsumption"
              key="pvSelfConsumption"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Self Consumption Ratio"
              dataIndex="selfConsumptionRatio"
              key="selfConsumptionRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PV Export"
              dataIndex="pvExport"
              key="pvExport"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PV Energy Export Ratio"
              dataIndex="pvEnergyExportRatio"
              key="pvEnergyExportRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Import From Grid"
              dataIndex="importFromGrid"
              key="importFromGrid"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
          <ColumnGroup title="Solar Irradiation">
            <Column
              title="Actual"
              dataIndex="actualSolarIrradiation"
              key="actualSolarIrradiation"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PVSyst GlobHor"
              dataIndex="pvSystGlobHor"
              key="pvSystGlobHor"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Deviation"
              dataIndex="deviationSolarIrradiation"
              key="deviationSolarIrradiation"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
          <ColumnGroup title="Performance Ratio">
            <Column
              title="Actual"
              dataIndex="actualPerformanceRatio"
              key="actualPerformanceRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PVSyst GlobHor"
              dataIndex="pvSystGlobHorPerformanceRatio"
              key="pvSystGlobHorPerformanceRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Deviation"
              dataIndex="deviationPerformanceRatio"
              key="deviationPerformanceRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
          <ColumnGroup title="Specific Yield">
            <Column
              title="Actual"
              dataIndex="actualSpecificYield"
              key="actualSpecificYield"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PVSyst"
              dataIndex="pvSystSpecificYield"
              key="pvSystSpecificYield"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Deviation"
              dataIndex="deviationSpecificYield"
              key="deviationSpecificYield"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
        </Table>
      ) : (
        <Table dataSource={data} scroll={{ x: 800 }} loading={loading}>
          <Column title="Year" dataIndex="year" key="year" />
          <Column
            title="Energy Generation"
            dataIndex="actualEnergyGeneration"
            key="actualEnergyGeneration"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />

          <ColumnGroup title="Energy Utilization">
            <Column
              title="PV Self Consumption"
              dataIndex="pvSelfConsumption"
              key="pvSelfConsumption"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Self Consumption Ratio"
              dataIndex="selfConsumptionRatio"
              key="selfConsumptionRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PV Export"
              dataIndex="pvExport"
              key="pvExport"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="PV Energy Export Ratio"
              dataIndex="pvEnergyExportRatio"
              key="pvEnergyExportRatio"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
            <Column
              title="Import From Grid"
              dataIndex="importFromGrid"
              key="importFromGrid"
              render={(value) => formatToTwoDecimalPlaces(value)}
            />
          </ColumnGroup>
          <Column
            title="Solar Irradiation"
            dataIndex="actualSolarIrradiation"
            key="actualSolarIrradiation"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />

          <Column
            title="Performance Ratio"
            dataIndex="actualPerformanceRatio"
            key="actualPerformanceRatio"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />

          <Column
            title="Specific Yield"
            dataIndex="actualSpecificYield"
            key="actualSpecificYield"
            render={(value) => formatToTwoDecimalPlaces(value)}
          />
        </Table>
      )}
    </>
  );
}

export default OverallReportContent;
