import "../css/pages/Configuration.css";
import {
  Button,
  Card,
  Divider,
  Flex,
  Input,
  message,
  Space,
  Tooltip,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { InfoCircleOutlined, UploadOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { fetchHelper } from "../functions/fetch";
import { BACKEND_URL } from "../configuration";

function ConfigurationRow({ title, value, additional, lastRow }) {
  const lastRowCSS = lastRow ? "configuration-last-row" : "";

  return (
    <div className={`configuration-row ${lastRowCSS}`}>
      <div className="configuration-col-1 align-center">
        <span className="configuration-row-title">{title}</span>
        {value === false ? (
          <></>
        ) : (
          <Divider className="configuration-divider" type="vertical" />
        )}
      </div>

      <div className="configuration-col-2">
        {value}
        {additional ?? <></>}
      </div>
    </div>
  );
}

function CompactInput({ value, setValue, unit, type }) {
  return (
    <Space.Compact>
      <Input
        size="small"
        suffix={unit}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        type={type}
      />
    </Space.Compact>
  );
}

function Configuration() {
  const [isLoading, setIsLoading] = useState(false);
  const [configurationData, setConfigurationData] = useState({});
  const [companyLogo, setCompanyLogo] = useState();
  const [logoName, setLogoName] = useState(null);
  const [oldLogoName, setOldLogoName] = useState(null);

  const changeCompanyName = (value) =>
    setConfigurationData((prev) => ({ ...prev, companyName: value }));
  const changeCompanyAccount = (value) =>
    setConfigurationData((prev) => ({ ...prev, companyAccount: value }));
  const changeCompanyBank = (value) =>
    setConfigurationData((prev) => ({ ...prev, companyBank: value }));
  const changeCompanyAddress = (value) =>
    setConfigurationData((prev) => ({ ...prev, companyAddress: value }));
  const changeEmail = (value) =>
    setConfigurationData((prev) => ({ ...prev, email: value }));
  const changePhone = (value) =>
    setConfigurationData((prev) => ({ ...prev, phone: value }));

  function validateConfiguration() {
    const logoRes = logoName == null;
    const res = inputValidation();
    const emailRes = emailValidation();

    if (logoRes) {
      return message.error("Please upload company logo");
    }

    if (!res) {
      return message.error("Please fill in all input");
    }

    if (!emailRes) {
      return message.error("Please input valid email");
    }

    insertConfiguration();
  }

  function inputValidation() {
    return Object.values(configurationData).every((value) => value != null);
  }

  function emailValidation() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(configurationData.email);
  }

  function uploadCompanyLogo(file) {
    const formData = new FormData();
    formData.append("file", file.file);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setLogoName(data.filename);

        const updatedCompanyLogo = companyLogo;
        if (updatedCompanyLogo) {
          updatedCompanyLogo.file.status = "done";
          setCompanyLogo(updatedCompanyLogo);
        }
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/configuration/uploadConfigurationImage",
      cb,
      () => {},
      "Upload Configuration Image",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function removeCompanyLogo() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: logoName,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setLogoName();
        setCompanyLogo();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/configuration/deleteConfigurationImage",
      cb,
      () => {},
      "Remove Company Logo",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function insertConfiguration() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: configurationData,
        logoName: logoName,
        oldLogoName: oldLogoName,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        message.success(data.message);
        setCompanyLogo();
        getConfiguration();
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/configuration/insertConfiguration",
      cb,
      () => {},
      "Insert Configuration",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getConfiguration() {
    setIsLoading(true);
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setConfigurationData(data.data);
        setOldLogoName(data.filename);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/configuration/getConfiguration",
      cb,
      setIsLoading,
      "Get Configuration",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getLogoImage() {
    const downloadLink = document.createElement("a");
    downloadLink.href =
      BACKEND_URL + `/configuration/getConfigurationImage/${oldLogoName}`;
    downloadLink.setAttribute("download", oldLogoName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  useEffect(() => {
    getConfiguration();
  }, []);

  return (
    <Card
      className="context-card configuration-card"
      title={"Configuration"}
      style={{ overflowY: "hidden" }}
      bodyStyle={{ height: "90%", width: "100%" }}
      loading={isLoading}
    >
      <div className="configuration-card-content">
        <ConfigurationRow
          title={"Company Logo"}
          value={
            <>
              <Upload
                beforeUpload={""}
                customRequest={uploadCompanyLogo}
                onRemove={removeCompanyLogo}
                maxCount={1}
                listType="picture"
                file={companyLogo}
                onChange={(companyLogo) => setCompanyLogo(companyLogo)}
              >
                <Button icon={<UploadOutlined />} disabled={companyLogo}>
                  Upload
                </Button>
              </Upload>
              {oldLogoName != null && (
                <Tooltip title="Click me to download the image upload before">
                  <InfoCircleOutlined onClick={() => getLogoImage()} />
                </Tooltip>
              )}
            </>
          }
        />
        <ConfigurationRow
          title={"Company Name"}
          value={
            <CompactInput
              value={configurationData.companyName}
              setValue={changeCompanyName}
              type={"text"}
            />
          }
        />
        <ConfigurationRow
          title={"Company Account"}
          value={
            <CompactInput
              value={configurationData.companyAccount}
              setValue={changeCompanyAccount}
              type={"number"}
            />
          }
        />
        <ConfigurationRow
          title={"Company Bank"}
          value={
            <CompactInput
              value={configurationData.companyBank}
              setValue={changeCompanyBank}
              type={"text"}
            />
          }
        />
        <ConfigurationRow
          title={
            <>
              <Flex gap="small">
                <span>Company Address</span>
                <Tooltip
                  title={
                    <div>
                      Please follow this format to write your address:
                      <br />
                      2, Jalan Puteri 5/18,
                      <br />
                      Bandar Puteri,
                      <br />
                      47100 Puchong,
                      <br />
                      Selangor
                    </div>
                  }
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </Flex>
            </>
          }
          value={
            <Space.Compact>
              <TextArea
                rows={4}
                value={configurationData.companyAddress}
                onChange={(e) => changeCompanyAddress(e.target.value)}
              />
            </Space.Compact>
          }
        />
        <ConfigurationRow
          title={"Email"}
          value={
            <CompactInput
              value={configurationData.email}
              setValue={changeEmail}
              type={"text"}
            />
          }
        />
        <ConfigurationRow
          title={"Phone"}
          value={
            <CompactInput
              value={configurationData.phone}
              setValue={changePhone}
              type={"number"}
            />
          }
          lastRow={true}
        />
      </div>
      <div className="configuration-button-container">
        <Button
          className="configuration-save-button"
          onClick={() => validateConfiguration()}
        >
          Save
        </Button>
      </div>
    </Card>
  );
}

export default Configuration;
