import { Card, Segmented } from "antd";
import { useState } from "react";
import Active from "../component/PvCondition/Active";
import History from "../component/PvCondition/History";

function PvCondition() {
  const pvConditionOptions = [
    { label: "Active", value: "active" },
    { label: "History", value: "history" },
  ];

  const [pvConditionSelection, setPvConditionSelection] = useState(
    pvConditionOptions[0].value
  );

  const contentList = {
    active: <Active />,
    history: <History />,
  };

  return (
    <>
      <Card
        className="context-card pv-condition-card"
        title={
          <Segmented
            className="pv-condition-segmented"
            size="large"
            options={pvConditionOptions}
            onChange={(value) => {
              setPvConditionSelection(value);
            }}
            value={pvConditionSelection}
          />
        }
      >
        {contentList[pvConditionSelection]}
      </Card>
    </>
  );
}

export default PvCondition;
