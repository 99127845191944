import Analytics from "../component/Analytics";
import { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import { VerticalDateFilter } from "../component/common/dateFilter";
import { filterDataByDateRange } from "../functions/dateFilter";
import { Empty, message, Segmented, Flex } from "antd";
import { fetchHelper } from "../functions/fetch";
import { BACKEND_URL } from "../configuration";
import { useOutletContext } from "react-router-dom";
import { Chart } from "@antv/g2";
import {
  DARK_BLUE,
  LIGHT_BLUE,
  LIGHT_LIGHT_BLUE,
  DARK_GREEN,
  LIGHT_GREEN,
  ORANGE,
} from "../configuration";

const dateTypeOptions = [
  { label: "Day", value: "date" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

function OverallEnergyChart(props) {
  const { container, data_line, data_chart, subscribeAiBess } = props;
  const chartRef = useRef(null);

  let chart_domain = ["PV (Self-Consumption)", "PV (Export)", "TNB", "Load"];
  let chart_range = [DARK_GREEN, LIGHT_GREEN, DARK_BLUE, ORANGE];
  if (subscribeAiBess === true) {
    chart_domain = [
      "PV (Self-Consumption)",
      "PV (Export)",
      "BESS Discharge",
      "BESS Charge",
      "TNB",
      "Load",
    ];
    chart_range = [
      DARK_GREEN,
      LIGHT_GREEN,
      LIGHT_BLUE,
      LIGHT_LIGHT_BLUE,
      DARK_BLUE,
      ORANGE,
    ];
  }

  useEffect(() => {
    const chart = new Chart({
      container: container,
      autoFit: true,
    });

    chart
      .interval()
      .data(data_chart)
      .encode("x", "date")
      .encode("y", "value")
      .encode("color", "type")
      .scale("color", {
        domain: chart_domain,
        range: chart_range,
      })
      .transform({ type: "stackY" })
      .interaction("elementHighlight", { background: true })
      .axis("x", { title: "Time" })
      .axis("y", { title: "Energy (kWh)" })
      .interaction("tooltip", {
        render: (event, { title, items }) => {
          return `<div style="width:150px;">
            <h3 style="padding:0;margin:0">${title}</h3>
            <ul style="padding: 0; margin: 0; list-style: none;">
              ${items
                .map((d) => {
                  // Format the value with commas and 2 decimal places
                  const formattedValue = d.value
                    ? Number(d.value).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : d.value === 0
                    ? Number(d.value).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : d.value;
                  return `
                    <li style="display: flex; justify-content: space-between; align-items: center; margin: 4px 0;">
                      <span style="display: inline-flex; align-items: center;">
                        <span style="width: 8px; height: 8px; background-color: ${d.color}; border-radius: 50%; margin-right: 8px;"></span>
                        <span>${d.name}</span>
                      </span>
                      <span>${formattedValue}</span>
                    </li>`;
                })
                .join("")}
            </ul>
          </div>`;
        },
      });

    chart
      .line()
      .data(data_line)
      .encode("x", "date")
      .encode("y", "Load")
      .encode("color", "type")
      .encode("shape", "smooth")
      .style("stroke", ORANGE)
      .style("lineWidth", 3);

    document.getElementById(container).innerHTML = "";
    chartRef.current = chart;

    chart.render();

    window.onresize = () => {
      chart.render();
    };
  }, [container]);

  return <div style={{ width: "100%", height: "100%" }} id={container} />;
}

/*
 * TODO
 *
 * - how to set order of stacked bars (currently is based on the order of data)
 *
 */
function EnergyDemand() {
  const [
    siteSelect,
    setSiteSelect,
    siteChange,
    setSiteChange,
    isDarkMode,
    setIsDarkMode,
    subscribePpaBilling,
    subscribeCarbonManagement,
    subscribeAiPvDiagnosis,
    subscribeAiBess,
  ] = useOutletContext();

  const [isTrendingDataLoading, setIsTrendingDataLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [startDateSelection, setStartDateSelection] = useState(
    dayjs().subtract(6, "day")
  );
  const [endDateSelection, setEndDateSelection] = useState(dayjs());

  const [dateTypeSelection, setDateType] = useState(dateTypeOptions[0].value);

  const [energyDemandDownloadData, setEnergyDemandDownloadData] = useState([]);
  const [filteredEnergyDemandData, setFilteredEnergyDemandData] = useState([]);

  const [loadEnergyData, setLoadEnergyData] = useState([]);

  const onFilterClick = () => {
    if (startDateSelection > endDateSelection) {
      message.error("End date must be later than or same as start date.");
    } else {
      getEnergyDemandData();
    }
  };

  const filterData = (data) => {
    return setFilteredEnergyDemandData(
      filterDataByDateRange(data, startDateSelection, endDateSelection)
    );
  };

  const onExportClick = () => {
    if (energyDemandDownloadData.length < 1) {
      message.error("Please get the data first");
    } else {
      if (
        energyDemandDownloadData.data.length < 1 &&
        loadEnergyData.length < 1
      ) {
        message.error("No data to export");
      } else {
        saveEnergyDemandData();
      }
    }
  };

  /** API to get the energy demand data */
  function getEnergyDemandData() {
    setIsTrendingDataLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
        start_date: startDateSelection.format("YYYY-MM-DD HH:mm:ss"),
        end_date: endDateSelection.format("YYYY-MM-DD HH:mm:ss"),
        date_selection: dateTypeSelection,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        // filterData(data.data);
        setFilteredEnergyDemandData(data.data);
        setEnergyDemandDownloadData(data);
        setLoadEnergyData(data.load_data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/energyDemand/getEnergyDemandData`,
      cb,
      setIsTrendingDataLoading,
      "Get Energy Demand Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to save power demand data as excel */
  function saveEnergyDemandData() {
    setSaveLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        data: energyDemandDownloadData.data,
        load_data: loadEnergyData,
        siteName: energyDemandDownloadData.siteName,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        downloadEnergyDemandData(data.download_link);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + "/energyDemand/saveEnergyDemandData",
      cb,
      setSaveLoading,
      "Save Energy Demand Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function downloadEnergyDemandData(filename) {
    const downloadLink = document.createElement("a");
    downloadLink.href = BACKEND_URL + `/energyDemand/download/${filename}`;
    downloadLink.setAttribute("download", filename);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  useEffect(() => {
    setEnergyDemandDownloadData([]);
    setFilteredEnergyDemandData([]);
  }, [siteSelect]);

  return (
    <Analytics
      title={"Energy Demand Distribution"}
      contentComponent={
        filteredEnergyDemandData.length > 0 ? (
          <OverallEnergyChart
            data_chart={filteredEnergyDemandData}
            data_line={loadEnergyData}
            container={"overall-energy-chart"}
            subscribeAiBess={subscribeAiBess}
          />
        ) : (
          <Empty />
        )
      }
      isTrendingDataLoading={isTrendingDataLoading}
      filterComponent={
        <div className="analytics-picker-type-segmented">
          <Flex vertical={true} gap={"8px"}>
            <span>Graph Type Selection</span>
            <Segmented
              style={{ marginBottom: "12px" }}
              options={dateTypeOptions}
              onChange={(value) => {
                setDateType(value);
              }}
              value={dateTypeSelection}
            />
          </Flex>

          <VerticalDateFilter
            startDateSelection={startDateSelection}
            setStartDateSelection={setStartDateSelection}
            endDateSelection={endDateSelection}
            setEndDateSelection={setEndDateSelection}
            saveLoading={saveLoading}
            onFilterClick={onFilterClick}
            onExportClick={onExportClick}
            pickerType={dateTypeSelection}
          />
        </div>
      }
    />
  );
}

export default EnergyDemand;
