import { Col, Row, Skeleton, message, Flex } from "antd";
import { CustomTable } from "../common/table";
import { LabelValueUnitDisplayRow } from "../common/wordDisplay";
import DeviceCard from "./DeviceCard";
import { useEffect, useState } from "react";
import { fetchHelper } from "../../functions/fetch";
import { BACKEND_URL, MQTT_REFRESH_RATE } from "../../configuration";

function BessTable({ data }) {
  const bessTableColumns = [
    {
      title: "State of Charge",
      dataIndex: "soc",
      key: "soc",
    },
    {
      title: "State of Health",
      dataIndex: "soh",
      key: "soh",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Battery Operations",
      dataIndex: "batteryoperation",
      key: "batteryoperation",
    },
    {
      title: "Power",
      dataIndex: "power",
      key: "power",
    },
    {
      title: "Chargeable Energy",
      dataIndex: "chargeableenergy",
      key: "chargeableenergy",
    },
    {
      title: "Dischargeable Energy",
      dataIndex: "dischargeableenergy",
      key: "dischargeableenergy",
    },
  ];

  return (
    <CustomTable
      dataSource={data}
      columns={bessTableColumns}
      row_per_page={8}
      tableHeight={500}
      tableContainerStyleObject={{
        height: "80%",
        minWidth: "700px",
      }}
    />
  );
}

function BESSContent({
  systemData,
  tableData,
  bessCard,
  setOpenModal,
  isLoading,
  systemLastUpdate,
  deviceLastUpdate,
}) {
  return (
    <div className="system-col">
      {isLoading ? (
        <Skeleton active style={{ margin: "5px" }} />
      ) : (
        <Flex gap={"40px"} vertical>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                align: "center",
                marginBottom: "12px",
              }}
            >
              <h2 style={{ margin: "0px" }}>BESS System</h2>
              <span>Last Update: {systemLastUpdate}</span>
            </div>
            <div className="system-row">
              <div className="content-box">
                <LabelValueUnitDisplayRow
                  label={"Maximum Demand Reduced"}
                  value={
                    systemData?.maximumDemandReduced !== undefined
                      ? systemData.maximumDemandReduced
                      : "-"
                  }
                  unit={"kW"}
                />
                <LabelValueUnitDisplayRow
                  label={"Status"}
                  value={
                    systemData?.status !== undefined ? systemData.status : "-"
                  }
                  unit={""}
                />
                <LabelValueUnitDisplayRow
                  label={"Mode"}
                  value={systemData?.mode !== undefined ? systemData.mode : "-"}
                  unit={""}
                />
                <LabelValueUnitDisplayRow
                  label={"Grid Mode"}
                  value={
                    systemData?.gridMode !== undefined
                      ? systemData.gridMode
                      : "-"
                  }
                  unit={""}
                />
              </div>
              <div className="content-box">
                <LabelValueUnitDisplayRow
                  label={"Total Charging Power"}
                  value={
                    systemData?.totalChargingPower !== undefined
                      ? systemData.totalChargingPower
                      : "-"
                  }
                  unit={"kW"}
                />
                <LabelValueUnitDisplayRow
                  label={"Total Discharging Power"}
                  value={
                    systemData?.totalDischargingPower !== undefined
                      ? systemData.totalDischargingPower
                      : "-"
                  }
                  unit={"kW"}
                />
                <LabelValueUnitDisplayRow
                  label={"Dischargeable Energy"}
                  value={
                    systemData?.dischargeableEnergy !== undefined
                      ? systemData.dischargeableEnergy
                      : "-"
                  }
                  unit={"kWh"}
                />
                <LabelValueUnitDisplayRow
                  label={"Chargeable Energy"}
                  value={
                    systemData?.chargeableEnergy !== undefined
                      ? systemData.chargeableEnergy
                      : "-"
                  }
                  unit={"kWh"}
                />
              </div>
              <div className="content-box">{/* empty space */}</div>
            </div>
          </div>

          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                align: "center",
                marginBottom: "12px",
              }}
            >
              <h2 style={{ margin: "0px" }}>BESS Device Parameters</h2>
              <span>Last Update: {deviceLastUpdate}</span>
            </div>
            <div className="content-box">
              <div className="system-row power-meter-phases">
                {bessCard == true ? (
                  <Col span={24}>
                    <Row gutter={[18, 18]}>
                      {tableData?.map((d, index) => {
                        const key = `col-${index}`;
                        return (
                          <Col
                            key={key}
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}
                            xl={{ span: 8 }}
                            xxl={{ span: 6 }}
                          >
                            <DeviceCard data={d} setOpenModal={setOpenModal} />
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                ) : (
                  <BessTable data={tableData} setOpenModal={setOpenModal} />
                )}
                <div className="content-box">{/* empty space */}</div>
              </div>
            </div>
          </div>
        </Flex>
      )}
    </div>
  );
}

function BESS({
  deviceTabData,
  bessCard,
  handleChange,
  openModal,
  setOpenModal,
  token,
  siteSelect,
}) {
  const [bessLoading, setBessLoading] = useState(true);
  const [bessSystemData, setBessSystemData] = useState({});
  const [bessTableData, setBessTableData] = useState([]);

  const [systemLastUpdate, setSystemLastUpdate] = useState(null);
  const [deviceLastUpdate, setDeviceLastUpdate] = useState(null);

  /** API to call device mqtt data */
  function getBessMQTTData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setBessTableData(data.data.table);
        setBessSystemData(data.data.system);
        setDeviceLastUpdate(data.last_update);
        setSystemLastUpdate(data.last_update);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      BACKEND_URL + `/system/getSystemBESS/${siteSelect}`,
      cb,
      setBessLoading,
      "Get Bess Device MQTT Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    const timer = setInterval(() => {
      if (siteSelect) {
        getBessMQTTData();
      }
    }, MQTT_REFRESH_RATE * 1000);
    return () => {
      clearInterval(timer);
    };
  }, [siteSelect]);

  return (
    <>
      <BESSContent
        deviceTabData={deviceTabData}
        systemData={bessSystemData}
        tableData={bessTableData}
        bessCard={bessCard}
        setOpenModal={setOpenModal}
        isLoading={bessLoading}
        systemLastUpdate={systemLastUpdate}
        deviceLastUpdate={deviceLastUpdate}
      />
    </>
  );
}

export default BESS;
