import "../css/pages/SiteList.css";
import { useState, useEffect } from "react";
import { Card, Divider, Select, Image } from "antd";
import { placeholder_image, BACKEND_URL } from "../configuration";
import { FigureDisplay } from "../component/common/figureDisplay";
import { formatMoneyNumber, formatNumber } from "../functions/numberFormatting";
import { fetchHelper } from "../functions/fetch";
import { useNavigate, useOutletContext } from "react-router-dom";

/*
    TO-DO:
    1) warning
    2) income/savings
    3) display error
        - when failed to fetch
        - when error from fetch
*/

const STATUS_MENU = [
  {
    value: "All",
    label: "All",
    color: "black",
  },
  {
    value: "Normal",
    label: "Normal",
    color: "green",
  },
  {
    value: "Warning",
    label: "Warning",
    color: "red",
  },
  {
    value: "Offline",
    label: "Offline",
    color: "grey",
  },
];

/**
 * Component: Figure to display summary on total number sites with different status
 */
function SiteTypeSummary({ normal, warning, offline }) {
  return (
    <>
      <div className="flexbox-container-col">
        <div>{`Normal`}</div>
        <div>{`Warning`}</div>
        <div>{`Offline`}</div>
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <div>{`:`}</div>
        <div>{`:`}</div>
        <div>{`:`}</div>
      </div>
      <div
        className="flexbox-container-col"
        style={{ flexShrink: "0", flexGrow: "0" }}
      >
        <div>{`${normal} site(s)`}</div>
        <div>{`${warning} site(s)`}</div>
        <div>{`${offline} site(s)`}</div>
      </div>
    </>
  );
}

/**
 * Component: Display info of 1 site
 * display:
 *  left column: Image
 *  right column:
 *      - Site Long Name
 *      - Site Capacity
 *      - Site Generated Power
 *      - Site Status
 */
function SiteInfo({ id, name, capacity, power, status }) {
  const navigate = useNavigate();
  const [siteSelect, setSiteSelect, siteChange, setSiteChange] =
    useOutletContext();

  const onDivClick = () => {
    setSiteSelect(id);
    navigate("/dashboard");
  };

  const status_object = STATUS_MENU.find((item) => item.value === status);

  return (
    <div className="flexbox-container-row site-box">
      <Image
        width={150}
        height={150}
        src="error"
        fallback={placeholder_image}
        style={{ borderRadius: "10px", border: "1px solid #ccc" }}
      />
      <div className="flexbox-container-col" onClick={onDivClick}>
        <span style={{ fontWeight: "bold" }}>{name}</span>
        <span>{capacity} kWp</span>
        <span>{power} kWh</span>
        {status_object ? (
          <span style={{ color: status_object.color }}>
            {status_object.label}
          </span>
        ) : (
          <span>{status}</span>
        )}
      </div>
    </div>
  );
}

/**
 * Component: Site List Page
 */
function SiteList() {
  const [isLoading, setIsLoading] = useState(true);

  const [siteSummary, setSiteSummary] = useState({
    totalSites: 0,
    normalSites: 0,
    warningSites: 0,
    offlineSites: 0,
    totalCapacity: 0,
    totalPower: 0,
    totalIncome: 0,
  });

  const [siteData, setSiteData] = useState([]);
  const [statusSelection, setStatusSelection] = useState("All");

  function getSiteLists() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setIsLoading(false);
      setSiteData(data.site_lists.all_site_lists);
      setSiteSummary({
        totalSites: data.site_lists.totalSites,
        normalSites: data.site_lists.normalSites,
        warningSites: data.site_lists.warningSites,
        offlineSites: data.site_lists.offlineSites,
        totalCapacity: data.site_lists.totalCapacity,
        totalPower: data.site_lists.totalPower,
        totalIncome: data.site_lists.totalIncome,
      });
    };

    fetchHelper(
      `${BACKEND_URL}/siteLists/getSiteLists`,
      cb,
      () => {},
      "Get Site Lists",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    getSiteLists();
  }, []);

  let filteredSiteData =
    statusSelection === "All"
      ? siteData
      : siteData.filter((site) => site.Status === statusSelection);

  return (
    <>
      <Card
        title={"Site List"}
        className="context-card"
        style={{ overflowY: "hidden", margin: "10px" }}
        bodyStyle={{
          height: "95%",
          // overflowY: "scroll"
        }}
        loading={isLoading}
      >
        <div className="flexbox-container-col">
          <div className="grid-container">
            <div className="flexbox-container-row summary-box-margin center-alignment-both-axis">
              <FigureDisplay
                figure={siteSummary.totalSites}
                unit={""}
                caption={"Sites"}
              />
              <SiteTypeSummary
                normal={siteSummary.normalSites}
                warning={siteSummary.warningSites}
                offline={siteSummary.offlineSites}
              />
            </div>

            <FigureDisplay
              figure={formatNumber(siteSummary.totalCapacity)}
              unit={"kWp"}
              caption={"Total Capacity"}
            />
            <FigureDisplay
              figure={formatNumber(siteSummary.totalPower)}
              unit={"kWh"}
              caption={"Generated This Month"}
            />
            <FigureDisplay
              figure={formatMoneyNumber(siteSummary.totalIncome)}
              unit={"MYR"}
              caption={"Income/Savings"}
            />
          </div>

          <Divider style={{ marginBottom: "0px" }} />

          <div
            id="status-selection-bar"
            className="flexbox-container-row center-alignment-cross-axis"
          >
            <span id="status-label"> Status: </span>
            <Select
              defaultValue={statusSelection}
              style={{
                width: 120,
              }}
              onChange={(value) => setStatusSelection(value)}
              options={STATUS_MENU}
            />
          </div>

          <div
            id="site-container"
            className="flexbox-container-row wrap-container"
          >
            {filteredSiteData.map((data) => (
              <SiteInfo
                key={data.SiteName}
                id={data.ID}
                name={data.SiteName}
                capacity={data.RatingInkWp}
                power={data.Energy}
                status={data.Status}
              />
            ))}
          </div>
        </div>
      </Card>
    </>
  );
}

export default SiteList;
