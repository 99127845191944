import "../css/pages/Login.css";
import { Row, Card, Image, Layout, Button, Form, Input, message } from "antd";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/REVEAI.png";
import { BACKEND_URL } from "../configuration";
import { fetchHelper } from "../functions/fetch";

/**
 * TO-DO
 *
 * check usage of sessionStorage and localStorage
 */

const { Content, Footer } = Layout;

const login_url = BACKEND_URL + "/users/login";
const checkLogin_url = BACKEND_URL + "/users/checkUser";

function Login() {
  let navigate = useNavigate();

  let [login_processing, setLoginProcessing] = useState(false);
  let [loginError, setLoginError] = useState({
    status: false,
    msg: "",
  });

  const onFinish = (values) => {
    login(values);
  };

  const onFinishFailed = (errorInfo) => {
    setLoginProcessing(false);
  };

  const showLoading = () => {
    setLoginProcessing(true);
  };

  function login(user_inputs) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify(user_inputs),
    };

    const fetchErrorCallback = () => {
      setLoginError({
        status: true,
        msg: "Server Error",
      });
    };
    const fetchDataErrorCallback = (data) => {
      message.error(data.message);

      setLoginError({
        status: true,
        msg: data.error,
      });
    };
    const fetchSucessCallback = (data) => {
      setLoginError({
        status: false,
        msg: "",
      });
      navigate("/dashboard", { replace: true });
    };
    fetchHelper(
      login_url,
      fetchSucessCallback,
      setLoginProcessing,
      "Login",
      fetchErrorCallback,
      fetchDataErrorCallback,
      requestOptions,
      false
    );
  }

  const checkUserLogin = (callback) => {
    callback();

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    fetch(checkLogin_url, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          navigate("/dashboard");
          callback();
        } else {
          navigate("/login");
        }
      })
      .catch((e) => {
        navigate("/login");
        console.log(e);
      });
  };

  useEffect(() => {
    checkUserLogin(() => {});
  }, []);

  return (
    <>
      <Layout style={{ height: "100svh", background: "transparent" }}>
        <Content
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card id="login-card" hoverable>
            <div id="logo-image-div">
              <img src={Logo} style={{ width: "90%" }} alt="logo"></img>
            </div>
            <Form
              id="login-form"
              name="login"
              requiredMark={false}
              labelCol={{ span: 7 }}
              labelAlign="left"
              wrapperCol={{ offset: 0, span: 18 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              loading={String(login_processing)}
              onSubmitCapture={showLoading}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Password"
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item wrapperCol={{ span: 24 }}>
                <Button
                  id="login-button"
                  block="true"
                  type="primary"
                  htmlType="submit"
                  loading={login_processing}
                >
                  Log In
                </Button>
              </Form.Item>
              {loginError.status ? (
                <Row id="error-bar">{loginError.msg}</Row>
              ) : (
                <></>
              )}
            </Form>
          </Card>
        </Content>
      </Layout>
    </>
  );
}

export default Login;
