import { useState, useCallback } from "react";
import { fetchHelper } from "../fetch";
import { BACKEND_URL } from "../../configuration";
import { message } from "antd";

function useGetAlarmList() {

  const [ alarmList, setAlarmList ] = useState([])
  const [ alarmListLoading, setAlarmListLoading ] = useState(true)

  const getAlarmList = useCallback((startDateSelection, endDateSelection, site_id) => {
    setAlarmListLoading(true)
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        'start_date': startDateSelection,
        'end_date': endDateSelection,
        'site_id': site_id
      }),
    };
  
    const cb = (data) => {
      if (data.status) {
        setAlarmList(data.alarm_list)
        setAlarmListLoading(false)
      } else {
        message.error(data.message);
        setAlarmListLoading(false)
      }
    };
  
    fetchHelper(
      BACKEND_URL + "/alarm/getAlarmList",
      cb,
      () => {},
      "get alarm List",
      () => {},
      () => {},
      requestOptions,
      true
    );  }, [fetchHelper]);

  return { getAlarmList, alarmList, alarmListLoading };
}

export default useGetAlarmList;
