export function formatMoneyNumber(number) {
  if (isNaN(number)) {
    return "-";
  }

  // Round and pad decimals to 2 places
  const roundedNumber = Number(number).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Add commas for every thousands
  const formattedNumber = roundedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedNumber;
}

export function formatNumber(number) {
  if (isNaN(number)) {
    return "-";
  }

  // Add commas for every thousands
  const formattedNumber = `${number}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return formattedNumber;
}
