import { Col, Flex, Image, Modal, Row, Space, Tag, Typography } from "antd";
import PM5000 from "../../assets/PM5000-power-meter.jpeg";
const { Title, Text } = Typography;

function SiteDetails({ siteDetailsData }) {
  return (
    <div
      style={{
        minHeight: "calc(60vh - 62px)",
        paddingTop: 12,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Row gutter={[24, 24]} style={{ overflow: "hidden" }}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={{ flex: "300px" }}>
          <Flex justify="center" align="center">
            <Image width={200} src={PM5000} />
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={6}>
          <Flex vertical gap={12}>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Site Name
              </Title>
              <Text>{siteDetailsData.SiteName}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Site Short Name
              </Title>
              <Text>{siteDetailsData.SiteShortName}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Address
              </Title>
              <Text>{siteDetailsData.Address}</Text>
            </div>
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={6}>
          <Flex vertical gap={12}>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Connection Type
              </Title>
              <Text>{siteDetailsData.ConnectionType}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Latitude
              </Title>
              <Text>{siteDetailsData.Latitude}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Longitude
              </Title>
              <Text>{siteDetailsData.Longitude}</Text>
            </div>
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={6}>
          <Flex vertical gap={12}>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Status
              </Title>
              <Text>{siteDetailsData.Status}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Created At
              </Title>
              <Text>{siteDetailsData.CreateDateTime}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Organisation
              </Title>
              <Text>{siteDetailsData.Organisation}</Text>
            </div>
          </Flex>
        </Col>
        <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={6}>
          <Flex vertical gap={12}>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                PIC
              </Title>
              <Text>{siteDetailsData.Name}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Tariff Type
              </Title>
              <Text>{siteDetailsData.TariffFullName}</Text>
            </div>
            <div>
              <Title level={4} style={{ marginTop: 0 }}>
                Capacity In kWp
              </Title>
              <Text>{siteDetailsData.CapacityInkWp}</Text>
            </div>
          </Flex>
        </Col>
      </Row>

      <div style={{ width: "200px" }} />
    </div>
  );
}

function SiteDetailsModal({ modalOpen, modalClose, siteDetails }) {
  return (
    <Modal
      open={modalOpen}
      onCancel={() => modalClose()}
      footer={null}
      style={{ maxWidth: "90vw", minWidth: "70vw", height: "80vh" }}
    >
      <div style={{ marginTop: "20px" }}>
        <SiteDetails siteDetailsData={siteDetails} />
      </div>
    </Modal>
  );
}

export default SiteDetailsModal;
