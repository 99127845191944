import "../../css/component/SystemReport/InverterReportContent.css";
import { Card, Divider, Select, Skeleton, message } from "antd";
import { useState, useEffect } from "react";
import { FigureDisplay, DoubleFigureDisplay } from "../common/figureDisplay";
import { LabelValueUnitDisplayRow } from "../common/wordDisplay";
import { BACKEND_URL, MQTT_REFRESH_RATE } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { useOutletContext } from "react-router-dom";

const STATUS_MENU = [
  {
    value: "all",
    label: "All",
    color: "black",
  },
  {
    value: "Online",
    label: "Online",
    color: "green",
  },
  {
    value: "Warning",
    label: "Warning",
    color: "red",
  },
  {
    value: "Offline",
    label: "Offline",
    color: "grey",
  },
];

function InverterStatusSummary({ normal, alarm, offline }) {
  return (
    <>
      <div className="flexbox-container-col">
        <div>{`Normal`}</div>
        <div>{`Alarm`}</div>
        <div>{`Offline`}</div>
      </div>
      <div
        className="flexbox-container-col"
        style={{ margin: "0px 10px 0px 5px" }}
      >
        <div>{`:`}</div>
        <div>{`:`}</div>
        <div>{`:`}</div>
      </div>
      <div
        className="flexbox-container-col"
        style={{ flexShrink: "0", flexGrow: "0" }}
      >
        <div>{`${normal} units(s)`}</div>
        <div>{`${alarm} units(s)`}</div>
        <div>{`${offline} units(s)`}</div>
      </div>
    </>
  );
}

function InverterInfo({
  name,
  status,
  activePower,
  reactivePower,
  dcPower,
  powerFactor,
  dailyGeneration,
}) {
  const status_object = STATUS_MENU.find((item) => item.value === status);

  return (
    <Card
      title={
        <div className="inverter-info-card-title">
          <div
            className="circle"
            style={{ backgroundColor: status_object.color }}
          >
            {" "}
          </div>
          <span>{name}</span>
        </div>
      }
      className="inverter-info-card"
      bodyStyle={{ display: "flex", flexDirection: "column", padding: "12px" }}
      size="small"
    >
      <LabelValueUnitDisplayRow
        label={"Active Power"}
        value={
          activePower !== "-"
            ? `${activePower.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : `${activePower}`
        }
        unit={"kW"}
      />
      <LabelValueUnitDisplayRow
        label={"Reactive Power"}
        value={
          reactivePower !== "-"
            ? `${activePower.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : `${reactivePower}`
        }
        unit={"kVAR"}
      />
      <LabelValueUnitDisplayRow
        label={"DC Power"}
        value={
          dcPower !== "-"
            ? `${activePower.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : `${dcPower}`
        }
        unit={"kW"}
      />
      <LabelValueUnitDisplayRow
        label={"Power Factor"}
        value={
          powerFactor !== "-"
            ? `${powerFactor.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : `${powerFactor}`
        }
        unit={""}
      />
      <LabelValueUnitDisplayRow
        label={"Daily Generation"}
        value={
          dailyGeneration !== "-"
            ? `${dailyGeneration.toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : `${dailyGeneration}`
        }
        unit={"kWh"}
      />
    </Card>
  );
}

function InverterReportContent() {
  const [firstLoading, setFirstLoading] = useState(true);
  const [siteSelect] = useOutletContext();

  const [statusSelection, setStatusSelection] = useState("all");

  const [inverterSummary, setInverterSummary] = useState({
    totalInverters: 0,
    normalInverters: 0,
    alarmInverters: 0,
    offlineInverters: 0,
    activePower: 0,
    activePowerPeak: 0,
    generatedToday: 0,
    generatedThisMonth: 0,
  });
  const [inverterData, setInverterData] = useState([]);

  let filteredInverterData =
    statusSelection === "all"
      ? inverterData
      : inverterData.filter((inverter) => inverter.status === statusSelection);

  function getInverterReportData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setInverterData(data.df_inverter);
        setInverterSummary(data.df_inverter_summary[0]);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/inverter_report/getInverterReportData`,
      cb,
      setFirstLoading,
      "Get Inverter Report Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getInverterReportData();

      const timer = setInterval(() => {
        getInverterReportData();
      }, MQTT_REFRESH_RATE * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelect]);

  return (
    <>
      {firstLoading ? (
        <Skeleton
          active={true}
          paragraph={{ rows: 10 }}
          style={{ padding: "2rem", maxWidth: "70%", margin: "auto" }}
        />
      ) : (
        <div className="flexbox-container-col">
          <div className="grid-container">
            <div className="flexbox-container-row summary-box-margin center-alignment-both-axis">
              <FigureDisplay
                figure={inverterSummary.totalInverters}
                unit={""}
                caption={"Inverters"}
              />
              <InverterStatusSummary
                normal={inverterSummary.normalInverters}
                alarm={inverterSummary.alarmInverters}
                offline={inverterSummary.offlineInverters}
              />
            </div>

            <DoubleFigureDisplay
              figure1={
                inverterSummary.activePower !== "-"
                  ? `${inverterSummary.activePower.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : `${inverterSummary.activePower}`
              }
              unit1={"kW"}
              figure2={
                inverterSummary.activePowerPeak !== "-"
                  ? `${inverterSummary.activePowerPeak.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : `${inverterSummary.activePowerPeak}`
              }
              unit2={"kWp"}
              caption={"Current Active Power"}
            />
            <FigureDisplay
              figure={
                inverterSummary.generatedToday !== "-"
                  ? `${inverterSummary.generatedToday.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : `${inverterSummary.generatedToday}`
              }
              unit={"kWh"}
              caption={"Generated Today"}
            />
            <FigureDisplay
              figure={
                inverterSummary.generatedThisMonth !== "-"
                  ? `${inverterSummary.generatedThisMonth.toLocaleString(
                      "en-US",
                      { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                    )}`
                  : `${inverterSummary.generatedThisMonth}`
              }
              unit={"kWh"}
              caption={"Generated This Month"}
            />
          </div>

          <Divider style={{ marginBottom: "0px" }} />

          <div
            id="status-selection-bar"
            className="flexbox-container-row center-alignment-cross-axis"
          >
            <span id="status-label"> Status: </span>
            <Select
              defaultValue={statusSelection}
              style={{
                width: 120,
              }}
              onChange={(value) => setStatusSelection(value)}
              options={STATUS_MENU}
            />
          </div>

          <div className="inverter-content-container">
            {inverterData.length > 0 ? (
              filteredInverterData.map((data) => (
                <InverterInfo
                  key={data.name}
                  name={data.name}
                  status={data.status}
                  activePower={data.activePower}
                  reactivePower={data.reactivePower}
                  dcPower={data.dcPower}
                  powerFactor={data.powerFactor}
                  dailyGeneration={data.dailyGeneration}
                />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default InverterReportContent;
