import "../css/pages/WorkInProgress.css";
import "../css/pages/AssetLayout.css";

import { Card } from "antd";

function WorkInProgress() {
  return (
    <>
      <Card className="context-card work-in-progress-page">
        <h2 className="work-in-progress">Work In Progress</h2>
      </Card>
    </>
  );
}

export default WorkInProgress;
