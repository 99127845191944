import { Button, Card, Modal, Tooltip, message } from "antd";
import { useOutletContext } from "react-router-dom";
import { useState } from "react";
import { Line } from "@ant-design/plots";
import { CustomTable } from "../common/table";
import { fetchHelper } from "../../functions/fetch";
import { BACKEND_URL } from "../../configuration";
import { useEffect } from "react";

function ActiveTable({ tableData, onViewClick, onDismissClick }) {
  const truncateText = (text, limit) => {
    const words = text.split("");
    const check = words.length > limit;
    return check ? words.slice(0, limit).join("") + "..." : text;
  };
  const activeTableColumns = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Device",
      dataIndex: "Device",
      key: "Device",
    },
    {
      title: "String No.",
      dataIndex: "StringNo",
      key: "StringNo",
    },
    {
      title: "Description",
      dataIndex: "Description",
      key: "Description",
      render: (text) => (
        <Tooltip title={text}>{truncateText(text, 20)}</Tooltip>
      ),
    },
    {
      title: "Time",
      dataIndex: "Time",
      key: "Time",
    },
    {
      title: "Action",
      dataIndex: "Action",
      key: "Action",
      render: (_, record, index) => (
        <div style={{ display: "flex", gap: "10px" }}>
          <Button
            onClick={() => {
              onViewClick(record);
            }}
          >
            View
          </Button>
          <Button
            onClick={() => {
              onDismissClick(record);
            }}
          >
            Dismiss
          </Button>
        </div>
      ),
    },
  ];

  return (
    <CustomTable
      dataSource={tableData}
      columns={activeTableColumns}
      row_per_page={8}
      tableHeight={500}
      tableContainerStyleObject={{ height: "80%", minWidth: "700px" }}
    />
  );
}

function ActiveModal({ modelOpen, modalClose, isLoading, title, chart_data }) {
  const lineChartConfig = {
    autoFit: true,
    data: chart_data?.length ? chart_data : [],
    xField: "time",
    yField: "data",
    seriesField: "type",

    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    point: false,
    xAxis: {
      title: {
        text: "Time",
        style: {
          fontSize: 16,
        },
      },
    },
    yAxis: {
      title: {
        text: "Parameter",
        style: {
          fontSize: 16,
        },
      },
    },
    color: ["grey", "#01063B", "#20C092"],
  };

  return (
    <>
      <Modal
        open={modelOpen}
        onCancel={() => modalClose()}
        footer={null}
        style={{ maxWidth: "90vw", minWidth: "70vw", height: "80vh" }}
      >
        <Card
          title={title ? title : ""}
          style={{ marginTop: "30px" }}
          loading={isLoading}
        >
          <Line {...lineChartConfig} />
        </Card>
      </Modal>
    </>
  );
}

function Active() {
  const [isLoading, setIsLoading] = useState(false);
  const [activeModel, setActiveModal] = useState(false);
  const [activeTableData, setActiveTableData] = useState([]);
  const [activeChartData, setActiveChartData] = useState([]);
  const [siteSelect, setSiteSelect, siteChange, setSiteChange] =
    useOutletContext();
  const [viewRecordId, setViewRecordId] = useState(0);

  const onViewClick = (record) => {
    setActiveModal(true);
    setViewRecordId(record.ID);
    getChartData(record.ID);
  };

  const onModalClose = () => {
    setActiveModal(false);
  };

  const onDismissClick = (record) => {
    dismissActiveRecords(record.ID);
    getActiveRecords();
  };

  function getActiveRecords() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data?.status) {
        setActiveTableData(data?.data ? data.data : []);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/pv_condition/getAllActive`,
      cb,
      setIsLoading,
      "Get Alarm Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getChartData(idx) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        id: idx,
      }),
    };

    const cb = (data) => {
      if (data?.status) {
        setActiveChartData(data?.data ? data.data : []);
      } else {
        message.error(data.message);
      }
    };

    if (idx) {
      setIsLoading(true);
      fetchHelper(
        `${BACKEND_URL}/pv_condition/getChartData`,
        cb,
        setIsLoading,
        "Get Chart Data",
        () => {},
        () => {},
        requestOptions,
        true
      );
    }
  }

  function dismissActiveRecords(idx) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        id: idx,
      }),
    };

    const cb = (data) => {
      if (data?.status) {
        message.success(data.message);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/pv_condition/dismissRecord`,
      cb,
      setIsLoading,
      "Get Alarm Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  let modal_title = "";
  if (activeChartData?.length) {
    if (viewRecordId > 0) {
      const viewed_record = activeTableData.find(
        (element) => element.ID === viewRecordId
      );
      modal_title = viewed_record.Device + " - " + viewed_record.Parameter;
    }
  }

  useEffect(() => {
    if (siteSelect !== undefined) {
      getActiveRecords();
    }
  }, [siteSelect]);

  useEffect(() => {
    getChartData(viewRecordId);
  }, [viewRecordId]);

  return (
    <>
      <ActiveTable
        tableData={activeTableData}
        onViewClick={onViewClick}
        onDismissClick={onDismissClick}
      />
      <ActiveModal
        modelOpen={activeModel}
        modalClose={onModalClose}
        isLoading={isLoading}
        title={modal_title}
        chart_data={activeChartData}
      />
    </>
  );
}

export default Active;
