import {
  Button,
  Form,
  Input,
  Select,
  Divider,
  Tooltip,
  Spin,
  InputNumber,
  message,
  Popconfirm,
} from "antd";
import { CustomTable } from "../common/table";
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined,
  StopOutlined,
  NotificationOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { ModalForm } from "../common/modalForm";
import { useTableSearch } from "../common/tableSearch";
import { useEffect, useState } from "react";
import { FORM_REQUIRED, BACKEND_URL } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import DeviceConfigurationModal from "./DeviceConfigurationModal";
import SiteDetailsModal from "./SiteDetailsModal";
import AlarmNotificationConfigModal from "./AlarmNotificationConfigModal";

/**
 * Component: Site Form Body
 * @returns Form.items
 */
function SiteFormBody({
  tariffTypeSelect,
  setTariffTypeSelect,
  tariffTypeList,
  setTariffTypeList,
  connectionTypeSelect,
  setConnectionTypeSelect,
  connectionTypeList,
  disabled,
  messageSiteName,
  messageSiteShortName,
  modalType,
  checkSiteName,
}) {
  const [validatorSiteName, setValidatorSiteName] = useState(false);

  return (
    <>
      <Form.Item
        label="Site Name"
        name="SiteName"
        rules={
          modalType != "Edit"
            ? [
                {
                  required: FORM_REQUIRED,
                  message: "Site Name cannot be empty.",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    checkSiteName(value).then((data) => {
                      setValidatorSiteName(data.is_unique);
                    });
                    if (validatorSiteName) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Site name already exist.")
                    );
                  },
                }),
              ]
            : []
        }
        tooltip={messageSiteName}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Site Short Name"
        name="SiteShortName"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Site Short Name cannot be empty.",
          },
        ]}
        tooltip={messageSiteShortName}
      >
        <Input disabled={disabled} />
      </Form.Item>

      <Form.Item
        label="Address"
        name="Address"
        rules={[
          { required: FORM_REQUIRED, message: "Address cannot be empty." },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Latitude"
        name="Latitude"
        rules={[
          { required: FORM_REQUIRED, message: "Latitude cannot be empty." },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Longitude"
        name="Longitude"
        rules={[
          { required: FORM_REQUIRED, message: "Longitude cannot be empty." },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Connection Type"
        name="ConnectionTypeId"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Connection Type cannot be empty.",
          },
        ]}
      >
        <Select
          value={connectionTypeSelect}
          options={connectionTypeList}
          onChange={(value, option) => {
            setConnectionTypeSelect(value);
          }}
        />
      </Form.Item>

      <Form.Item
        label="Tariff Type"
        name="TariffTypeId"
        rules={[
          { required: FORM_REQUIRED, message: "Tariff Type cannot be empty." },
        ]}
      >
        <Select
          value={tariffTypeSelect}
          options={tariffTypeList}
          onChange={(value, option) => {
            setTariffTypeSelect(value);
          }}
        />
      </Form.Item>

      <Form.Item
        label="Plant Capacity"
        name="CapacityInkWp"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Plant capacity cannot be empty.",
          },
        ]}
      >
        <InputNumber style={{ width: "100%" }} addonAfter="kWp" />
      </Form.Item>

      <Divider />

      <Form.Item
        label="PIC Name"
        name="Name"
        rules={[
          { required: FORM_REQUIRED, message: "PIC Name cannot be empty." },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Contact Number"
        name="Contact"
        rules={[
          {
            required: FORM_REQUIRED,
            message: "Contact Number cannot be empty.",
          },
          {
            pattern: /^\d{7,20}$/,
            message:
              "Please enter a valid mobile number with correct length and without any symbols.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="Email"
        rules={[
          { required: FORM_REQUIRED, message: "Email cannot be empty." },
          {
            type: "email",
            message: "Please input a valid E-mail.",
          },
        ]}
      >
        <Input />
      </Form.Item>
    </>
  );
}

/**
 * Component: Site Edit Form within a Model
 *
 * This modal form is to edit existing site
 */
function SiteEditModalForm({
  modalOpen,
  closeModal,
  initialValues,
  onSubmit,
  tariffTypeSelect,
  setTariffTypeSelect,
  tariffTypeList,
  setTariffTypeList,
  connectionTypeSelect,
  setConnectionTypeSelect,
  connectionTypeList,
  setConnectionTypeList,
  editLoading,
}) {
  const [siteForm] = Form.useForm();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      modalTitle={"Edit Site"}
      formObject={siteForm}
      formName={"siteInfoForm"}
      formBody={
        editLoading ? (
          <Spin
            style={{ width: "100%" }}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 80,
                  color: "#000038",
                }}
                spin
              />
            }
          />
        ) : (
          <SiteFormBody
            tariffTypeSelect={tariffTypeSelect}
            setTariffTypeSelect={setTariffTypeSelect}
            tariffTypeList={tariffTypeList}
            setTariffTypeList={setTariffTypeList}
            connectionTypeSelect={connectionTypeSelect}
            setConnectionTypeSelect={setConnectionTypeSelect}
            connectionTypeList={connectionTypeList}
            setConnectionTypeList={setConnectionTypeList}
            disabled={true}
            messageSiteName="If you wish to change the site name, please delete the site first and then add a new site."
            messageSiteShortName="If you wish to change the site short name, please delete the site first and then add a new site."
            modalType={"Edit"}
          />
        )
      }
      formIntitialValues={initialValues}
      formSubmit={onSubmit}
    />
  );
}

/**
 * Component: Site Form within a Model
 *
 * This modal form is for adding new site
 */
function SiteModalForm({
  modalOpen,
  closeModal,
  onSubmit,
  tariffTypeSelect,
  setTariffTypeSelect,
  tariffTypeList,
  setTariffTypeList,
  connectionTypeSelect,
  setConnectionTypeSelect,
  connectionTypeList,
  setConnectionTypeList,
  checkSiteName,
}) {
  const [siteForm] = Form.useForm();

  return (
    <ModalForm
      modalOpen={modalOpen}
      closeModal={closeModal}
      modalTitle={"Add Site"}
      formObject={siteForm}
      formName={"siteInfoForm"}
      formBody={
        <SiteFormBody
          tariffTypeSelect={tariffTypeSelect}
          setTariffTypeSelect={setTariffTypeSelect}
          tariffTypeList={tariffTypeList}
          setTariffTypeList={setTariffTypeList}
          connectionTypeSelect={connectionTypeSelect}
          setConnectionTypeSelect={setConnectionTypeSelect}
          connectionTypeList={connectionTypeList}
          setConnectionTypeList={setConnectionTypeList}
          disabled={false}
          messageSiteName="Please enter the site name carefully as you cannot change the site name once it is created."
          messageSiteShortName="Please enter the site short name carefully as you cannot change the site short name once it is created."
          modalType={"Add"}
          checkSiteName={checkSiteName}
        />
      }
      formSubmit={onSubmit}
    />
  );
}

/**
 * Component: Site Management Table
 */
function SiteManagementTable({
  tableData,
  onEditClick,
  onDeleteClick,
  tariffTypeSelect,
  setTariffTypeSelect,
  tariffTypeList,
  setTariffTypeList,
  connectionTypeSelect,
  setConnectionTypeSelect,
  connectionTypeList,
  setConnectionTypeList,
  onConfigurationModalOpen,
  onSiteDetailsModalOpen,
  onAlarmNotificationConfigModalOpen,
}) {
  const { getColumnSearchProps } = useTableSearch();

  // A map to map the value and label of tariffTypeList so that correct label is shown in the table
  const tariffTypeMap = {};

  // Populate tariffTypeMap if it is not null or undefined
  if (tariffTypeList) {
    Object.keys(tariffTypeList).forEach((key) => {
      tariffTypeMap[tariffTypeList[key].value] = tariffTypeList[key].label;
    });
  }

  const siteTableColumns = [
    {
      title: "No.",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Site Name",
      dataIndex: "SiteName",
      key: "SiteName",
      ...getColumnSearchProps("SiteName"),
    },
    // {
    //   title: "Tariff Type",
    //   dataIndex: "TariffTypeId",
    //   key: "TariffTypeId",
    //   // The render function looks up the TariffTypeId in tariffTypeMap.
    //   // If it finds a match, it returns the corresponding value from deviceTypeMap. If not, it returns the DeviceTypeId itself.
    //   render: (TariffTypeId) => tariffTypeMap[TariffTypeId] || TariffTypeId,
    // },
    {
      title: "Status",
      dataIndex: "SiteManagementStatus",
      key: "SiteManagementStatus",
    },
    {
      title: "Description",
      dataIndex: "SiteManagementDescription",
      key: "SiteManagementDescription",
    },

    {
      title: "Configuration",
      dataIndex: "configuration",
      key: "configuration",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-start" }}
        >
          <Tooltip title="Site Details">
            <Button
              icon={<EyeOutlined />}
              onClick={() => {
                onSiteDetailsModalOpen(record);
              }}
              disabled={record.IsRemoved != true ? false : true}
            />
          </Tooltip>

          <Tooltip title="Device Configuration">
            <Button
              icon={<SettingOutlined />}
              onClick={() => onConfigurationModalOpen(record.ID)}
              disabled={record.IsRemoved != true ? false : true}
            />
          </Tooltip>

          <Tooltip title="Notification Setting">
            <Button
              icon={<NotificationOutlined />}
              onClick={() => {
                onAlarmNotificationConfigModalOpen(record.ID);
              }}
              disabled={record.IsRemoved != true ? false : true}
            />
          </Tooltip>
        </div>
      ),
    },
    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      render: (_, record, index) => (
        <div
          style={{ display: "flex", gap: "5px", justifyContent: "flex-end" }}
        >
          {record.IsRemoved == true ? (
            <h4
              style={{
                margin: "0px",
                marginTop: "-2px",
                padding: "0px 10px",
                color: "red",
              }}
            >
              <StopOutlined />
            </h4>
          ) : (
            ""
          )}
          <Button
            type="text"
            icon={<EditOutlined />}
            onClick={() => onEditClick(record.ID, "siteManagementTab")}
            disabled={record.IsRemoved != true ? false : true}
          />
          {record.IsRemoved != true ? (
            <Popconfirm
              title="Delete Site"
              description={"Are you sure to delete this site?"}
              onConfirm={() => {
                onDeleteClick(record.ID, "siteManagementTab");
              }}
              onCancel={() => {
                message.success("Action is cancelled");
              }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="text" icon={<DeleteOutlined />} />
            </Popconfirm>
          ) : (
            ""
          )}
        </div>
      ),
    },
  ];

  return (
    <CustomTable
      dataSource={tableData}
      columns={siteTableColumns}
      row_per_page={8} // to fit the screen when at 100%
      tableHeight={500} // to fit the screen when at 100%
      tableContainerStyleObject={{ height: "80%", minWidth: "700px" }}
    />
  );
}

function SiteManagement({
  tableData,
  onEditClick,
  onDeleteClick,
  modalOpen,
  editModalOpen,
  closeModal,
  initialValues,
  onAddSubmit,
  onEditSubmit,
  checkSiteName,
  editLoading,
}) {
  // tariff type selection
  const [tariffTypeSelect, setTariffTypeSelect] = useState();
  const [tariffTypeList, setTariffTypeList] = useState([]);

  // connection type selection
  const [connectionTypeSelect, setConnectionTypeSelect] = useState();
  const [connectionTypeList, setConnectionTypeList] = useState([]);

  const [siteID, setSiteID] = useState();
  const [siteDetails, setSiteDetails] = useState({});

  // Configuration and Details Modal
  const [configurationModal, setConfigurationModal] = useState(false);
  const [siteDetailsModal, setSiteDetailsModal] = useState(false);
  const [alarmNotificationConfigModal, setAlarmNotificationConfigModal] =
    useState(false);

  const onConfigurationModalOpen = (id) => {
    setConfigurationModal(true);
    setSiteID(id);
  };

  const onConfigurationModalClose = () => setConfigurationModal(false);
  const onSiteDetailsModalOpen = (data) => {
    setSiteDetailsModal(true);
    setSiteDetails(data);
  };
  const onSiteDetailsModalClose = () => setSiteDetailsModal(false);

  const onAlarmNotificationConfigModalOpen = (id) => {
    setAlarmNotificationConfigModal(true);
    setSiteID(id);
  };
  const onAlarmNotificationConfigModalClose = () =>
    setAlarmNotificationConfigModal(false);

  /** API to get all the site tariff type */
  function getSiteTariffType() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setTariffTypeList(data.tariff_type);
    };

    fetchHelper(
      `${BACKEND_URL}/site_management/getSiteTariffType`,
      cb,
      () => {},
      "Get Site Tariff Type",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get all the site connection type */
  function getSiteConnectionType() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setConnectionTypeList(data.connection_type);
    };

    fetchHelper(
      `${BACKEND_URL}/site_management/getSiteConnectionType`,
      cb,
      () => {},
      "Get Site Connection Type",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    getSiteTariffType();
    getSiteConnectionType();
  }, []);

  return (
    <>
      <SiteManagementTable
        tableData={tableData}
        onConfigurationModalOpen={onConfigurationModalOpen}
        onSiteDetailsModalOpen={onSiteDetailsModalOpen}
        onAlarmNotificationConfigModalOpen={onAlarmNotificationConfigModalOpen}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        tariffTypeSelect={tariffTypeSelect}
        setTariffTypeSelect={setTariffTypeSelect}
        tariffTypeList={tariffTypeList}
        setTariffTypeList={setTariffTypeList}
        connectionTypeSelect={connectionTypeSelect}
        setConnectionTypeSelect={setConnectionTypeSelect}
        connectionTypeList={connectionTypeList}
        setConnectionTypeList={setConnectionTypeList}
      />
      <SiteModalForm
        modalOpen={modalOpen}
        closeModal={closeModal}
        onSubmit={onAddSubmit}
        tariffTypeSelect={tariffTypeSelect}
        setTariffTypeSelect={setTariffTypeSelect}
        tariffTypeList={tariffTypeList}
        setTariffTypeList={setTariffTypeList}
        connectionTypeSelect={connectionTypeSelect}
        setConnectionTypeSelect={setConnectionTypeSelect}
        connectionTypeList={connectionTypeList}
        setConnectionTypeList={setConnectionTypeList}
        checkSiteName={checkSiteName}
      />
      <SiteEditModalForm
        modalOpen={editModalOpen}
        closeModal={closeModal}
        initialValues={initialValues}
        onSubmit={onEditSubmit}
        tariffTypeSelect={tariffTypeSelect}
        setTariffTypeSelect={setTariffTypeSelect}
        tariffTypeList={tariffTypeList}
        setTariffTypeList={setTariffTypeList}
        connectionTypeSelect={connectionTypeSelect}
        setConnectionTypeSelect={setConnectionTypeSelect}
        connectionTypeList={connectionTypeList}
        setConnectionTypeList={setConnectionTypeList}
        editLoading={editLoading}
      />
      <DeviceConfigurationModal
        modalOpen={configurationModal}
        modalClose={onConfigurationModalClose}
        siteID={siteID}
      />
      <SiteDetailsModal
        modalOpen={siteDetailsModal}
        modalClose={onSiteDetailsModalClose}
        siteDetails={siteDetails}
      />
      <AlarmNotificationConfigModal
        modalOpen={alarmNotificationConfigModal}
        modalClose={onAlarmNotificationConfigModalClose}
        siteID={siteID}
      />
    </>
  );
}

export default SiteManagement;
