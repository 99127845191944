import "../css/pages/AssetLayout.css";
import {
  App,
  Layout,
  Menu,
  message,
  Button,
  ConfigProvider,
  theme,
  Switch,
  Popover,
  Avatar,
  Flex,
  Card,
  Row,
  Col,
  Select,
  Spin,
  Modal,
  Typography,
} from "antd";
import React, { useState, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  AppstoreOutlined,
  DesktopOutlined,
  PieChartOutlined,
  SunFilled,
  MoonFilled,
  HighlightOutlined,
  UserOutlined,
  KeyOutlined,
  LogoutOutlined,
  DashboardOutlined,
  LineChartOutlined,
  AlertOutlined,
  ReconciliationOutlined,
  UnorderedListOutlined,
  WarningTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import Logo from "../assets/REVEAI.png";
import WhiteLogo from "../assets/REVEAI White.png";
import { BACKEND_URL } from "../configuration";
import { SiteContext } from "../context";
import { fetchHelper } from "../functions/fetch";

const VERBOSE = false;
/**
 * TODO
 */
const { Title, Text } = Typography;
const { Header, Sider, Content } = Layout;
const logout_url = BACKEND_URL + "/users/logout";
const checkLogin_url = BACKEND_URL + "/users/checkUser";
// const userTheme = localStorage.getItem("Theme");
const userTheme = "light";
const { getDesignToken, useToken, darkAlgorithm, defaultAlgorithm } = theme;
const config = {
  token: {
    colorPrimary: "#070091e4",
    colorWarning: "#faad14",
    colorSuccess: "#52C41A",
    colorError: "ff4d4f",
    colorInfo: "#167fffe4",
    algorithm:
      userTheme == NaN || userTheme == "light"
        ? theme.defaultAlgorithm
        : theme.darkAlgorithm,
  },
  components: {
    Layout: {
      algorithm: true,
    },
    Collapse: {
      algorithm: true,
    },
  },
  cssVar: true,
};
const globalToken = getDesignToken(config);

function AssetLayout() {
  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [assetLoading, setAssetLoading] = useState(true);
  const [checkSiteLoading, setCheckSiteLoading] = useState(true);
  const [siteSelect, setSiteSelect] = useState();
  const [siteNum, setSiteNum] = useState(0);
  const [siteChange, setSiteChange] = useState(false);
  const [siteList, setSiteList] = useState([]);
  const [userType, setUserType] = useState();
  const [userSite, setUserSite] = useState(false);
  const [shortUsername, setShortUsername] = useState("");
  const [username, setUsername] = useState("");

  const [subscribePpaBilling, setSubscribePpaBilling] = useState(null);
  const [subscribeAiPvDiagnosis, setSubscribeAiPvDiagnosis] = useState(null);
  const [subscribeCarbonManagement, setSubscribeCarbonManagement] =
    useState(null);
  const [subscribeAiBess, setSubscribeAiBess] = useState(null);
  const [hasInverter, setHasInverter] = useState(null);

  const excludedPaths = [
    "/management",
    "/sitelist",
    "/billing",
    "/maintenance",
    "/configuration",
  ];
  const navigate = useNavigate();
  const location = useLocation();
  const [appTheme, setAppTheme] = useState(globalToken);
  const [collapsed, setCollapsed] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(
    userTheme == NaN || userTheme == "light" ? true : false
  );
  const headerHeight = 60;

  const headerStyle = {
    textAlign: "center",
    height: headerHeight,
    paddingInline: 0,
    lineHeight: headerHeight,
    position: "sticky",
    top: 0,
    zIndex: 1,
  };

  const contentStyle = {
    height: "calc(100% -" + headerHeight + " )",
    width: "100vw",
    minWidth: "100vw",
    overflow: "auto",
    padding: 12,
  };

  const layoutStyle = {
    overflow: "hidden",
    padding: 0,
    margin: 0,
    width: "100%",
    height: "100vh",
  };

  const navMenu = [
    userType != 2
      ? {
          label: (
            <Flex
              align="center"
              justify="start"
              style={{ paddingInline: 4, height: headerHeight }}
            >
              <UnorderedListOutlined style={{ marginRight: 8 }} />
              Site List
            </Flex>
          ),
          key: "/sitelist",
        }
      : null,
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 4, height: headerHeight }}
        >
          <DashboardOutlined style={{ marginRight: 8 }} />
          Dashboard
        </Flex>
      ),
      key: "/dashboard",
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 4, height: headerHeight }}
        >
          <LineChartOutlined style={{ marginRight: 8 }} />
          Analytics
        </Flex>
      ),
      key: "/analytics",
      children: [
        {
          label: "Power Demand",
          key: "/powerdemand",
        },
        {
          label: "Energy Demand",
          key: "/energydemand",
        },
        {
          label: "Parameter",
          key: "/parameter",
        },
      ],
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 4, height: headerHeight }}
        >
          <DesktopOutlined style={{ marginRight: 8 }} />
          System
        </Flex>
      ),
      key: "/system",
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 4, height: headerHeight }}
        >
          <AlertOutlined style={{ marginRight: 8 }} />
          Alarm
        </Flex>
      ),
      children: [
        {
          label: "Alarm Record",
          key: "/alarm",
        },
        subscribeAiPvDiagnosis
          ? {
              label: "PV Condition",
              key: "/pvcondition",
            }
          : null,
      ],
    },
    {
      label: (
        <Flex
          align="center"
          justify="start"
          style={{ paddingInline: 4, height: headerHeight }}
        >
          <ReconciliationOutlined style={{ marginRight: 8 }} />
          Reports
        </Flex>
      ),
      key: "/reports",
      children: [
        {
          label: "System Report",
          key: "/systemreport",
        },
        {
          label: "ST Report",
          key: "/streport",
        },
        subscribeAiBess
          ? {
              label: "Saving Report",
              key: "/savingreport",
            }
          : null,
      ],
    },
    userType != 2
      ? {
          label: (
            <Flex
              align="center"
              justify="start"
              style={{ paddingInline: 4, height: headerHeight }}
            >
              <PieChartOutlined style={{ marginRight: 8 }} />
              Management
            </Flex>
          ),
          children: [
            {
              label: "Maintenance",
              key: "/maintenance",
            },
            {
              label: "Other Management",
              key: "/management",
            },
          ],
        }
      : null,
    subscribePpaBilling ||
    subscribeCarbonManagement ||
    subscribeAiPvDiagnosis ||
    subscribeAiBess
      ? {
          label: (
            <Flex
              align="center"
              justify="start"
              style={{ paddingInline: 4, height: headerHeight }}
            >
              <AppstoreOutlined style={{ marginRight: 8 }} />
              Features
            </Flex>
          ),
          children: [
            subscribePpaBilling
              ? {
                  label: "PPA Billing",
                  key: "/ppabilling",
                  children: [
                    {
                      label: "PPA Billing",
                      key: "/billing",
                    },
                    {
                      label: "Configuration",
                      key: "/configuration",
                    },
                  ],
                }
              : null,
            subscribeCarbonManagement
              ? {
                  label: "Carbon Management",
                  key: "/carbonmanagement",
                }
              : null,
            subscribeAiPvDiagnosis
              ? {
                  label: "AI-PV Diagnosis",
                  key: "/aipvdiagnosis",
                }
              : null,
            subscribeAiBess
              ? {
                  label: "AI-BESS",
                  key: "/aibess",
                }
              : null,
          ],
        }
      : null,
  ];

  const content = (
    <div style={{ minWidth: "150px" }}>
      <Menu
        style={{ minWidth: "200px", borderWidth: 0 }}
        onClick={(value) => {
          if (value.key == "logout") {
            logout();
          }
          if (value.key == "password") {
            navigate("/changepassword");
          }
        }}
        items={[
          // {
          //   key: "theme",
          //   label: (
          //     <ConfigProvider
          //       theme={{
          //         components: {
          //           Switch: {
          //             algorithm: true,
          //             trackHeight: 28,
          //             handleSize: 24,
          //             fontSize: 18,
          //             fontSizeIcon: 18,
          //             trackMinWidth: 60,
          //             innerMinMargin: 5,
          //           },
          //         },
          //       }}
          //     >
          //       Theme
          //       <Switch
          //         style={{
          //           backgroundColor: isDarkMode ? appTheme["orange-5"] : "",
          //           marginTop: -4,
          //           marginLeft: "30px",
          //         }}
          //         checkedChildren={<SunFilled style={{ marginLeft: 0 }} />}
          //         unCheckedChildren={<MoonFilled style={{ paddingLeft: 0 }} />}
          //         value={isDarkMode}
          //         onChange={(checked) => themeOnChange(checked)}
          //       ></Switch>
          //     </ConfigProvider>
          //   ),
          //   icon: <HighlightOutlined />,
          // },
          // {
          //   key: "profile",
          //   label: "Username: " + username,
          //   icon: <UserOutlined />,
          // },
          // { key: "password", label: "Change Password", icon: <KeyOutlined /> },
          { key: "logout", label: "Logout", icon: <LogoutOutlined /> },
        ]}
        selectable={false}
      ></Menu>
    </div>
  );

  function themeOnChange(value) {
    setIsDarkMode(value);

    let t = (appTheme.algorithm = value ? defaultAlgorithm : darkAlgorithm);
    localStorage.setItem("Theme", value ? "light" : "dark");
    const theme = localStorage.getItem("Theme");

    if (theme === "light") {
      document.documentElement.style.setProperty(
        "--text-color",
        "var(--text-color-light)"
      );
      setImageLogo(Logo);
    } else {
      document.documentElement.style.setProperty(
        "--text-color",
        "var(--text-color-dark)"
      );
      setImageLogo(WhiteLogo);
    }

    setAppTheme(globalToken);
  }

  const [imageLogo, setImageLogo] = useState(
    userTheme == "light" ? Logo : WhiteLogo
  );

  // get selected based on url pathname
  // to show selected state in (header) menu
  let menuSelectedKey = window.location.pathname;

  // to show loading page instead of actual page when checking login status
  const [userLoginChecking, setUserLoginChecking] = useState(true);

  // setup using data from session storage
  // STANDARDIZE selecteddSite = ID : Integer
  const [selectedSite, _setSelectedSite] = useState();
  const setSelectedSite = (site) => {
    sessionStorage.setItem("selectedSite", site);
    _setSelectedSite(site);
  };
  const [loginData, setLoginData] = useState({
    carbonAccounting: false,
    userType: 999,
    sites: [],
  });

  const logout = () => {
    //show a message to indicate it is logging out
    message.loading({
      content: "Logging Out",
      key: "updatable",
    });

    const fetchSucessCallback = (data) => {
      message.success({
        content: "Logged Out. Redirecting in 3s.",
        key: "updatable",
        duration: 3,
      });

      setTimeout(() => {
        navigate("/login");
      }, 3000);
    };

    const setLoading = () => {};

    fetchHelper(logout_url, fetchSucessCallback, setLoading, "Logout");
  };

  /**
   * call backend to check if user is logged in
   * @param {*} callback function to call upon success (mostly for navigation in this context)
   */
  const checkUserLogin = (callback) => {
    setUserLoginChecking(true);
    setAssetLoading(true);
    callback();

    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    fetch(checkLogin_url, requestOptions)
      .then((response) => {
        if (response.status == 200) {
          setUserLoginChecking(false);
          response.json().then((data) => {
            setUserType(data.data.user_type);
            setAssetLoading(false);
          });
          setFirstLoad(false);
          callback();
        } else {
          navigate("/login");
        }
      })
      .catch((e) => {
        navigate("/login");
        console.log(e);
      });
  };

  /** API to get all the site selection */
  function getSiteSelection() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      setSiteList(data.site_data);
      setSiteNum(data.site_data.length);
      if (data.site_data.length !== 0) {
        setSiteSelect(data.site_data[0]["value"]);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/asset_layout/getSiteSelection`,
      cb,
      setLoading,
      "Get Site Selection",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to get features that the site subscribes */
  function getFeatureSubscription() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      setSubscribePpaBilling(data.ppa_billing);
      setSubscribeAiPvDiagnosis(data.ai_pv_diagnosis);
      setSubscribeCarbonManagement(data.carbon_management);
      setSubscribeAiBess(data.ai_bess);
    };

    fetchHelper(
      `${BACKEND_URL}/asset_layout/getFeatureSubscription`,
      cb,
      setLoading,
      "Get Feature Subscription",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  /** API to check whether the site has inverter */
  function getHasInverter() {
    setLoading(true);

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      setHasInverter(data.has_inverter);
    };

    fetchHelper(
      `${BACKEND_URL}/asset_layout/getHasInverter`,
      cb,
      setLoading,
      "Get Has Inverter",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function checkUserSite() {
    setCheckSiteLoading(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setUserSite(data.data);
      } else {
        message.error(data.error);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/asset_layout/checkUserSite`,
      cb,
      setCheckSiteLoading,
      "Check User Site",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  function getUsername() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const cb = (data) => {
      if (data.status) {
        setUsername(data.username);
        setShortUsername(data.short_username);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/asset_layout/getUsername`,
      cb,
      () => {},
      "Get Username",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    getSiteSelection();
  }, [siteChange]);

  useEffect(() => {
    if (siteSelect) {
      getFeatureSubscription();
      getHasInverter();
    }
  }, [siteSelect]);

  useEffect(() => {
    // if (VERBOSE) console.log("Use Effect");
    checkUserLogin(() => {});
    checkUserSite(() => {});
    getUsername();
  }, []);

  return (
    <>
      <ConfigProvider key={userTheme} theme={{ ...appTheme }}>
        <App>
          <Layout style={layoutStyle}>
            <Modal
              open={userSite}
              closable={false}
              centered={true}
              footer={null}
            >
              <div
                style={{
                  textAlign: "center",
                  padding: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <WarningTwoTone
                  twoToneColor="#bb2124"
                  style={{ fontSize: "60px" }}
                />
                <Title type="danger">Warning</Title>
                <Text style={{ fontSize: "20px" }}>
                  There is not site for this user account. Please contact user
                  admin to get a site
                </Text>
                <Button
                  style={{
                    marginTop: "20px",
                  }}
                  size="large"
                  onClick={() => logout()}
                >
                  <LogoutOutlined />
                  <Text>Logout</Text>
                </Button>
              </div>
            </Modal>
            {checkSiteLoading ? (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 100, color: "#000038" }}
                      spin
                    />
                  }
                />
              </div>
            ) : (
              <>
                {userSite ? (
                  <></>
                ) : (
                  <>
                    <Header style={headerStyle}>
                      <Card
                        className="test-card"
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: 0,
                          marginBottom: "20px",
                          overflow: "hidden",
                        }}
                        bordered={false}
                      >
                        <Row
                          style={{
                            height: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <Col xl={0} flex="52px" style={{ height: "100%" }}>
                            <Flex
                              align="center"
                              style={{ height: "100%", width: "100%" }}
                            >
                              <Button
                                icon={<UnorderedListOutlined />}
                                style={{ paddingHorizontal: "10px" }}
                                type="text"
                                size="large"
                                onClick={() => setCollapsed(!collapsed)}
                              />
                            </Flex>
                          </Col>
                          <Col
                            flex="auto"
                            style={{ height: "100%", paddingLeft: 0 }}
                          >
                            <Flex
                              justify="space-between"
                              align="center"
                              style={{ height: "100%", width: "100%" }}
                            >
                              <img
                                src={imageLogo}
                                alt="REVEAI"
                                style={{ height: "20px" }}
                              />
                              <Row
                                style={{
                                  height: "60px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Col
                                  style={{ height: "100%" }}
                                  xs={0}
                                  sm={0}
                                  md={0}
                                  lg={0}
                                  xl={22}
                                >
                                  {assetLoading && firstLoad ? (
                                    <Spin
                                      indicator={
                                        <LoadingOutlined
                                          style={{
                                            fontSize: 100,
                                            color: "#000038",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            height: "100%",
                                          }}
                                          spin
                                        />
                                      }
                                    />
                                  ) : (
                                    <Menu
                                      style={{
                                        height: "100%",
                                        minWidth: 1000,
                                        borderBottom: 0,
                                      }}
                                      mode="horizontal"
                                      items={navMenu}
                                      selectedKeys={[menuSelectedKey]}
                                      onClick={(value) => {
                                        checkUserLogin(() =>
                                          navigate(value.key)
                                        );
                                      }}
                                    />
                                  )}
                                </Col>
                              </Row>
                              {siteNum > 1 && (
                                <>
                                  {!excludedPaths.includes(menuSelectedKey) && (
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p>Site: &nbsp;</p>
                                      <Select
                                        loading={loading}
                                        value={siteSelect}
                                        options={siteList}
                                        onChange={(value, option) => {
                                          setSiteSelect(value);
                                        }}
                                        placeholder="Select site"
                                      />
                                    </div>
                                  )}
                                </>
                              )}
                              <Popover
                                placement="bottom"
                                contentStyle={{ marginTop: "50px" }}
                                content={content}
                                arrow={false}
                                trigger="hover"
                                overlayInnerStyle={{
                                  borderRadius: 0,
                                  marginTop: 10,
                                  padding: 0,
                                }}
                              >
                                <Avatar
                                  style={{
                                    backgroundColor: appTheme["blue-10"],
                                  }}
                                >
                                  {shortUsername}
                                </Avatar>
                              </Popover>
                            </Flex>
                          </Col>
                        </Row>
                      </Card>
                    </Header>
                    <Layout style={{ height: "100%" }}>
                      <Sider
                        breakpoint="xl"
                        collapsedWidth="0"
                        width="300"
                        collapsible
                        trigger={null}
                        collapsed={collapsed}
                        onBreakpoint={() => setCollapsed(true)}
                      >
                        <Menu
                          mode="inline"
                          inlineIndent={32}
                          style={{
                            height: "100%",
                            // overflowY: "scroll"
                          }}
                          items={navMenu}
                          selectedKeys={[menuSelectedKey]}
                          onClick={(value) => {
                            checkUserLogin(() => navigate(value.key));
                          }}
                        />
                      </Sider>
                      <SiteContext.Provider
                        value={[selectedSite, setSelectedSite]}
                        style={contentStyle}
                      >
                        <Outlet
                          context={[
                            siteSelect,
                            setSiteSelect,
                            siteChange,
                            setSiteChange,
                            isDarkMode,
                            setIsDarkMode,
                            subscribePpaBilling,
                            subscribeCarbonManagement,
                            subscribeAiPvDiagnosis,
                            subscribeAiBess,
                            hasInverter,
                          ]}
                        />
                      </SiteContext.Provider>
                    </Layout>
                  </>
                )}
              </>
            )}
          </Layout>
        </App>
      </ConfigProvider>
    </>
  );
}

export default AssetLayout;
