import { Card, Empty, Skeleton, message } from "antd";
import { BACKEND_URL, MQTT_REFRESH_RATE } from "../../configuration";
import { fetchHelper } from "../../functions/fetch";
import { useOutletContext } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Chart } from "@antv/g2";
import "../../css/component/dashboard/SocChartCard.css";
import { LIGHT_BLUE } from "../../configuration";
import moment from "moment";

function SocChart(props) {
  const { container, data_line } = props;
  const chartRef = useRef(null);

  useEffect(() => {
    document.getElementById(container).innerHTML = "";
  }, []);

  useEffect(() => {
    const chart = new Chart({
      container: container,
      autoFit: true,
    });

    chart
      .line()
      .data(data_line)
      .encode("x", "Time")
      .encode("y", "SOC (%)")
      .encode("color", "name")
      .encode("shape", "smooth")
      .scale("color", { range: [LIGHT_BLUE] }) // Set the color scale to LIGHT_BLUE
      .style("lineWidth", 2)
      .interaction("elementHighlight", { background: true })
      .interaction("tooltip", {
        render: (event, { title, items }) => {
          return `<div style="width:150px;">
            <h3 style="padding:0;margin:0">${title}</h3>
            <ul style="padding: 0; margin: 0; list-style: none;">
              ${items
                .map((d) => {
                  // Format the value with commas and 2 decimal places
                  const formattedValue = d.value
                    ? Number(d.value).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : d.value === 0
                    ? Number(d.value).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : d.value;
                  return `
                    <li style="display: flex; justify-content: space-between; align-items: center; margin: 4px 0;">
                      <span style="display: inline-flex; align-items: center;">
                        <span style="width: 8px; height: 8px; background-color: ${d.color}; border-radius: 50%; margin-right: 8px;"></span>
                        <span>${d.name}</span>
                      </span>
                      <span>${formattedValue}</span>
                    </li>`;
                })
                .join("")}
            </ul>
          </div>`;
        },
      });

    document.getElementById(container).innerHTML = "";
    chartRef.current = chart;

    chart.render();

    window.onresize = () => {
      chart.render();
    };
  }, [container]);

  return <div style={{ width: "100%", height: "100%" }} id={container} />;
}

function SocChartCard(props) {
  const { heightRef } = props;
  const [loading, setLoading] = useState(true);

  const [siteSelect] = useOutletContext();

  const [socChartData, setSocChartData] = useState([]);

  /** API to get SOC chart data*/
  function getSocChartData() {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: "include",
      body: JSON.stringify({
        site_id: siteSelect,
      }),
    };

    const cb = (data) => {
      if (data.status) {
        setSocChartData(data.soc_chart_data);
      } else {
        message.error(data.message);
      }
    };

    fetchHelper(
      `${BACKEND_URL}/dashboard/getSocChartData`,
      cb,
      setLoading,
      "Get SOC Chart Data",
      () => {},
      () => {},
      requestOptions,
      true
    );
  }

  useEffect(() => {
    if (siteSelect) {
      getSocChartData();

      const timer = setInterval(() => {
        getSocChartData();
      }, MQTT_REFRESH_RATE * 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [siteSelect]);

  return (
    <>
      {loading === false ? (
        <Card
          className="soc-chart-card"
          title={
            "State of Charge (" + moment().local().format("YYYY-MM-DD") + ")"
          }
          style={{
            height: heightRef.current?.clientHeight,
          }}
        >
          <div className="soc-chart-card-content">
            {socChartData.length > 0 ? (
              <SocChart data_line={socChartData} container={"soc-chart"} />
            ) : (
              <Empty
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              />
            )}
          </div>
        </Card>
      ) : (
        <Card
          className="soc-chart-card"
          style={{
            height: heightRef.current?.clientHeight,
          }}
        >
          <div className="soc-chart-card-content">
            <Skeleton
              active={true}
              style={{ padding: "1rem", maxWidth: "70%", margin: "auto" }}
              paragraph={{ rows: 10 }}
            />
          </div>
        </Card>
      )}
    </>
  );
}

export default SocChartCard;
