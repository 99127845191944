import dayjs from "dayjs";

/**
 * Filter Data Objects based on 'date' variable
 */
export const filterDataByDateRange = (
  data,
  startDate,
  endDate,
  variable = "date",
  debug = ""
) => {
  const filteredData = data.filter((entry) => {
    const entryDate = dayjs(entry[variable]);
    return (
      entryDate.isSame(startDate.startOf("day")) ||
      (entryDate.isAfter(startDate.startOf("day")) &&
        entryDate.isBefore(endDate.endOf("day")))
    );
  });

  return filteredData;
};

export const filterDataByPickerRange = (
  data,
  selectedDate,
  pickerType,
  variable = "date",
  debug = "not set"
) => {
  let filteredData;

  switch (pickerType) {
    case "date":
      filteredData = data.filter((entry) =>
        selectedDate.isSame(entry[variable], "day")
      );
      break;
    case "month":
      filteredData = data.filter((entry) =>
        selectedDate.isSame(entry[variable], "month")
      );
      break;
    case "year":
      filteredData = data.filter((entry) =>
        selectedDate.isSame(entry[variable], "year")
      );
      break;
    default:
      filteredData = data;
  }

  return filteredData;
};
